import api from './axiosConfig';

const calculatorApi = {
  // Business Settings
  getBusinessSettings: () => api.get('/calculator/settings/business'),
  updateBusinessSettings: (data) => api.put('/calculator/settings/business', data),
  
  // Service Categories
  getServiceCategories: () => api.get('/calculator/services/categories'),
  createServiceCategory: (data) => api.post('/calculator/services/categories', data),
  updateServiceCategory: (id, data) => api.put(`/calculator/services/categories/${id}`, data),
  deleteServiceCategory: (id) => api.delete(`/calculator/services/categories/${id}`),
  
  // Services
  getServices: () => api.get('/calculator/services'),
  getService: (id) => api.get(`/calculator/services/${id}`),
  createService: (data) => api.post('/calculator/services', data),
  updateService: (id, data) => api.put(`/calculator/services/${id}`, data),
  deleteService: (id) => api.delete(`/calculator/services/${id}`),
  saveService: (id, data) => id ? api.put(`/calculator/services/${id}`, data) : api.post('/calculator/services', data),
  
  // Service Cost Analysis
  getServiceAnalysis: (serviceId) => api.get(`/calculator/services/cost-analysis?service_id=${serviceId}`),
  getServicesAnalysis: () => api.get('/calculator/services/cost-analysis/all'),
  
  // Service Products
  getServiceProducts: (serviceId) => api.get(`/calculator/services/${serviceId}/products`),
  addProductToService: (data) => api.post('/calculator/service-products', data),
  updateServiceProduct: (id, data) => api.put(`/calculator/service-products/${id}`, data),
  deleteServiceProduct: (id) => api.delete(`/calculator/service-products/${id}`),
  
  // Staff
  getStaff: () => api.get('/calculator/staff'),
  updateStaffRate: (id, data) => api.put(`/calculator/staff/${id}`, data),
  
  // Expenses - Original endpoints
  getExpenses: () => api.get('/calculator/expenses'),
  createExpense: (data) => api.post('/calculator/expenses', data),
  updateExpense: (id, data) => api.put(`/calculator/expenses/${id}`, data),
  deleteExpense: (id) => api.delete(`/calculator/expenses/${id}`),
  
  // Enhanced Expense Management endpoints
  getExpenseSummary: (period = 'current') => api.get(`/calculator/expenses/summary?period=${period}`),
  getExpenseSummaryConfig: () => api.get('/calculator/expenses/summary/config'),
  updateExpenseSummaryConfig: (data) => api.post('/calculator/expenses/summary/config', data),
  recalculateOverhead: (data) => api.post('/calculator/expenses/recalculate-overhead', data),
  
  // Import/Export
  importServices: (formData) => api.post('/calculator/import-services', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }),
  exportServices: () => api.get('/calculator/export-services', {
    responseType: 'blob'
  })
};

export default calculatorApi;