import errorHandler from './utils/errorHandler';
import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Button,
  Snackbar,
  Alert,
  Typography,
  MenuItem,
  Box,
  Paper,
  CircularProgress,
  Divider
} from '@mui/material';
import api from './axiosConfig';

const CATEGORIES = [
    'Marketing & Advertising', // Note the '&' instead of 'and'
    'Accountancy & Legal',
    'Bank Charges & Interest',
    'Card Machine Fees',
    'Client Comfort',
    'Communications',
    'Furniture & Equipment',
    'Insurance',
    'IT & Software',
    'Loan Repayment',
    'Maintenance & Repairs',
    'Office Supplies',
    'Payroll',
    'Personal',
    'Rates',
    'Rent',
    'Stock',
    'Subscriptions',
    'Sundry',
    'Taxation',
    'Training',
    'Utilities',
    'Waste Disposal'
].sort();

const validationSchema = Yup.object({
  date: Yup.date().required('Date is required'),
  category: Yup.string().required('Category is required'),
  description: Yup.string().required('Description is required'),
  total: Yup.number()
    .required('Total is required')
    .min(0, 'Total must be positive'),
  paymentType: Yup.string().required('Payment type is required')
});

const ExpenditureTab = ({ supplierName, vatApplicable, supplierType }) => {
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [categoryTotals, setCategoryTotals] = useState({});
  const [recentExpenditures, setRecentExpenditures] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Test endpoint connectivity
  const testEndpoint = useCallback(async () => {
    try {
      errorHandler.logDebug('ExpenditureTab', 'Testing API endpoint connectivity...');
      await api.get('/expenditures/ping');
    } catch (error) {
      console.error('API test error:', error);
    }
  }, []);

  // Fetch category totals
  const fetchCategoryTotals = useCallback(async () => {
    if (!supplierName) return;
    
    try {
      const response = await api.get(`/expenditures/totals/${supplierName}`);
      setCategoryTotals(response.data);
    } catch (error) {
      console.error('Error fetching category totals:', error);
      setSnackbar({
        open: true,
        message: 'Error loading category totals',
        severity: 'error'
      });
    }
  }, [supplierName]);

  // Fetch recent expenditures
  const fetchRecentExpenditures = useCallback(async () => {
    if (!supplierName) return;
    
    try {
      const response = await api.get(`/expenditures/recent/${supplierName}`);
      setRecentExpenditures(response.data);
    } catch (error) {
      console.error('Error fetching recent expenditures:', error);
      setSnackbar({
        open: true,
        message: 'Error loading recent expenditures',
        severity: 'error'
      });
    }
  }, [supplierName]);

  const formik = useFormik({
    initialValues: {
      date: new Date().toISOString().split('T')[0],
      category: '',
      description: '',
      total: '',
      vat: '',
      net_expenditure: '',
      paymentType: 'card'
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      errorHandler.logDebug('ExpenditureTab', 'ExpenditureTab submission started');
      
      if (!supplierName) {
        console.warn('No supplier name provided');
        setSnackbar({
          open: true,
          message: 'No supplier selected',
          severity: 'error'
        });
        return;
      }

      if (isSubmitting) {
        console.warn('Already submitting');
        return;
      }

      setIsSubmitting(true);
      errorHandler.logDebug('ExpenditureTab', 'Preparing data for submission');
      
      try {
        const total = parseFloat(values.total);
        let vat = vatApplicable ? total/6 : 0;
        let net = total - vat;

        const submitData = {
          date: values.date,
          type: supplierType || 'Supplies',
          category: values.category,
          description: values.description,
          total: total.toString(),
          vat: vat.toFixed(2),
          net_expenditure: net.toFixed(2),
          payment_type: values.paymentType.toUpperCase(),
          supplier_name: supplierName
        };

        errorHandler.logApiCall('Submitting data:', submitData);
        const response = await api.post('/expenditures', submitData);
        errorHandler.logDebug('ExpenditureTab', 'Server response:', response);
        
        if (response.status === 201) {
          errorHandler.logDebug('ExpenditureTab', 'Submission successful');
          setSnackbar({
            open: true,
            message: 'Expenditure saved successfully',
            severity: 'success'
          });
          
          await Promise.all([
            fetchCategoryTotals(),
            fetchRecentExpenditures()
          ]);
          
          resetForm();
        }
      } catch (error) {
        console.error('Submission error:', {
          message: error.message,
          response: error.response?.data,
          status: error.response?.status
        });
        setSnackbar({
          open: true,
          message: error.response?.data?.message || 'Error saving expenditure',
          severity: 'error'
        });
      } finally {
        setIsSubmitting(false);
        errorHandler.logDebug('ExpenditureTab', 'Submission process completed');
      }
    }
  });

  // Effect for initial data fetch
  useEffect(() => {
    if (supplierName) {
      setIsLoading(true);
      Promise.all([
        fetchCategoryTotals(),
        fetchRecentExpenditures()
      ]).finally(() => {
        setIsLoading(false);
      });
    }
  }, [supplierName, fetchCategoryTotals, fetchRecentExpenditures]);

  // Effect for VAT calculations
  useEffect(() => {
    if (formik.values.total) {
      const total = parseFloat(formik.values.total);
      if (vatApplicable) {
        const vat = total/6;
        const net = total - vat;
        formik.setFieldValue('vat', vat.toFixed(2));
        formik.setFieldValue('net_expenditure', net.toFixed(2));
      } else {
        formik.setFieldValue('vat', '0.00');
        formik.setFieldValue('net_expenditure', total.toFixed(2));
      }
    }
  }, [formik.values.total, vatApplicable]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {/* Category Totals Summary */}
      <Paper sx={{ p: 2, mb: 3, bgcolor: 'background.default' }}>
        <Typography variant="h6" gutterBottom>Category Totals for {supplierName}</Typography>
        <Grid container spacing={2}>
          {Object.entries(categoryTotals).map(([category, total]) => (
            <Grid item xs={6} sm={4} md={3} key={category}>
              <Typography variant="subtitle2">{category}:</Typography>
              <Typography>£{parseFloat(total).toFixed(2)}</Typography>
            </Grid>
          ))}
        </Grid>
      </Paper>

      {/* Recent Expenditures */}
      <Paper sx={{ p: 2, mb: 3, bgcolor: 'background.default' }}>
        <Typography variant="h6" gutterBottom>Recent Expenditures</Typography>
        <Grid container spacing={2}>
          {recentExpenditures.length > 0 ? (
            recentExpenditures.map((exp, index) => (
              <Grid item xs={12} key={index}>
                <Typography>
                  {new Date(exp.date).toLocaleDateString()} - {exp.category} - £{exp.total}
                </Typography>
              </Grid>
            ))
          ) : (
            <Grid item xs={12}>
              <Typography color="textSecondary">No recent expenditures</Typography>
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Expenditure Form */}
      <Box component="div">
        <form 
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            formik.handleSubmit(e);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="date"
                name="date"
                label="Date"
                type="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                error={formik.touched.date && Boolean(formik.errors.date)}
                helperText={formik.touched.date && formik.errors.date}
                InputLabelProps={{ shrink: true }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                select
                id="category"
                name="category"
                label="Category"
                value={formik.values.category}
                onChange={formik.handleChange}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
                required
              >
                {CATEGORIES.map(category => (
                  <MenuItem key={category} value={category}>{category}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                id="description"
                name="description"
                label="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.touched.description && Boolean(formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
                multiline
                rows={2}
                required
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="total"
                name="total"
                label="Total (inc. VAT)"
                type="number"
                value={formik.values.total}
                onChange={formik.handleChange}
                error={formik.touched.total && Boolean(formik.errors.total)}
                helperText={formik.touched.total && formik.errors.total}
                required
                inputProps={{
                  step: "0.01",
                  min: "0"
                }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="vat"
                name="vat"
                label="VAT"
                type="number"
                value={formik.values.vat}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                id="net_expenditure"
                name="net_expenditure"
                label="Net Amount"
                type="number"
                value={formik.values.net_expenditure}
                InputProps={{ readOnly: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <RadioGroup
                row
                name="paymentType"
                value={formik.values.paymentType}
                onChange={formik.handleChange}
              >
                <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                <FormControlLabel value="card" control={<Radio />} label="Card" />
                <FormControlLabel value="bank" control={<Radio />} label="Bank Payment" />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <Button 
                variant="contained" 
                color="primary" 
                type="submit"
                disabled={isSubmitting}
                startIcon={isSubmitting && <CircularProgress size={20} />}
              >
                {isSubmitting ? 'Saving...' : 'Save Expenditure'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExpenditureTab;