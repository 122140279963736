import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import api from './axiosConfig';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
  Alert,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const VatReturnForm = () => {
  const { periodId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [calculating, setCalculating] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [periodData, setPeriodData] = useState(null);
  const [vatCalculation, setVatCalculation] = useState(null);
  const [declaration, setDeclaration] = useState(false);
  
  // Fetch period data
  useEffect(() => {
    const fetchPeriodData = async () => {
      try {
        setLoading(true);
        // Add timestamp for cache busting
        const timestamp = new Date().getTime();
        const response = await api.get(`/vat/periods/${periodId}?t=${timestamp}`);
        
        if (response.data.success) {
          setPeriodData(response.data.data);
          
          // If calculation is included, use it
          if (response.data.data.calculation) {
            setVatCalculation(response.data.data.calculation);
          }
        } else {
          setError(response.data.error || 'Failed to load VAT period data');
        }
      } catch (err) {
        console.error('Error fetching VAT period data:', err);
        setError('Failed to load VAT period data: ' + (err.response?.data?.error || err.message));
      } finally {
        setLoading(false);
      }
    };

    if (periodId) {
      fetchPeriodData();
    }
    
    // Cleanup function to reset state when unmounting
    return () => {
      setPeriodData(null);
      setVatCalculation(null);
      setDeclaration(false);
    };
  }, [periodId]);
  
  // Function to calculate VAT
  const calculateVAT = async () => {
    try {
      setCalculating(true);
      setError(null);
      
      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      const response = await api.get(`/vat/periods/${periodId}/calculate?t=${timestamp}`);
      
      if (response.data.success) {
        setVatCalculation(response.data.data);
      } else {
        setError(response.data.error || 'Failed to calculate VAT');
      }
    } catch (err) {
      console.error('Error calculating VAT:', err);
      setError('Failed to calculate VAT: ' + (err.response?.data?.error || err.message));
    } finally {
      setCalculating(false);
    }
  };
  
  // Function to submit VAT return
  const submitVATReturn = async () => {
    try {
      setSubmitting(true);
      setError(null);
      
      if (!vatCalculation) {
        setError('Please calculate VAT amounts before submitting');
        setSubmitting(false);
        return;
      }
      
      if (!declaration) {
        setError('You must confirm the declaration before submitting');
        setSubmitting(false);
        return;
      }
      
      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      const response = await api.post(`/vat/periods/${periodId}/submit?t=${timestamp}`, {
        output_vat: vatCalculation.output_vat,
        input_vat: vatCalculation.input_vat,
        vat_due: vatCalculation.vat_due
      });
      
      if (response.data.success) {
        // Redirect to VAT monitoring page
        navigate('/vat-monitoring');
      } else {
        setError(response.data.error || 'Failed to submit VAT return');
      }
    } catch (err) {
      console.error('Error submitting VAT return:', err);
      setError('Failed to submit VAT return: ' + (err.response?.data?.error || err.message));
    } finally {
      setSubmitting(false);
    }
  };
  
  // Function to format dates
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy');
  };
  
  // Function to format currency
  const formatCurrency = (value) => {
    if (value === null || value === undefined) return '£0.00';
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Button 
          startIcon={<ArrowBackIcon />} 
          variant="outlined" 
          sx={{ mb: 2 }}
          onClick={() => navigate('/vat-monitoring')}
        >
          Back to VAT Monitoring
        </Button>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }
  
  if (!periodData) {
    return (
      <Box sx={{ p: 2 }}>
        <Button 
          startIcon={<ArrowBackIcon />} 
          variant="outlined" 
          sx={{ mb: 2 }}
          onClick={() => navigate('/vat-monitoring')}
        >
          Back to VAT Monitoring
        </Button>
        <Alert severity="warning">VAT period not found.</Alert>
      </Box>
    );
  }

  // Check if period is already submitted
  const isSubmitted = periodData.status === 'SUBMITTED';
  
  return (
    <Box sx={{ p: 2 }}>
      <Button 
        startIcon={<ArrowBackIcon />} 
        variant="outlined" 
        sx={{ mb: 2 }}
        onClick={() => navigate('/vat-monitoring')}
      >
        Back to VAT Monitoring
      </Button>
      
      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {isSubmitted ? 'VAT Return Details' : 'Prepare VAT Return'}
          </Typography>
          
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="text.secondary">
                Period
              </Typography>
              <Typography variant="body1">
                {formatDate(periodData.period_start_date)} to {formatDate(periodData.period_end_date)}
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="text.secondary">
                Due Date
              </Typography>
              <Typography variant="body1">
                {formatDate(periodData.due_date)}
              </Typography>
            </Grid>
            
            <Grid item xs={12} md={4}>
              <Typography variant="body2" color="text.secondary">
                Status
              </Typography>
              <Typography variant="body1" color={isSubmitted ? 'success.main' : 'text.primary'}>
                {periodData.status === 'SUBMITTED' 
                  ? `Submitted on ${formatDate(periodData.submission_date)}` 
                  : periodData.status}
              </Typography>
            </Grid>
          </Grid>
          
          {isSubmitted && (
            <Alert severity="info" sx={{ mb: 3 }}>
              This VAT return has already been submitted and cannot be modified.
            </Alert>
          )}
          
          {!isSubmitted && !vatCalculation && (
            <Box sx={{ mb: 3, textAlign: 'center' }}>
              <Button 
                variant="contained" 
                onClick={calculateVAT}
                disabled={calculating}
                sx={{ my: 2 }}
              >
                {calculating ? <CircularProgress size={24} /> : 'Calculate VAT'}
              </Button>
              
              <Typography variant="body2" color="text.secondary">
                Click the button above to calculate VAT for this period based on your income and expenses.
              </Typography>
            </Box>
          )}
          
          {vatCalculation && (
            <Box>
              <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                VAT Summary
              </Typography>
              
              <Paper sx={{ mb: 3 }}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>VAT charged on sales (Output VAT)</TableCell>
                        <TableCell align="right">{formatCurrency(vatCalculation.output_vat)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>VAT claimed on purchases (Input VAT)</TableCell>
                        <TableCell align="right">{formatCurrency(vatCalculation.input_vat)}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell><strong>VAT due for this period</strong></TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                          {formatCurrency(vatCalculation.vat_due)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              
              {!isSubmitted && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Submit VAT Return
                  </Typography>
                  
                  <Alert severity="info" sx={{ mb: 3 }}>
                    Once submitted, this VAT return cannot be modified. Please ensure all information is correct.
                  </Alert>
                  
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={declaration} 
                        onChange={(e) => setDeclaration(e.target.checked)}
                        disabled={submitting}
                      />
                    }
                    label="I declare that the information I have given on this VAT Return is true and complete."
                    sx={{ mb: 2, display: 'block' }}
                  />
                  
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={submitVATReturn}
                    disabled={submitting || !declaration}
                  >
                    {submitting ? <CircularProgress size={24} /> : 'Submit VAT Return'}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
      
      {isSubmitted && periodData.submission_reference && (
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Submission Details
            </Typography>
            
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="text.secondary">
                  Submission Reference
                </Typography>
                <Typography variant="body1">
                  {periodData.submission_reference}
                </Typography>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="text.secondary">
                  Submission Date
                </Typography>
                <Typography variant="body1">
                  {formatDate(periodData.submission_date)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default VatReturnForm;