import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Alert,
  AlertTitle,
  LinearProgress,
  Divider,
  Grid,
  CircularProgress
} from '@mui/material';

const VatMonitoring = () => {
  const [vatStatus, setVatStatus] = useState(null);
  const [companyProfile, setCompanyProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState(null);

  // Define these functions before they're used
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value);
  };

  const calculateProgressPercentage = (current, threshold) => {
    if (!current || !threshold) return 0;
    return Math.min(100, (current / threshold) * 100);
  };

  const getProgressColor = (percentage) => {
    if (percentage >= 100) return 'error';
    if (percentage >= 80) return 'warning';
    return 'primary';
  };

  // First fetch company profile to get VAT registration status
  useEffect(() => {
    const fetchCompanyProfile = async () => {
      try {
        console.log("Fetching company profile");
        const response = await api.get('/company/profile');
        console.log("Company profile response:", response.data);
        setCompanyProfile(response.data);
      } catch (err) {
        console.error("Error fetching company profile:", err);
        setError("Failed to load company profile");
      }
    };

    fetchCompanyProfile();
  }, []);

  // Then fetch VAT thresholds and alerts
  useEffect(() => {
    const fetchVatStatus = async () => {
      if (!companyProfile) {
        return;
      }

      try {
        console.log("Fetching VAT status");
        setLoading(true);
        
        const response = await api.get('/vat/status');
        console.log("VAT status response:", response.data);
        
        if (response.data.success) {
          setVatStatus(response.data.data);
          
          // Save debug info if available
          if (response.data.data.debug) {
            setDebugInfo(response.data.data.debug);
          }
          
          setError(null);
        } else {
          setError(response.data.error || 'Failed to load VAT data');
        }
      } catch (err) {
        console.error('Error fetching VAT status:', err);
        setError('Failed to load VAT threshold information: ' + (err.response?.data?.error || err.message));
      } finally {
        setLoading(false);
      }
    };

    fetchVatStatus();
  }, [companyProfile]);

  if (loading && !companyProfile) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  
  // Determine VAT status from VAT status data, not company profile
  const isVatRegistered = vatStatus?.is_vat_registered === true || vatStatus?.is_vat_registered === 1;
  console.log("Is VAT registered:", isVatRegistered);
  console.log("VAT Number from status:", vatStatus?.vat_number);

  // If not VAT registered, show simplified view
  if (!isVatRegistered) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            VAT Status
          </Typography>
          <Alert severity="info">
            Your business is not currently VAT registered.
          </Alert>
          
          {vatStatus && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle1">
                12-Month Turnover: {formatCurrency(vatStatus.annual_turnover)}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Projected Annual: {formatCurrency(vatStatus.projected_annual)}
              </Typography>
              
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  VAT Registration Threshold: {formatCurrency(vatStatus.registration_threshold)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold)} 
                      color={getProgressColor(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold))}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      {Math.round(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold))}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    );
  }

  // If still loading VAT threshold data
  if (loading) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            VAT Monitoring
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
            <CircularProgress size={30} />
          </Box>
        </CardContent>
      </Card>
    );
  }

  // If no VAT threshold data yet but we know we're VAT registered
  if (!vatStatus) {
    return (
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            VAT Monitoring
          </Typography>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1">
              VAT Status: <strong>Registered</strong>
            </Typography>
            <Typography variant="subtitle2">
              VAT Number: {companyProfile.vat_number}
            </Typography>
            <Typography variant="subtitle2">
              VAT Scheme: {companyProfile.vat_scheme?.replace(/_/g, ' ') || 'STANDARD RATE'}
            </Typography>
          </Box>
          <Alert severity="info">
            Loading VAT threshold information...
          </Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          VAT Monitoring
        </Typography>
        
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1">
            VAT Status: <strong>Registered</strong>
          </Typography>
          <Typography variant="subtitle2">
            VAT Number: {vatStatus.vat_number || 'Not specified'}
          </Typography>
          <Typography variant="subtitle2">
            VAT Scheme: {vatStatus.vat_scheme?.replace(/_/g, ' ') || 'STANDARD RATE'}
          </Typography>
        </Box>
        
        <Divider sx={{ mb: 3 }} />
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1">
                12-Month Turnover: {formatCurrency(vatStatus.annual_turnover)}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                Projected Annual: {formatCurrency(vatStatus.projected_annual)}
              </Typography>
            </Box>
            
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                VAT Registration Threshold: {formatCurrency(vatStatus.registration_threshold)}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress 
                    variant="determinate" 
                    value={calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold)} 
                    color={getProgressColor(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold))}
                    sx={{ height: 10, borderRadius: 5 }}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" color="text.secondary">
                    {Math.round(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.registration_threshold))}%
                  </Typography>
                </Box>
              </Box>
            </Box>
            
            {(vatStatus.vat_scheme === 'FLAT_RATE' || vatStatus.vat_scheme === 'FIRST_YEAR_FLAT_RATE') && vatStatus.flat_rate_exit_threshold && (
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Flat Rate Exit Threshold: {formatCurrency(vatStatus.flat_rate_exit_threshold)}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.flat_rate_exit_threshold)} 
                      color={getProgressColor(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.flat_rate_exit_threshold))}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                      {Math.round(calculateProgressPercentage(vatStatus.annual_turnover, vatStatus.flat_rate_exit_threshold))}%
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
            
            {/* Debug info for troubleshooting */}
            {debugInfo && (
              <Box sx={{ mt: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
                <Typography variant="caption" component="div" color="text.secondary">
                  Debug Info:
                </Typography>
                <Typography variant="caption" component="div" color="text.secondary">
                  Total records: {debugInfo.total_records}
                </Typography>
                <Typography variant="caption" component="div" color="text.secondary">
                  Date range: {debugInfo.date_range}
                </Typography>
                {debugInfo.account_id && (
                  <Typography variant="caption" component="div" color="text.secondary">
                    Account ID: {debugInfo.account_id}
                  </Typography>
                )}
              </Box>
            )}
          </Grid>
          
          <Grid item xs={12} md={6}>
            {vatStatus.alerts && vatStatus.alerts.length > 0 ? (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  VAT Alerts
                </Typography>
                {vatStatus.alerts.map((alert, index) => (
                  <Alert 
                    key={index} 
                    severity={alert.level === 'danger' ? 'error' : 'warning'}
                    sx={{ mb: 2 }}
                  >
                    <AlertTitle>{alert.type.replace(/_/g, ' ')}</AlertTitle>
                    {alert.message}
                  </Alert>
                ))}
              </Box>
            ) : (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  VAT Status
                </Typography>
                <Alert severity="success">
                  Your VAT status is healthy with no alerts.
                </Alert>
              </Box>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VatMonitoring;