import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  Card, 
  CardContent,
  CardActions,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Divider,
  Chip
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import api from './axiosConfig';

const CommissionDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commissionTypes, setCommissionTypes] = useState([]);
  const [employeeCommissions, setEmployeeCommissions] = useState([]);
  const [debugInfo, setDebugInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCommissionData = async () => {
      try {
        setLoading(true);
        
        // Add timestamp for cache busting
        const timestamp = new Date().getTime();
        
        // Get commission types
        const typesResponse = await api.get(`/commission/types?t=${timestamp}`);
        
        if (typesResponse.data.success) {
          setCommissionTypes(typesResponse.data.commission_types || []);
          
          if (typesResponse.data.debug) {
            setDebugInfo(typesResponse.data.debug);
          }
        } else {
          setError(typesResponse.data.error || 'Failed to load commission types');
        }
        
        // Get employees with commissions
        const employeesResponse = await api.get(`/employees?with_commission=true&t=${timestamp}`);
        
        if (employeesResponse.data.success) {
          setEmployeeCommissions(employeesResponse.data.employees || []);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching commission data:', err);
        setError('Failed to load commission data: ' + (err.response?.data?.error || err.message));
        setLoading(false);
      }
    };
    
    fetchCommissionData();
    
    // Cleanup function
    return () => {
      // Reset state when component unmounts to prevent stale data
      setCommissionTypes([]);
      setEmployeeCommissions([]);
      setDebugInfo(null);
    };
  }, []);

  const handleCreateCommissionType = () => {
    navigate('/commission/types/new');
  };

  const handleEditCommissionType = (id) => {
    navigate(`/commission/types/${id}`);
  };

  const handleManageEmployeeCommission = (id) => {
    navigate(`/commission/employee/${id}`);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">Commission Management</Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={handleCreateCommissionType}
        >
          Create Commission Type
        </Button>
      </Box>
      
      {/* Debug Info */}
      {debugInfo && (
        <Box sx={{ mb: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
          <Typography variant="caption" color="text.secondary">
            Debug: API account_id: {debugInfo.account_id || 'Unknown'} | 
            User ID: {debugInfo.user_id || 'Unknown'}
          </Typography>
        </Box>
      )}
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>Commission Types</Typography>
            
            {commissionTypes.length > 0 ? (
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Category</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Default</TableCell>
                      <TableCell align="center">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {commissionTypes.map((type) => (
                      <TableRow key={type.id}>
                        <TableCell>{type.name}</TableCell>
                        <TableCell>{type.category_name}</TableCell>
                        <TableCell>{type.description}</TableCell>
                        <TableCell>
                          {type.is_default && <Chip size="small" color="primary" label="Default" />}
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={() => handleEditCommissionType(type.id)}
                            sx={{ mr: 1 }}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Alert severity="info">
                No commission types have been created yet. Click the "Create Commission Type" button to get started.
              </Alert>
            )}
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Employee Commissions</Typography>
            
            {employeeCommissions.length > 0 ? (
              <Grid container spacing={2}>
                {employeeCommissions.map((employee) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={employee.id}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6">{employee.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {employee.commission_type || 'No commission type assigned'}
                        </Typography>
                        <Divider sx={{ my: 1 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">Service: </Typography>
                          <Typography variant="body2" fontWeight="bold">
                            {employee.commission_service_rate ? `${employee.commission_service_rate}%` : 'N/A'}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2">Product: </Typography>
                          <Typography variant="body2" fontWeight="bold">
                            {employee.commission_product_rate ? `${employee.commission_product_rate}%` : 'N/A'}
                          </Typography>
                        </Box>
                      </CardContent>
                      <CardActions>
                        <Button
                          size="small"
                          startIcon={<PersonIcon />}
                          onClick={() => handleManageEmployeeCommission(employee.id)}
                          fullWidth
                        >
                          Manage Commission
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Alert severity="info">
                No employees with commission settings found.
              </Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CommissionDashboard;