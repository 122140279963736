import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Grid, 
  Paper, 
  Typography, 
  Container, 
  CircularProgress, 
  Alert, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Button, 
  Switch, 
  FormControlLabel,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl
} from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { SecurityOutlined, Speed, People, ErrorOutline, Assessment, Add, Edit, Delete } from '@mui/icons-material';
import { useAuth } from './AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AdminDashboard = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [users, setUsers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [auditMetrics, setAuditMetrics] = useState(null);
  const [vatRates, setVatRates] = useState({
    standard_rate: '',
    first_year_rate: '',
    following_year_rate: ''
  });
  const [newUser, setNewUser] = useState({
    email: '',
    tempPassword: '',
    role: 'ACCOUNT_OWNER',
    has_free_access: true
  });

  // NMW Rate Management States
  const [nmwRates, setNmwRates] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openRateDialog, setOpenRateDialog] = useState(false);
  const [currentRate, setCurrentRate] = useState({
    rate_type: 'NMW_OVER21',
    value: '',
    effective_date: '',
    is_future_rate: false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [dashboardResponse, usersResponse, accountsResponse, auditResponse, nmwRatesResponse] = await Promise.all([
          api.get('/admin/dashboard'),
          api.get('/admin/users'),
          api.get('/admin/accounts'),
          api.get('/admin/audit-metrics'),
          api.get('/admin/payroll/rates')
        ]);
        
        setDashboardData(dashboardResponse.data);
        setUsers(usersResponse.data);
        setAccounts(accountsResponse.data);
        setVatRates(dashboardResponse.data.vat_rates);
        setAuditMetrics(auditResponse.data);
        setNmwRates(nmwRatesResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching admin data:', error);
        setError('Failed to load admin data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
    
    // Refresh audit metrics every 5 minutes
    const interval = setInterval(() => {
      api.get('/admin/audit-metrics')
        .then(response => setAuditMetrics(response.data))
        .catch(error => console.error('Error refreshing audit metrics:', error));
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleVatRateChange = (event) => {
    setVatRates({
      ...vatRates,
      [event.target.name]: event.target.value
    });
  };

  const handleVatRateSubmit = async (event) => {
    event.preventDefault();
    try {
      await api.post('/admin/vat-rates', vatRates);
      alert('VAT rates updated successfully');
    } catch (error) {
      console.error('Error updating VAT rates:', error);
      alert('Failed to update VAT rates');
    }
  };

  const handleNewUserChange = (event) => {
    const { name, value, checked } = event.target;
    setNewUser({
      ...newUser,
      [name]: name === 'has_free_access' ? checked : value
    });
  };

  const handleCreateUser = async (event) => {
    event.preventDefault();
    try {
      const userData = {
        ...newUser,
        name: newUser.email.split('@')[0],
        account_name: newUser.email.split('@')[0],
        account_details: {
          is_active: true,
          subscription_status: 'active',
          manager_can_view_notes: false
        }
      };

      await api.post('/admin/create-user', userData);
      alert('User and account created successfully');
      
      const [usersResponse, accountsResponse] = await Promise.all([
        api.get('/admin/users'),
        api.get('/admin/accounts')
      ]);
      
      setUsers(usersResponse.data);
      setAccounts(accountsResponse.data);
      
      setNewUser({ 
        email: '', 
        tempPassword: '', 
        role: 'ACCOUNT_OWNER', 
        has_free_access: true 
      });
    } catch (error) {
      console.error('Error creating user:', error);
      alert('Failed to create user and account');
    }
  };

  const handleToggleFreeAccess = async (userId, currentStatus) => {
    try {
      await api.put(`/admin/users/${userId}/toggle-free-access`);
      const response = await api.get('/admin/users');
      setUsers(response.data);
      alert('User free access status updated successfully');
    } catch (error) {
      console.error('Error updating user free access status:', error);
      alert('Failed to update user free access status');
    }
  };

  const handleToggleAccountStatus = async (accountId) => {
    try {
      await api.put(`/admin/toggle-account-status/${accountId}`);
      const response = await api.get('/admin/accounts');
      setAccounts(response.data);
      alert('Account status updated successfully');
    } catch (error) {
      console.error('Error updating account status:', error);
      alert('Failed to update account status');
    }
  };

  const handleToggleAccountFreeAccess = async (accountId) => {
    try {
      await api.put(`/admin/accounts/${accountId}/toggle-free-access`);
      const response = await api.get('/admin/accounts');
      setAccounts(response.data);
      alert('Account free access status updated successfully');
    } catch (error) {
      console.error('Error updating account free access:', error);
      alert('Failed to update account free access');
    }
  };

  // NMW Rate Management Functions
  const handleOpenRateDialog = (rate = null) => {
    if (rate) {
      setCurrentRate({
        id: rate.id,
        rate_type: rate.rate_type,
        value: rate.value,  // Changed from rate_value to value to match backend
        effective_date: rate.effective_date.split('T')[0], // Format date for the input
        is_future_rate: rate.is_future_rate
      });
    } else {
      setCurrentRate({
        rate_type: 'NMW_OVER21',
        value: '',  // Changed from rate_value to value to match backend
        effective_date: '',
        is_future_rate: false
      });
    }
    setOpenRateDialog(true);
  };

  const handleCloseRateDialog = () => {
    setOpenRateDialog(false);
  };

  const handleRateChange = (event) => {
    const { name, value, checked, type } = event.target;
    setCurrentRate({
      ...currentRate,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSaveRate = async () => {
    try {
      // Prepare payload with the correct property name (value instead of rate_value)
      const payload = {
        ...currentRate,
        value: currentRate.value // Ensure we're using the right property name
      };
      
      if (currentRate.id) {
        // Update existing rate
        await api.put(`/admin/payroll/rates/${currentRate.id}`, payload);
        alert('Rate updated successfully');
      } else {
        // Create new rate
        await api.post('/admin/payroll/rates', payload);
        alert('Rate created successfully');
      }
      
      // Refresh rates
      const response = await api.get('/admin/payroll/rates');
      setNmwRates(response.data);
      handleCloseRateDialog();
    } catch (error) {
      console.error('Error saving rate:', error);
      alert(`Failed to save rate: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleDeleteRate = async (id) => {
    if (window.confirm('Are you sure you want to delete this rate?')) {
      try {
        await api.delete(`/admin/payroll/rates/${id}`);
        
        // Refresh rates
        const response = await api.get('/admin/payroll/rates');
        setNmwRates(response.data);
        alert('Rate deleted successfully');
      } catch (error) {
        console.error('Error deleting rate:', error);
        alert(`Failed to delete rate: ${error.response?.data?.message || error.message}`);
      }
    }
  };

  // Helper function to group rates by type
  const getRatesByType = () => {
    const grouped = {};
    nmwRates.forEach(rate => {
      if (!grouped[rate.rate_type]) {
        grouped[rate.rate_type] = [];
      }
      grouped[rate.rate_type].push(rate);
    });
    
    // Sort rates by effective date
    Object.keys(grouped).forEach(type => {
      grouped[type].sort((a, b) => new Date(a.effective_date) - new Date(b.effective_date));
    });
    
    return grouped;
  };

  // Helper function to get rate type display name
  const getRateTypeDisplayName = (rateType) => {
    const rateNames = {
      'NMW_OVER21': 'National Minimum Wage (21+)',
      'NMW_18TO20': 'National Minimum Wage (18-20)',
      'NMW_UNDER18': 'National Minimum Wage (Under 18)',
      'NMW_APPRENTICE': 'Apprentice Rate',
      'ENIC_STANDARD': 'Employer NIC Rate',
      'ENIC_THRESHOLD': 'NIC Threshold'
    };
    return rateNames[rateType] || rateType;
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const groupedRates = getRatesByType();

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Admin Dashboard</Typography>
      
      {/* Tabs for different sections */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="admin dashboard tabs">
          <Tab label="Dashboard Overview" />
          <Tab label="User Management" />
          <Tab label="Account Management" />
          <Tab label="VAT Rates" />
          <Tab label="NMW & Threshold Management" />
        </Tabs>
      </Box>

      {/* Dashboard Overview Tab */}
      {tabValue === 0 && (
        <>
          {/* Audit Metrics Section */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>Security Audit Metrics (Last 24h)</Typography>
            </Grid>
            
            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography color="textSecondary" variant="subtitle2">Total Events</Typography>
                  <Typography variant="h4">{auditMetrics?.totalEvents || 0}</Typography>
                </Box>
                <Assessment color="primary" />
              </Paper>
            </Grid>

            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography color="textSecondary" variant="subtitle2">Security Violations</Typography>
                  <Typography variant="h4" color={auditMetrics?.securityViolations > 0 ? "error" : "inherit"}>
                    {auditMetrics?.securityViolations || 0}
                  </Typography>
                </Box>
                <SecurityOutlined color={auditMetrics?.securityViolations > 0 ? "error" : "primary"} />
              </Paper>
            </Grid>

            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography color="textSecondary" variant="subtitle2">Active Users</Typography>
                  <Typography variant="h4">{auditMetrics?.activeUsers || 0}</Typography>
                </Box>
                <People color="primary" />
              </Paper>
            </Grid>

            <Grid item xs={12} md={3}>
              <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                  <Typography color="textSecondary" variant="subtitle2">Avg Response Time</Typography>
                  <Typography variant="h4">{auditMetrics?.avgResponseTime || 0}ms</Typography>
                </Box>
                <Speed color="primary" />
              </Paper>
            </Grid>

            {/* Activity Timeline Chart */}
            <Grid item xs={12}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Activity Timeline</Typography>
                <Box sx={{ height: 300 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={auditMetrics?.timeline || []}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="time" />
                      <YAxis />
                      <Tooltip />
                      <Line type="monotone" dataKey="events" stroke="#8884d8" name="Events" />
                      <Line type="monotone" dataKey="errors" stroke="#ff0000" name="Errors" />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>

            {/* Recent Errors */}
            {auditMetrics?.errors && auditMetrics.errors.length > 0 && (
              <Grid item xs={12}>
                <Paper sx={{ p: 2 }}>
                  <Typography variant="h6" gutterBottom>Recent Errors</Typography>
                  <Timeline>
                    {auditMetrics.errors.map((error, index) => (
                      <TimelineItem key={index}>
                        <TimelineSeparator>
                          <TimelineDot color="error">
                            <ErrorOutline />
                          </TimelineDot>
                          {index < auditMetrics.errors.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="subtitle2" color="error">
                            {error.message}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {error.count} occurrences
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    ))}
                  </Timeline>
                </Paper>
              </Grid>
            )}
          </Grid>

          {/* Dashboard Summary */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Total Users</Typography>
                <Typography variant="h4">{dashboardData?.total_users}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Total Active Accounts</Typography>
                <Typography variant="h4">{dashboardData?.total_active_accounts || accounts.filter(a => a.is_active).length}</Typography>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}

      {/* User Management Tab */}
      {tabValue === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>Create New User</Typography>
              <form onSubmit={handleCreateUser}>
                <TextField
                  label="Email"
                  name="email"
                  value={newUser.email}
                  onChange={handleNewUserChange}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Temporary Password"
                  name="tempPassword"
                  value={newUser.tempPassword}
                  onChange={handleNewUserChange}
                  type="password"
                  fullWidth
                  margin="normal"
                  required
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={newUser.has_free_access}
                      onChange={handleNewUserChange}
                      name="has_free_access"
                      color="primary"
                    />
                  }
                  label="Free Access"
                />
                <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                  Create User
                </Button>
              </form>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>User List</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Free Access</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.map((user) => (
                      <TableRow key={user.id}>
                        <TableCell>{user.id}</TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>{user.has_free_access ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          <Button 
                            onClick={() => handleToggleFreeAccess(user.id, user.has_free_access)}
                            variant="outlined"
                            color={user.has_free_access ? "secondary" : "primary"}
                          >
                            {user.has_free_access ? 'Remove Free Access' : 'Grant Free Access'}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    )}
    
    {/* Account Management Tab */}
    {tabValue === 2 && (
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Account Management</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Account Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Subscription Status</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {accounts.map((account) => (
                  <TableRow key={account.id}>
                    <TableCell>{account.name}</TableCell>
                    <TableCell>
                      <span style={{ color: account.is_active ? 'green' : 'red' }}>
                        {account.is_active ? 'Active' : 'Inactive'}
                      </span>
                    </TableCell>
                    <TableCell>{account.subscription_status}</TableCell>
                    <TableCell>
                      {new Date(account.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>
                      <Button 
                        onClick={() => handleToggleAccountStatus(account.id)}
                        variant="outlined"
                        color={account.is_active ? "secondary" : "primary"}
                        sx={{ mr: 1 }}
                      >
                        {account.is_active ? 'Deactivate' : 'Activate'}
                      </Button>
                      <Button
                        onClick={() => handleToggleAccountFreeAccess(account.id)}
                        variant="outlined"
                        color="primary"
                      >
                        Toggle Free Access
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    )}
    
    {/* VAT Rates Tab */}
    {tabValue === 3 && (
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>VAT Rates</Typography>
          <form onSubmit={handleVatRateSubmit}>
            <TextField
              label="Standard Rate"
              name="standard_rate"
              value={vatRates.standard_rate}
              onChange={handleVatRateChange}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="First Year Rate"
              name="first_year_rate"
              value={vatRates.first_year_rate}
              onChange={handleVatRateChange}
              type="number"
              fullWidth
              margin="normal"
            />
            <TextField
              label="Following Year Rate"
              name="following_year_rate"
              value={vatRates.following_year_rate}
              onChange={handleVatRateChange}
              type="number"
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary">
              Update VAT Rates
            </Button>
          </form>
        </Paper>
      </Grid>
    )}
    
    {/* NMW & Threshold Management Tab */}
    {tabValue === 4 && (
      <>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">National Minimum Wage & Threshold Management</Typography>
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<Add />}
                onClick={() => handleOpenRateDialog()}
              >
                Add New Rate
              </Button>
            </Box>
            
            {Object.keys(groupedRates).length > 0 ? (
              Object.keys(groupedRates).map(rateType => (
                <Box key={rateType} sx={{ mb: 4 }}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {getRateTypeDisplayName(rateType)}
                  </Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Rate Value</TableCell>
                          <TableCell>Effective Date</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {groupedRates[rateType].map((rate) => (
                          <TableRow key={rate.id}>
                            <TableCell>
                              {rate.rate_type.includes('ENIC') 
                                ? `${rate.value}%` 
                                : `£${parseFloat(rate.value).toFixed(2)}`}
                            </TableCell>
                            <TableCell>
                              {new Date(rate.effective_date).toLocaleDateString()}
                            </TableCell>
                            <TableCell>
                              <span style={{ 
                                color: rate.is_future_rate ? 'orange' : 'green',
                                fontWeight: 'bold'
                              }}>
                                {rate.is_future_rate ? 'Future Rate' : 'Current Rate'}
                              </span>
                            </TableCell>
                            <TableCell>
                              <IconButton 
                                size="small" 
                                color="primary"
                                onClick={() => handleOpenRateDialog(rate)}
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                              <IconButton 
                                size="small" 
                                color="error"
                                onClick={() => handleDeleteRate(rate.id)}
                              >
                                <Delete fontSize="small" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              ))
            ) : (
              <Alert severity="info">No rates have been configured yet.</Alert>
            )}
          </Paper>
        </Grid>
      </>
    )}

    {/* Rate Dialog */}
    <Dialog open={openRateDialog} onClose={handleCloseRateDialog}>
      <DialogTitle>
        {currentRate.id ? 'Edit Rate' : 'Add New Rate'}
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="rate-type-label">Rate Type</InputLabel>
          <Select
             labelId="rate-type-label"
            id="rate-type"
            name="rate_type"
            value={currentRate.rate_type}
            onChange={handleRateChange}
            label="Rate Type"
            >
            <MenuItem value="NMW_OVER21">National Minimum Wage (21+)</MenuItem>
            <MenuItem value="NMW_18TO20">National Minimum Wage (18-20)</MenuItem>
            <MenuItem value="NMW_UNDER18">National Minimum Wage (Under 18)</MenuItem>
            <MenuItem value="NMW_APPRENTICE">Apprentice Rate</MenuItem>
            <MenuItem value="ENIC_THRESHOLD">NIC Threshold</MenuItem>
            <MenuItem value="ENIC_STANDARD">Employer NIC Rate</MenuItem>
            <MenuItem value="EMPLOYMENT_ALLOWANCE">Employment Allowance</MenuItem>
          </Select>
        </FormControl>
        
        <TextField
          label="Rate Value"
          name="value"
          value={currentRate.value}
          onChange={handleRateChange}
          type="number"
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: currentRate.rate_type?.includes('ENIC_') && currentRate.rate_type !== 'ENIC_THRESHOLD' ? null : '£',
            endAdornment: currentRate.rate_type?.includes('ENIC_') && currentRate.rate_type !== 'ENIC_THRESHOLD' ? '%' : null,
          }}
        />
        
        <TextField
          label="Effective Date"
          name="effective_date"
          value={currentRate.effective_date}
          onChange={handleRateChange}
          type="date"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        
        <FormControlLabel
          control={
            <Switch
              checked={currentRate.is_future_rate}
              onChange={handleRateChange}
              name="is_future_rate"
              color="primary"
            />
          }
          label="Future Rate"
          sx={{ mt: 2 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRateDialog}>Cancel</Button>
        <Button onClick={handleSaveRate} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  </Container>
);
};

export default AdminDashboard;