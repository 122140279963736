import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  Grid, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  TextField, 
  Divider, 
  CircularProgress, 
  Alert, 
  IconButton, 
  Card, 
  CardContent
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CalculateIcon from '@mui/icons-material/Calculate';
import SaveIcon from '@mui/icons-material/Save';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, subDays, startOfMonth, endOfMonth } from 'date-fns';
import api from './axiosConfig';

const EmployeeCommission = () => {
  const { employeeId } = useParams();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [calculating, setCalculating] = useState(false);
  const [error, setError] = useState(null);
  
  const [employee, setEmployee] = useState(null);
  const [commissionTypes, setCommissionTypes] = useState([]);
  const [selectedCommissionType, setSelectedCommissionType] = useState(null);
  const [commissionParameters, setCommissionParameters] = useState([]);
  const [paramValues, setParamValues] = useState({});
  
  const [calculationPeriod, setCalculationPeriod] = useState({
    start_date: format(startOfMonth(subDays(new Date(), 30)), 'yyyy-MM-dd'),
    end_date: format(endOfMonth(subDays(new Date(), 30)), 'yyyy-MM-dd')
  });
  
  const [calculationResult, setCalculationResult] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Add timestamp for cache busting
        const timestamp = new Date().getTime();
        
        // Get employee details
        const employeeResponse = await api.get(`/employees/${employeeId}?t=${timestamp}`);
        
        if (employeeResponse.data.success) {
          setEmployee(employeeResponse.data.employee);
        } else {
          setError(employeeResponse.data.error || 'Failed to load employee details');
          setLoading(false);
          return;
        }
        
        // Get commission types
        const typesResponse = await api.get(`/commission/types?t=${timestamp}`);
        
        if (typesResponse.data.success) {
          setCommissionTypes(typesResponse.data.commission_types || []);
        } else {
          setError(typesResponse.data.error || 'Failed to load commission types');
          setLoading(false);
          return;
        }
        
        // Get employee's current commission
        const commissionResponse = await api.get(`/commission/employee/${employeeId}?t=${timestamp}`);
        
        if (commissionResponse.data.success && commissionResponse.data.commission) {
          const commission = commissionResponse.data.commission;
          
          // Set selected commission type
          setSelectedCommissionType(commission.commission_type_id);
          
          // Get commission type details to get parameters
          const typeDetailsResponse = await api.get(`/commission/types/${commission.commission_type_id}?t=${timestamp}`);
          
          if (typeDetailsResponse.data.success) {
            const typeDetails = typeDetailsResponse.data.commission_type;
            setCommissionParameters(typeDetails.parameters || []);
            
            // Set parameter values
            const values = {};
            Object.keys(commission.values || {}).forEach(key => {
              values[key] = commission.values[key];
            });
            setParamValues(values);
          }
        } else {
          // If no commission is assigned yet, just set default type
          const defaultType = typesResponse.data.commission_types.find(t => t.is_default);
          if (defaultType) {
            setSelectedCommissionType(defaultType.id);
            
            // Get default type parameters
            const defaultTypeResponse = await api.get(`/commission/types/${defaultType.id}?t=${timestamp}`);
            if (defaultTypeResponse.data.success) {
              setCommissionParameters(defaultTypeResponse.data.commission_type.parameters || []);
              
              // Set default parameter values
              const defaultValues = {};
              defaultTypeResponse.data.commission_type.parameters.forEach(param => {
                defaultValues[param.param_name] = param.default_value;
              });
              setParamValues(defaultValues);
            }
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data: ' + (err.response?.data?.error || err.message));
        setLoading(false);
      }
    };
    
    fetchData();
    
    // Cleanup function
    return () => {
      setEmployee(null);
      setCommissionTypes([]);
      setSelectedCommissionType(null);
      setCommissionParameters([]);
      setParamValues({});
      setCalculationResult(null);
    };
  }, [employeeId]);
  
  const handleCommissionTypeChange = async (e) => {
    const typeId = e.target.value;
    setSelectedCommissionType(typeId);
    
    try {
      // Get commission type details
      const timestamp = new Date().getTime();
      const typeResponse = await api.get(`/commission/types/${typeId}?t=${timestamp}`);
      
      if (typeResponse.data.success) {
        const typeDetails = typeResponse.data.commission_type;
        setCommissionParameters(typeDetails.parameters || []);
        
        // Set default parameter values
        const defaultValues = {};
        typeDetails.parameters.forEach(param => {
          defaultValues[param.param_name] = param.default_value;
        });
        setParamValues(defaultValues);
      } else {
        setError(typeResponse.data.error || 'Failed to load commission type details');
      }
    } catch (err) {
      console.error('Error loading commission type details:', err);
      setError('Failed to load commission type details: ' + (err.response?.data?.error || err.message));
    }
  };
  
  const handleParamValueChange = (paramName, value) => {
    setParamValues(prev => ({
      ...prev,
      [paramName]: value
    }));
  };
  
  const handlePeriodChange = (field, value) => {
    setCalculationPeriod(prev => ({
      ...prev,
      [field]: value ? format(value, 'yyyy-MM-dd') : null
    }));
  };
  
  const handleCalculate = async () => {
    if (!selectedCommissionType) {
      setError('Please select a commission type before calculating');
      return;
    }
    
    try {
      setCalculating(true);
      
      const data = {
        period_start: calculationPeriod.start_date,
        period_end: calculationPeriod.end_date
      };
      
      const timestamp = new Date().getTime();
      const response = await api.post(`/commission/calculate/${employeeId}?t=${timestamp}`, data);
      
      if (response.data.success) {
        setCalculationResult(response.data);
        setError(null);
      } else {
        setError(response.data.error || 'Failed to calculate commission');
      }
      
      setCalculating(false);
    } catch (err) {
      console.error('Error calculating commission:', err);
      setError('Failed to calculate commission: ' + (err.response?.data?.error || err.message));
      setCalculating(false);
    }
  };
  
  const handleSave = async () => {
    if (!selectedCommissionType) {
      setError('Please select a commission type before saving');
      return;
    }
    
    try {
      setSaving(true);
      
      const data = {
        commission_type_id: selectedCommissionType,
        param_values: paramValues,
        start_date: format(new Date(), 'yyyy-MM-dd')
      };
      
      const timestamp = new Date().getTime();
      const response = await api.post(`/commission/employee/${employeeId}/assign?t=${timestamp}`, data);
      
      if (response.data.success) {
        navigate('/commission/dashboard');
      } else {
        setError(response.data.error || 'Failed to save commission assignment');
        setSaving(false);
      }
    } catch (err) {
      console.error('Error saving commission assignment:', err);
      setError('Failed to save commission assignment: ' + (err.response?.data?.error || err.message));
      setSaving(false);
    }
  };
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value || 0);
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (!employee) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">Employee not found</Alert>
      </Box>
    );
  }
  
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={() => navigate('/commission/dashboard')} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">{employee.name}'s Commission</Typography>
      </Box>
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
      )}
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, height: '100%' }}>
            <Typography variant="h6" gutterBottom>Commission Setup</Typography>
            
            <Box sx={{ mb: 3 }}>
              <FormControl fullWidth sx={{ mb: 3 }}>
                <InputLabel>Commission Type</InputLabel>
                <Select
                  value={selectedCommissionType || ''}
                  onChange={handleCommissionTypeChange}
                  label="Commission Type"
                >
                  {commissionTypes.map(type => (
                    <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              
              {selectedCommissionType && commissionParameters.length > 0 && (
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    Parameters
                  </Typography>
                  
                  {commissionParameters
                    .sort((a, b) => a.display_order - b.display_order)
                    .map(param => (
                      <Box key={param.param_name} sx={{ mb: 2 }}>
                        {param.param_type === 'SELECT' ? (
                          <FormControl fullWidth size="small">
                            <InputLabel>{param.param_label}</InputLabel>
                            <Select
                              value={paramValues[param.param_name] || ''}
                              onChange={(e) => handleParamValueChange(param.param_name, e.target.value)}
                              label={param.param_label}
                            >
                              {(param.options || []).map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : param.param_type === 'BOOLEAN' ? (
                          <FormControl fullWidth size="small">
                            <InputLabel>{param.param_label}</InputLabel>
                            <Select
                              value={paramValues[param.param_name] || 'false'}
                              onChange={(e) => handleParamValueChange(param.param_name, e.target.value)}
                              label={param.param_label}
                            >
                              <MenuItem value="true">Yes</MenuItem>
                              <MenuItem value="false">No</MenuItem>
                            </Select>
                          </FormControl>
                        ) : (
                          <TextField
                            label={param.param_label}
                            value={paramValues[param.param_name] || ''}
                            onChange={(e) => handleParamValueChange(param.param_name, e.target.value)}
                            type={param.param_type === 'NUMBER' || param.param_type === 'PERCENTAGE' ? 'number' : 'text'}
                            InputProps={{
                              endAdornment: param.param_type === 'PERCENTAGE' ? '%' : null
                            }}
                            fullWidth
                            size="small"
                          />
                        )}
                      </Box>
                    ))}
                </Box>
              )}
              
              <Divider sx={{ my: 3 }} />
              
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={handleSave}
                  disabled={saving || !selectedCommissionType}
                >
                  {saving ? 'Saving...' : 'Save Commission Settings'}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>Commission Calculation</Typography>
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Start Date"
                    value={calculationPeriod.start_date ? new Date(calculationPeriod.start_date) : null}
                    onChange={(newValue) => handlePeriodChange('start_date', newValue)}
                    slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="End Date"
                    value={calculationPeriod.end_date ? new Date(calculationPeriod.end_date) : null}
                    onChange={(newValue) => handlePeriodChange('end_date', newValue)}
                    slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                  />
                </Grid>
              </Grid>
            </LocalizationProvider>
            
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CalculateIcon />}
                onClick={handleCalculate}
                disabled={calculating || !selectedCommissionType}
              >
                {calculating ? 'Calculating...' : 'Calculate Commission'}
              </Button>
            </Box>
            
            {calculationResult && calculationResult.success && (
              <Card variant="outlined">
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {format(new Date(calculationPeriod.start_date), 'dd MMM yyyy')} - {format(new Date(calculationPeriod.end_date), 'dd MMM yyyy')}
                  </Typography>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="subtitle1">Commission Amount:</Typography>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {formatCurrency(calculationResult.commission)}
                    </Typography>
                  </Box>
                  
                  <Divider sx={{ my: 2 }} />
                  
                  <Typography variant="subtitle2" gutterBottom>Details:</Typography>
                  
                  {calculationResult.details && Object.entries(calculationResult.details).map(([key, value]) => {
                    if (key === 'commission') return null; // Skip duplicate
                    
                    const isAmount = key.includes('sales') || 
                                    key.includes('commission') || 
                                    key.includes('amount') ||
                                    key.includes('threshold');
                    
                    const isPercentage = key.includes('rate') ||
                                        key.includes('percentage') ||
                                        key.includes('share');
                                        
                    const formattedValue = isAmount ? formatCurrency(value) :
                                          isPercentage ? `${value}%` :
                                          value;
                                          
                    // Format key for display
                    const formattedKey = key
                      .replace(/_/g, ' ')
                      .replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());
                    
                    return (
                      <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                        <Typography variant="body2">{formattedKey}:</Typography>
                        <Typography variant="body2" fontWeight={key === 'total_commission' ? 'bold' : 'normal'}>
                          {formattedValue}
                        </Typography>
                      </Box>
                    );
                  })}
                </CardContent>
              </Card>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmployeeCommission;