import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { 
    TextField, 
    Button, 
    Typography, 
    Container, 
    Box, 
    CircularProgress 
} from '@mui/material';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const { login, isLoggingIn } = useAuth();

    const handleLogin = async (e) => {
        e.preventDefault();
        if (isLoggingIn) return;
        setError('');

        try {
            // Enhanced logging for input validation
            console.log('Login attempt details:', {
                emailLength: email.length,
                emailTrimmedLength: email.trim().length,
                emailHasSpaces: email !== email.trim(),
                passwordLength: password.length,
                passwordHasSpaces: password !== password.trim(),
                rawEmail: email,
                emailCharCodes: Array.from(email).map(c => c.charCodeAt(0))
            });

            const cleanEmail = email.trim().toLowerCase();
            const cleanPassword = password;

            // Log the cleaned credentials (excluding actual password)
            console.log('Attempting login with:', {
                email: cleanEmail,
                cleanEmailLength: cleanEmail.length,
                passwordLength: cleanPassword.length,
                cleanEmailCharCodes: Array.from(cleanEmail).map(c => c.charCodeAt(0))
            });

            const result = await login(cleanEmail, cleanPassword);
            
            // Enhanced result logging
            console.log('Login result:', {
                success: result.success,
                error: result.error,
                hasUser: !!result.user,
                userRole: result.success ? JSON.parse(localStorage.getItem('user'))?.role : null
            });

            if (result.success) {
                const userRole = JSON.parse(localStorage.getItem('user'))?.role;
                
                // Log navigation attempt
                console.log('Navigation based on role:', {
                    role: userRole,
                    timestamp: new Date().toISOString()
                });

                switch(userRole) {
                    case 'RENTAL_USER':
                        navigate('/rental');
                        break;
                    case 'ACCOUNT_OWNER':
                        navigate('/owner_dashboard');
                        break;
                    case 'ACCOUNT_MANAGER':
                        navigate('/manager_dashboard');
                        break;
                    case 'ACCOUNT_USER':
                        navigate('/user_dashboard');
                        break;
                    default:
                        navigate('/dashboard');
                }
            } else {
                // Enhanced error logging
                console.error('Login failed:', {
                    error: result.error,
                    timestamp: new Date().toISOString()
                });
                setError(result.error || 'Login failed. Please check your credentials.');
            }
        } catch (error) {
            // Enhanced error logging
            console.error('Login error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status,
                stack: error.stack,
                timestamp: new Date().toISOString()
            });
            setError('An error occurred during login. Please try again.');
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ 
                marginTop: 8, 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center' 
            }}>
                <Typography component="h1" variant="h5">
                    Login
                </Typography>
                <Box 
                    component="form" 
                    onSubmit={handleLogin} 
                    sx={{ mt: 1 }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={isLoggingIn}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={isLoggingIn}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        disabled={isLoggingIn}
                    >
                        {isLoggingIn ? <CircularProgress size={24} /> : 'Sign In'}
                    </Button>
                </Box>
                {error && (
                    <Typography color="error" sx={{ mt: 2 }}>
                        {error}
                    </Typography>
                )}
            </Box>
        </Container>
    );
};

export default Login;