import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import {
  Box,
  Card,
  Typography,
  Chip,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  CircularProgress
} from '@mui/material';
import { format } from 'date-fns';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const VatReturnPeriods = ({ accountId }) => {
  const [periods, setPeriods] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVatPeriods = async () => {
      try {
        setLoading(true);
        
        // Add timestamp for cache busting
        const timestamp = new Date().getTime();
        
        // First check if we have any periods
        // Remove account_id param - it should use the authenticated account from security context
        const response = await api.get(`/vat/periods?t=${timestamp}`);
        
        if (response.data.success) {
          setPeriods(response.data.data);
          
          // If no periods exist yet, generate them
          if (!response.data.data.current_period && 
              response.data.data.upcoming_periods.length === 0 &&
              response.data.data.past_periods.length === 0) {
            // Generate periods - add timestamp for cache busting
            await api.post(`/vat/periods/generate?t=${timestamp}`, {});
            
            // Fetch them again with new timestamp
            const newTimestamp = new Date().getTime();
            const updatedResponse = await api.get(`/vat/periods?t=${newTimestamp}`);
            if (updatedResponse.data.success) {
              setPeriods(updatedResponse.data.data);
            }
          }
        } else {
          setError(response.data.error || 'Failed to load VAT periods');
        }
      } catch (err) {
        console.error('Error fetching VAT periods:', err);
        setError('Failed to load VAT periods: ' + (err.response?.data?.error || err.message));
      } finally {
        setLoading(false);
      }
    };

    fetchVatPeriods();
    
    // Cleanup function to reset state on unmount
    return () => {
      setPeriods(null);
    };
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
        <CircularProgress size={24} />
        <Typography variant="body2" sx={{ ml: 1 }}>
          Loading VAT return periods...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ mt: 2 }}>
        {error}
      </Alert>
    );
  }

  if (!periods) {
    return null;
  }

  // Function to format dates
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'dd MMM yyyy');
  };

  // Function to get status chip
  const getStatusChip = (status, dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const daysUntilDue = Math.ceil((due - today) / (1000 * 60 * 60 * 24));
    
    if (status === 'SUBMITTED') {
      return <Chip 
        icon={<CheckCircleOutlineIcon />} 
        label="Submitted" 
        color="success" 
        size="small" 
      />;
    } else if (status === 'OVERDUE') {
      return <Chip 
        icon={<ErrorOutlineIcon />} 
        label="Overdue" 
        color="error" 
        size="small" 
      />;
    } else if (daysUntilDue <= 14 && daysUntilDue > 0) {
      return <Chip 
        icon={<WarningIcon />} 
        label={`Due in ${daysUntilDue} days`} 
        color="warning" 
        size="small" 
      />;
    } else {
      return <Chip 
        icon={<CalendarTodayIcon />} 
        label="Pending" 
        color="default" 
        size="small"
      />;
    }
  };

  return (
    <Card sx={{ mt: 3 }}>
      <Box sx={{ p: 2, bgcolor: 'primary.main', color: 'primary.contrastText' }}>
        <Typography variant="h6">VAT Return Periods</Typography>
      </Box>
      
      {/* Current Period */}
      {periods.current_period && (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Current VAT Period
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography variant="body1">
              {formatDate(periods.current_period.period_start_date)} - {formatDate(periods.current_period.period_end_date)}
            </Typography>
            {getStatusChip(periods.current_period.status, periods.current_period.due_date)}
          </Box>
          <Typography variant="body2" color="text.secondary">
            Due: {formatDate(periods.current_period.due_date)}
          </Typography>
          <Button 
            variant="outlined" 
            color="primary" 
            size="small" 
            sx={{ mt: 1 }}
            href={`/vat-return/${periods.current_period.id}`}
          >
            Prepare Return
          </Button>
        </Box>
      )}
      
      {/* Alert for overdue returns */}
      {periods.overdue && periods.overdue.length > 0 && (
        <Alert severity="error" sx={{ m: 2 }}>
          You have {periods.overdue.length} overdue VAT {periods.overdue.length === 1 ? 'return' : 'returns'}.
        </Alert>
      )}
      
      {/* Alert for upcoming due dates */}
      {periods.due_soon && periods.due_soon.length > 0 && periods.due_soon.filter(p => p.status !== 'SUBMITTED').length > 0 && (
        <Alert severity="warning" sx={{ m: 2 }}>
          You have {periods.due_soon.filter(p => p.status !== 'SUBMITTED').length} VAT {periods.due_soon.filter(p => p.status !== 'SUBMITTED').length === 1 ? 'return' : 'returns'} due soon.
        </Alert>
      )}
      
      <Divider />
      
      {/* Upcoming periods */}
      {periods.upcoming_periods && periods.upcoming_periods.length > 0 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Upcoming Periods
          </Typography>
          <List dense>
            {periods.upcoming_periods.map((period) => (
              <ListItem key={period.id} sx={{ py: 0.5 }}>
                <ListItemText
                  primary={`${formatDate(period.period_start_date)} - ${formatDate(period.period_end_date)}`}
                  secondary={`Due: ${formatDate(period.due_date)}`}
                />
                {getStatusChip(period.status, period.due_date)}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      
      <Divider />
      
      {/* Past periods */}
      {periods.past_periods && periods.past_periods.length > 0 && (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Past Periods
          </Typography>
          <List dense>
            {periods.past_periods.map((period) => (
              <ListItem key={period.id} sx={{ py: 0.5 }}>
                <ListItemText
                  primary={`${formatDate(period.period_start_date)} - ${formatDate(period.period_end_date)}`}
                  secondary={
                    period.status === 'SUBMITTED' 
                      ? `Submitted: ${formatDate(period.submission_date)}` 
                      : `Due: ${formatDate(period.due_date)}`
                  }
                />
                {getStatusChip(period.status, period.due_date)}
                {period.status !== 'SUBMITTED' && (
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    size="small" 
                    sx={{ ml: 1 }}
                    href={`/vat-return/${period.id}`}
                  >
                    Submit
                  </Button>
                )}
                {period.status === 'SUBMITTED' && (
                  <Button 
                    variant="outlined" 
                    color="info" 
                    size="small" 
                    sx={{ ml: 1 }}
                    href={`/vat-return/${period.id}/view`}
                  >
                    View
                  </Button>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      
      {/* If no data at all */}
      {!periods.current_period && 
       (!periods.upcoming_periods || periods.upcoming_periods.length === 0) && 
       (!periods.past_periods || periods.past_periods.length === 0) && (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="body1" color="text.secondary">
            No VAT return periods found.
          </Typography>
          <Button 
            variant="contained" 
            color="primary" 
            sx={{ mt: 2 }}
            onClick={async () => {
              try {
                setLoading(true);
                // Add timestamp for cache busting
                const timestamp = new Date().getTime();
                await api.post(`/vat/periods/generate?t=${timestamp}`, {});
                const newTimestamp = new Date().getTime();
                const response = await api.get(`/vat/periods?t=${newTimestamp}`);
                if (response.data.success) {
                  setPeriods(response.data.data);
                }
              } catch (err) {
                console.error('Error generating VAT periods:', err);
                setError('Failed to generate VAT periods');
              } finally {
                setLoading(false);
              }
            }}
          >
            Generate VAT Periods
          </Button>
        </Box>
      )}
    </Card>
  );
};

export default VatReturnPeriods;