import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';

const KPIBox = ({ title, value, growth, prefix = '', isWholeNumber = false }) => {
  // Format title (preserving your existing logic)
  const formattedTitle = title.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
  
  // Format value (preserving your existing logic)
  let formattedValue = 'N/A';
  
  if (value !== null && value !== undefined) {
    if (isWholeNumber) {
      formattedValue = Math.round(value).toLocaleString('en-GB');
    } else if (typeof value === 'number') {
      formattedValue = value.toLocaleString('en-GB', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    }
  }

  // Determine status color based on growth
  const getStatusColor = () => {
    if (growth > 0) return '#4caf50'; // Green for positive growth
    if (growth < 0) return '#f44336'; // Red for negative growth
    return '#ff9800'; // Orange for no change
  };

  return (
    <Paper
      sx={{
        p: 2,
        borderRadius: 2,
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-3px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          width: 5,
          backgroundColor: growth !== undefined && growth !== null ? getStatusColor() : '#9e9e9e', // Default grey if no growth data
        }
      }}
    >
      <Typography color="textSecondary" variant="body2" gutterBottom>
        {formattedTitle}
      </Typography>
      <Typography 
        variant="h4" 
        sx={{ 
          my: 1, 
          fontWeight: 'medium',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis' 
        }}
      >
        {value !== null && value !== undefined ? `${prefix}${formattedValue}` : formattedValue}
      </Typography>
      {growth !== undefined && growth !== null && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {growth !== 0 && (
            growth > 0 
              ? <ArrowUpward sx={{ fontSize: 16, color: '#4caf50', mr: 0.5 }} /> 
              : <ArrowDownward sx={{ fontSize: 16, color: '#f44336', mr: 0.5 }} />
          )}
          <Typography 
            variant="body2" 
            color={getStatusColor()}
            sx={{ fontWeight: 'medium' }}
          >
            {Math.abs(growth).toFixed(2)}%
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default KPIBox;