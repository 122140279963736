import React, { useState, useEffect } from 'react';
import api from './axiosConfig';
import { 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Tabs,
  Tab,
  Box,
  Divider,
  Chip,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

const PayrollCalculator = () => {
  const [payrollData, setPayrollData] = useState({
    employees: [],
    rates: {
      current: {
        over21: 11.44,
        age18to20: 8.60,
        under18: 6.40,
        apprentice: 6.40,
        enic: 13.8,
        enic_threshold: 9100,
        employment_allowance: 5000
      },
      increased: {
        over21: 12.21,
        age18to20: 10.00,
        under18: 7.55,
        apprentice: 7.55,
        enic: 15.0,
        enic_threshold: 9100,
        employment_allowance: 5000
      }
    },
    year: 2024
  });
  
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({});
  
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Fetch data from backend
  const fetchData = async () => {
    setLoading(true);
    try {
      console.log("Fetching payroll calculator data");
      
      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      
      // Make API request with cache-busting parameter
      const response = await api.get(`/payroll/calculator-data?t=${timestamp}`);
      
      console.log("Payroll API response:", response.data);
      
      // Extract debug info if present
      if (response.data && response.data.debug) {
        console.log("Debug info from API:", response.data.debug);
        setDebugInfo(response.data.debug);
      }
      
      // Log the first employee to check account ID
      if (response.data && response.data.employees && response.data.employees.length > 0) {
        console.log("First employee:", response.data.employees[0]);
      }
      
      // Check if we need to enhance the API response with additional calculations
      if (response.data && response.data.employees) {
        const employeesWithCalculations = calculateEmployeeData(response.data.employees, response.data.rates);
        
        // Update state with response data and calculated values
        setPayrollData({
          ...response.data,
          employees: employeesWithCalculations
        });
      } else {
        // If no employees data, just update the state with response data
        setPayrollData(response.data);
      }
    } catch (error) {
      console.error('Error fetching payroll data:', error);
      setError('Failed to fetch payroll data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Get age of employee on a specific date
  const getAgeOnDate = (dateOfBirth, targetDate) => {
    if (!dateOfBirth) return null;
    
    // Parse dates
    const dob = new Date(dateOfBirth);
    const date = new Date(targetDate);
    
    // Calculate age
    let age = date.getFullYear() - dob.getFullYear();
    
    // Adjust age if birthday hasn't occurred yet in the target year
    const hasBirthdayOccurred = (
      date.getMonth() > dob.getMonth() || 
      (date.getMonth() === dob.getMonth() && date.getDate() >= dob.getDate())
    );
    
    if (!hasBirthdayOccurred) {
      age--;
    }
    
    return age;
  };

  // Determine NMW rate category based on age and apprentice status
  const getRateCategory = (age, isApprentice) => {
    if (isApprentice) return 'apprentice';
    if (age >= 21) return 'over21';
    if (age >= 18) return '18to20';
    return 'under18';
  };

  // Determine if an employee is on NMW or has a custom rate
  const isOnNMW = (hourlyRate, rates) => {
    const nmwRates = [
      rates.current.over21,
      rates.current.age18to20,
      rates.current.under18,
      rates.current.apprentice
    ];
    
    // Check if hourly rate matches any NMW rate (with small margin for floating point comparison)
    return nmwRates.some(rate => Math.abs(hourlyRate - rate) < 0.01);
  };
  
  // Calculate all employee data including current and future costs
  const calculateEmployeeData = (employees, rates) => {
    const currentDate = new Date(); // For current calculations
    const futureDate = new Date(currentDate.getFullYear() + 1, 3, 1); // April 1 of next year
    
    return employees.map(emp => {
      // Handle null hourly rate
      const hourlyRate = emp.hourlyRate || 0;
      
      // Current payroll calculation
      const currentPayroll = hourlyRate * emp.hours;
      
      // Determine if employee is on NMW or custom rate
      const onNMW = isOnNMW(hourlyRate, rates);
      
      // Get current and future ages
      const currentAge = emp.dateOfBirth ? getAgeOnDate(emp.dateOfBirth, currentDate) : null;
      const futureAge = emp.dateOfBirth ? getAgeOnDate(emp.dateOfBirth, futureDate) : null;
      
      console.log(`Employee ${emp.name}: Current age: ${currentAge}, Future age: ${futureAge}, On NMW: ${onNMW}`);
      
      // Determine future rate
      let futureRate = hourlyRate; // Default to keeping current rate
      
      if (onNMW || currentAge) {
        // For employees on NMW or with age data, calculate the appropriate future rate
        const currentCategory = currentAge ? 
          getRateCategory(currentAge, emp.isApprentice) : 
          Object.entries(rates.current).find(([_, rate]) => Math.abs(rate - hourlyRate) < 0.01)?.[0];
          
        const futureCategory = futureAge ? 
          getRateCategory(futureAge, emp.isApprentice) : 
          currentCategory;
        
        // Apply future rate based on category
        if (futureCategory) {
          futureRate = rates.increased[futureCategory] || hourlyRate;
        } else if (Math.abs(hourlyRate - rates.current.over21) < 0.01) {
          futureRate = rates.increased.over21;
        } else if (Math.abs(hourlyRate - rates.current.age18to20) < 0.01) {
          futureRate = rates.increased.age18to20;
        } else if (Math.abs(hourlyRate - rates.current.under18) < 0.01) {
          futureRate = rates.increased.under18;
        } else if (Math.abs(hourlyRate - rates.current.apprentice) < 0.01) {
          futureRate = rates.increased.apprentice;
        }
      }
      
      console.log(`Employee ${emp.name}: Current rate: ${hourlyRate}, Future rate: ${futureRate}`);
      
      // Future payroll calculation
      const futurePayroll = futureRate * emp.hours;
      
      // Calculate ENIC - using threshold
      const weeklyThreshold = rates.current.enic_threshold / 52;
      const weeklyPayroll = currentPayroll;
      let enicPayable = 0;
      
      if (weeklyPayroll > weeklyThreshold) {
        enicPayable = (weeklyPayroll - weeklyThreshold) * (rates.current.enic / 100);
      }
      
      // Calculate employee pension (assuming 3% contribution)
      const employerPension = currentPayroll * 0.03;
      
      // Calculate holiday pay (12.07% of wages)
      const holidayPay = currentPayroll * 0.1207;
      
      // Calculate total cost and cost per hour
      const totalWeeklyCost = currentPayroll + enicPayable + employerPension + holidayPay;
      const costPerHour = totalWeeklyCost / emp.hours;
      
      // Future cost calculations
      const futureWeeklyThreshold = rates.increased.enic_threshold / 52;
      let futureEnicPayable = 0;
      
      if (futurePayroll > futureWeeklyThreshold) {
        futureEnicPayable = (futurePayroll - futureWeeklyThreshold) * (rates.increased.enic / 100);
      }
      
      const futureEmployerPension = futurePayroll * 0.03;
      const futureHolidayPay = futurePayroll * 0.1207;
      const futureTotalWeeklyCost = futurePayroll + futureEnicPayable + futureEmployerPension + futureHolidayPay;
      const futureCostPerHour = futureTotalWeeklyCost / emp.hours;
      
      return {
        ...emp,
        currentAge,
        futureAge,
        onNMW,
        currentPayroll: currentPayroll.toFixed(2),
        futureRate: futureRate,
        futurePayroll: futurePayroll.toFixed(2),
        enicPayable: enicPayable.toFixed(2),
        employerPension: employerPension.toFixed(2),
        holidayPay: holidayPay.toFixed(2),
        totalWeeklyCost: totalWeeklyCost.toFixed(2),
        costPerHour: costPerHour.toFixed(2),
        futureEnicPayable: futureEnicPayable.toFixed(2),
        futureEmployerPension: futureEmployerPension.toFixed(2),
        futureHolidayPay: futureHolidayPay.toFixed(2),
        futureTotalWeeklyCost: futureTotalWeeklyCost.toFixed(2),
        futureCostPerHour: futureCostPerHour.toFixed(2)
      };
    });
  };
  
  // Initial fetch on mount
  useEffect(() => {
    console.log("PayrollCalculator component mounted");
    fetchData();
    
    // Cleanup on unmount
    return () => {
      console.log("PayrollCalculator component unmounting");
      // Clear state on unmount to prevent showing stale data
      setPayrollData({
        employees: [],
        rates: {
          current: {
            over21: 11.44,
            age18to20: 8.60,
            under18: 6.40,
            apprentice: 6.40,
            enic: 13.8,
            enic_threshold: 9100,
            employment_allowance: 5000
          },
          increased: {
            over21: 12.21,
            age18to20: 10.00,
            under18: 7.55,
            apprentice: 7.55,
            enic: 15.0,
            enic_threshold: 9100,
            employment_allowance: 5000
          }
        },
        year: 2024
      });
      setDebugInfo({});
    };
  }, []);

  // Calculate the totals for current rates
  const calculateCurrentTotals = () => {
  return payrollData.employees.reduce((acc, emp) => {
    return {
      totalEmployees: acc.totalEmployees + Number(emp.count || 1),
      totalPayroll: acc.totalPayroll + Number(emp.currentPayroll || 0),
      totalEnic: acc.totalEnic + Number(emp.enicPayable || 0),
      totalPension: acc.totalPension + Number(emp.employerPension || 0),
      totalHoliday: acc.totalHoliday + Number(emp.holidayPay || 0),
      totalCost: acc.totalCost + Number(emp.totalWeeklyCost || 0)
    };
  }, { 
    totalEmployees: 0, 
    totalPayroll: 0,
    totalEnic: 0,
    totalPension: 0,
    totalHoliday: 0,
    totalCost: 0
  });
};

// Calculate the totals for future rates
const calculateFutureTotals = () => {
  return payrollData.employees.reduce((acc, emp) => {
    return {
      totalEmployees: acc.totalEmployees + Number(emp.count || 1),
      totalPayroll: acc.totalPayroll + Number(emp.futurePayroll || 0),
      totalEnic: acc.totalEnic + Number(emp.futureEnicPayable || 0),
      totalPension: acc.totalPension + Number(emp.futureEmployerPension || 0),
      totalHoliday: acc.totalHoliday + Number(emp.futureHolidayPay || 0),
      totalCost: acc.totalCost + Number(emp.futureTotalWeeklyCost || 0)
    };
  }, { 
    totalEmployees: 0, 
    totalPayroll: 0,
    totalEnic: 0,
    totalPension: 0,
    totalHoliday: 0,
    totalCost: 0
  });
};

// Calculate the totals once and store them
const currentTotals = calculateCurrentTotals();
const futureTotals = calculateFutureTotals();

  // Calculate annual difference between current and future costs
  const calculateAnnualDifference = () => {
    return (futureTotals.totalCost - currentTotals.totalCost) * 52;
  };

  // Calculate effective NIC percentage
  const calculateEffectiveNIC = () => {
    if (currentTotals.totalPayroll === 0) return 0;
    return (currentTotals.totalEnic / currentTotals.totalPayroll) * 100;
  };
  
  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Paper>
    );
  }
  
  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
        <Button 
          variant="contained" 
          startIcon={<RefreshIcon />} 
          onClick={fetchData}
          sx={{ mt: 2 }}
        >
          Retry
        </Button>
      </Paper>
    );
  }
  
  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4" component="h1">
          Payroll Calculator {payrollData.year}
        </Typography>
        <Button 
          variant="outlined" 
          startIcon={<RefreshIcon />} 
          onClick={fetchData}
        >
          Refresh Data
        </Button>
      </Box>
      
      {/* Debug information */}
      <Box sx={{ mb: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
        <Typography variant="caption" color="text.secondary">
          Debug: API account_id: {debugInfo.account_id || 'Unknown'} | 
          User ID: {debugInfo.user_id || 'Unknown'} | 
          Timestamp: {debugInfo.timestamp || 'Unknown'}
        </Typography>
      </Box>
      
      <Card sx={{ mb: 3 }}>
        <CardHeader title="National Minimum Wage Rates" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>April 2024 (Current Rate)</Typography>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'primary.light', textAlign: 'center' }}>
                    <Typography variant="caption">Over 21</Typography>
                    <Typography variant="body1" fontWeight="bold">£{payrollData.rates.current.over21}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'secondary.light', textAlign: 'center' }}>
                    <Typography variant="caption">18-20</Typography>
                    <Typography variant="body1" fontWeight="bold">£{payrollData.rates.current.age18to20}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'success.light', textAlign: 'center' }}>
                    <Typography variant="caption">Under 18</Typography>
                    <Typography variant="body1" fontWeight="bold">£{payrollData.rates.current.under18}</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'warning.light', textAlign: 'center' }}>
                    <Typography variant="caption">Apprentices</Typography>
                    <Typography variant="body1" fontWeight="bold">£{payrollData.rates.current.apprentice}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>April 2025 (Increased Rate)</Typography>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'primary.main', textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ color: 'white' }}>Over 21</Typography>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: 'white' }}>
                      £{payrollData.rates.increased.over21}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'secondary.main', textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ color: 'white' }}>18-20</Typography>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: 'white' }}>
                      £{payrollData.rates.increased.age18to20}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'success.main', textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ color: 'white' }}>Under 18</Typography>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: 'white' }}>
                      £{payrollData.rates.increased.under18}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={3}>
                  <Paper elevation={0} sx={{ p: 1, bgcolor: 'warning.main', textAlign: 'center' }}>
                    <Typography variant="caption" sx={{ color: 'white' }}>Apprentices</Typography>
                    <Typography variant="body1" fontWeight="bold" sx={{ color: 'white' }}>
                      £{payrollData.rates.increased.apprentice}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} lg={8}>
          <Card>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={handleTabChange}>
                <Tab label="Current Rates (2024)" />
                <Tab label="Increased Rates (2025)" />
              </Tabs>
            </Box>
            <Box>
             {activeTab === 0 && (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow sx={{ bgcolor: 'grey.100' }}>
          <TableCell>Employee</TableCell>
          <TableCell>Level</TableCell>
          <TableCell align="center">Age</TableCell>
          <TableCell align="center">Hours Worked</TableCell>
          <TableCell align="center">Hourly Rate</TableCell>
          <TableCell align="center">Current Payroll</TableCell>
          <TableCell align="center">Total Annual</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {payrollData.employees.map((emp) => (
          <TableRow key={emp.id}>
            <TableCell>{emp.name || 'Unnamed'}</TableCell>
            <TableCell sx={{ fontWeight: 'medium' }}>{emp.level}</TableCell>
            <TableCell align="center">{emp.currentAge || 'N/A'}</TableCell>
            <TableCell align="center">{emp.hours}</TableCell>
            <TableCell align="center">£{Number(emp.hourlyRate || 0).toFixed(2)}</TableCell>
            <TableCell align="center">£{Number(emp.currentPayroll || 0).toFixed(2)}</TableCell>
            <TableCell align="center">£{(Number(emp.currentPayroll || 0) * 52).toFixed(2)}</TableCell>
          </TableRow>
        ))}
        <TableRow sx={{ bgcolor: 'grey.100' }}>
          <TableCell sx={{ fontWeight: 'bold' }} colSpan={2}>Totals</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell align="center" sx={{ fontWeight: 'bold' }}>£{currentTotals.totalPayroll.toFixed(2)}</TableCell>
          <TableCell align="center" sx={{ fontWeight: 'bold' }}>£{(currentTotals.totalPayroll * 52).toFixed(2)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
)}
              {activeTab === 1 && (
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow sx={{ bgcolor: 'grey.100' }}>
                        <TableCell>Employee</TableCell>
                        <TableCell>Level</TableCell>
                        <TableCell align="center">Future Age</TableCell>
                        <TableCell align="center">Hours Worked</TableCell>
                        <TableCell align="center">New Hourly Rate</TableCell>
                        <TableCell align="center">Future Payroll</TableCell>
                        <TableCell align="center">Total Annual</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payrollData.employees.map((emp) => {
                        const rateChanged = emp.futureRate > (emp.hourlyRate || 0);
                        return (
                          <TableRow key={emp.id}>
                            <TableCell>{emp.name || 'Unnamed'}</TableCell>
                            <TableCell sx={{ fontWeight: 'medium' }}>{emp.level}</TableCell>
                            <TableCell align="center">{emp.futureAge || 'N/A'}</TableCell>
                            <TableCell align="center">{emp.hours}</TableCell>
                            <TableCell 
                              align="center"
                              sx={{
                                fontWeight: rateChanged ? 'bold' : 'normal',
                                backgroundColor: rateChanged ? 'rgba(255, 193, 7, 0.1)' : 'inherit'
                              }}
                            >
                              £{Number(emp.futureRate || 0).toFixed(2)}
                              {rateChanged && <Chip 
                                size="small" 
                                label={`+${(emp.futureRate - (emp.hourlyRate || 0)).toFixed(2)}`} 
                                color="warning" 
                                sx={{ ml: 1, height: 20, fontSize: '0.7rem' }} 
                              />}
                            </TableCell>
                            <TableCell align="center">£{Number(emp.futurePayroll || 0).toFixed(2)}</TableCell>
                            <TableCell align="center">£{(Number(emp.futurePayroll || 0) * 52).toFixed(2)}</TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow sx={{ bgcolor: 'grey.100' }}>
                        <TableCell sx={{ fontWeight: 'bold' }} colSpan={2}>Totals</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>£{futureTotals.totalPayroll.toFixed(2)}</TableCell>
                        <TableCell align="center" sx={{ fontWeight: 'bold' }}>£{(futureTotals.totalPayroll * 52).toFixed(2)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>Summary</Typography>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">Total Weekly Payroll</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  £{activeTab === 0 ? currentTotals.totalPayroll.toFixed(2) : futureTotals.totalPayroll.toFixed(2)}
                </Typography>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">Total Annual Payroll</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                  £{activeTab === 0 ? (currentTotals.totalPayroll * 52).toFixed(2) : (futureTotals.totalPayroll * 52).toFixed(2)}
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">Annual Payroll Difference</Typography>
                <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'error.main' }}>
                  £{calculateAnnualDifference().toFixed(2)}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Increase from current to future rates
                </Typography>
              </Box>
              <Divider sx={{ my: 2 }} />
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="text.secondary">Effective NIC %</Typography>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{calculateEffectiveNIC().toFixed(2)}%</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Card>
         <CardHeader title="Employee Cost Breakdown" />
  <CardContent>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow sx={{ bgcolor: 'grey.100' }}>
            <TableCell>Employee</TableCell>
            <TableCell>Level</TableCell>
            <TableCell align="center">ENIC Payable</TableCell>
            <TableCell align="center">Emp Pension</TableCell>
            <TableCell align="center">Holiday Pay</TableCell>
            <TableCell align="center">Total Cost</TableCell>
            <TableCell align="center">Per Hour</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payrollData.employees.map((emp) => (
            <TableRow key={emp.id}>
              <TableCell>{emp.name || 'Unnamed'}</TableCell>
              <TableCell sx={{ fontWeight: 'medium' }}>{emp.level}</TableCell>
              <TableCell align="center">
                £{activeTab === 0 ? emp.enicPayable : emp.futureEnicPayable}
              </TableCell>
              <TableCell align="center">
                £{activeTab === 0 ? emp.employerPension : emp.futureEmployerPension}
              </TableCell>
              <TableCell align="center">
                £{activeTab === 0 ? emp.holidayPay : emp.futureHolidayPay}
              </TableCell>
              <TableCell align="center">
                £{activeTab === 0 ? emp.totalWeeklyCost : emp.futureTotalWeeklyCost}
              </TableCell>
              <TableCell align="center">
                £{activeTab === 0 ? emp.costPerHour : emp.futureCostPerHour}
              </TableCell>
            </TableRow>
          ))}

          <TableRow sx={{ bgcolor: 'grey.100' }}>
            <TableCell sx={{ fontWeight: 'bold' }} colSpan={2}>
              Totals
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              £{activeTab === 0
                ? currentTotals.totalEnic.toFixed(2)
                : futureTotals.totalEnic.toFixed(2)}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              £{activeTab === 0
                ? currentTotals.totalPension.toFixed(2)
                : futureTotals.totalPension.toFixed(2)}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              £{activeTab === 0
                ? currentTotals.totalHoliday.toFixed(2)
                : futureTotals.totalHoliday.toFixed(2)}
            </TableCell>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              £{activeTab === 0
                ? currentTotals.totalCost.toFixed(2)
                : futureTotals.totalCost.toFixed(2)}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </CardContent>
</Card>
  </Box>
);
};

export default PayrollCalculator;