import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Chip,
  CircularProgress,
  Alert
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import api from './axiosConfig';

const VAT_STATUS_COLORS = {
  'PENDING': 'warning',
  'PARTIAL': 'info',
  'PAID': 'success'
};

const VatLiabilities = () => {
  const [liabilities, setLiabilities] = useState([]);
  const [totals, setTotals] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
  const [selectedLiability, setSelectedLiability] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(new Date());
  const [debugInfo, setDebugInfo] = useState({});
  
  const fetchVatLiabilities = async () => {
    setLoading(true);
    try {
      console.log("Fetching VAT liabilities");
      
      const today = new Date();
      const startDate = new Date(today.getFullYear() - 1, 0, 1);
      const endDate = today;
      
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');
      
      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      
      // Only include date parameters, no account_id switching
      const params = {
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        t: timestamp  // Add cache-busting parameter
      };
      
      console.log("API request params:", params);
      const response = await api.get(`/vat/summary-report`, { params });
      
      console.log("API response:", response.data);
      
      if (response.data && response.data.report && response.data.report.periods) {
        console.log(`Received ${response.data.report.periods.length} VAT liability records`);
        
        if (response.data.report.debug) {
          console.log("Debug info from API:", response.data.report.debug);
          setDebugInfo(response.data.report.debug);
        }
        
        // Log the first record to check account ID
        if (response.data.report.periods.length > 0) {
          console.log("First record account_id:", response.data.report.periods[0].account_id);
        }
        
        setLiabilities(response.data.report.periods);
        setTotals(response.data.report.totals || {});
      } else {
        console.warn("Unexpected response structure:", response.data);
        setError('Unexpected data format received from server');
      }
    } catch (err) {
      console.error('Error fetching VAT liabilities:', err);
      setError('Failed to fetch VAT liabilities. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  // Initial fetch on mount
  useEffect(() => {
    console.log("VatLiabilities component mounted");
    fetchVatLiabilities();
    
    // Cleanup on unmount
    return () => {
      console.log("VatLiabilities component unmounting");
      // Clear state on unmount to prevent showing stale data when component remounts
      setLiabilities([]);
      setTotals({});
      setDebugInfo({});
    };
  }, []); // Empty dependency array - only run on mount
  
  const handlePaymentClick = (liability) => {
    console.log("Payment clicked for liability:", liability);
    setSelectedLiability(liability);
    setPaymentAmount(liability.outstanding.toString());
    setOpenPaymentDialog(true);
  };
  
  const handlePaymentSubmit = async () => {
    try {
      console.log("Submitting payment:", {
        liability_id: selectedLiability.id,
        payment_date: format(paymentDate, 'yyyy-MM-dd'),
        payment_amount: parseFloat(paymentAmount)
      });
      
      // Add timestamp for cache busting
      const timestamp = new Date().getTime();
      
      await api.post(`/vat/liabilities/${selectedLiability.id}/payment?t=${timestamp}`, {
        payment_date: format(paymentDate, 'yyyy-MM-dd'),
        payment_amount: parseFloat(paymentAmount)
      });
      
      setOpenPaymentDialog(false);
      fetchVatLiabilities(); // Refresh data
    } catch (err) {
      console.error('Error recording payment:', err);
      setError('Failed to record payment. Please try again.');
    }
  };
  
  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Paper>
    );
  }
  
  if (error) {
    return (
      <Paper elevation={3} sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Paper>
    );
  }
  
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>VAT Liabilities</Typography>
      
      {/* Debug information */}
      <Box sx={{ mb: 2, p: 1, bgcolor: '#f5f5f5', borderRadius: 1 }}>
        <Typography variant="caption" color="text.secondary">
          Debug: API account_id: {debugInfo.account_id || 'Unknown'} | 
          User ID: {debugInfo.user_id || 'Unknown'}
        </Typography>
      </Box>
      
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell align="right">Output VAT</TableCell>
              <TableCell align="right">Input VAT</TableCell>
              <TableCell align="right">Net Liability</TableCell>
              <TableCell align="right">Paid</TableCell>
              <TableCell align="right">Outstanding</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {liabilities.length > 0 ? (
              liabilities.map((liability) => (
                <TableRow key={liability.id}>
                  <TableCell>
                    {format(new Date(liability.period_start), 'dd/MM/yyyy')} - {format(new Date(liability.period_end), 'dd/MM/yyyy')}
                  </TableCell>
                  <TableCell align="right">£{liability.output_vat.toFixed(2)}</TableCell>
                  <TableCell align="right">£{liability.input_vat.toFixed(2)}</TableCell>
                  <TableCell align="right">£{liability.net_liability.toFixed(2)}</TableCell>
                  <TableCell align="right">£{(liability.payment_amount || 0).toFixed(2)}</TableCell>
                  <TableCell align="right">£{liability.outstanding.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <Chip 
                      label={liability.status} 
                      color={VAT_STATUS_COLORS[liability.status] || 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell align="center">
                    {liability.outstanding > 0 && (
                      <Button 
                        size="small" 
                        variant="outlined"
                        onClick={() => handlePaymentClick(liability)}
                      >
                        Record Payment
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">No VAT liabilities found</TableCell>
              </TableRow>
            )}
            
            {/* Totals row */}
            {Object.keys(totals).length > 0 && (
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                <TableCell><strong>Totals</strong></TableCell>
                <TableCell align="right"><strong>£{totals.output_vat.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>£{totals.input_vat.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>£{totals.net_liability.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>£{totals.paid.toFixed(2)}</strong></TableCell>
                <TableCell align="right"><strong>£{totals.outstanding.toFixed(2)}</strong></TableCell>
                <TableCell colSpan={2}></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Payment Dialog */}
      <Dialog open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)}>
        <DialogTitle>Record VAT Payment</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2 }}>
            <Typography variant="body2" gutterBottom>
              Period: {selectedLiability && `${format(new Date(selectedLiability.period_start), 'dd/MM/yyyy')} - ${format(new Date(selectedLiability.period_end), 'dd/MM/yyyy')}`}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Outstanding Amount: £{selectedLiability?.outstanding.toFixed(2)}
            </Typography>
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Payment Date"
                value={paymentDate}
                onChange={(newValue) => setPaymentDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </LocalizationProvider>
            
            <TextField
              label="Payment Amount"
              type="number"
              value={paymentAmount}
              onChange={(e) => setPaymentAmount(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <span>£</span>,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPaymentDialog(false)}>Cancel</Button>
          <Button onClick={handlePaymentSubmit} variant="contained" color="primary">
            Record Payment
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default VatLiabilities;