import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
  Button,
  Card,
  CardContent,
  Alert,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  FormHelperText,
  Chip,
  CircularProgress,
  LinearProgress,
  Divider
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';

const ExpenseManagement = ({ 
  businessSettings, 
  onUpdate, 
  calculatorApi 
}) => {
  // Define expense categories
  const [expenseCategories] = useState([
    'Accountancy & Legal',
    'Bank Charges & Interest',
    'Card Machine Fees',
    'Client Comfort',
    'Communications',
    'Furniture & Equipment',
    'Insurance',
    'IT & Software',
    'Loan Repayment',
    'Maintenance & Repairs',
    'Marketing & Advertising',
    'Office Supplies',
    'Payroll',
    'Personal',
    'Rates',
    'Rent',
    'Stock',
    'Subscriptions',
    'Sundry',
    'Taxation',
    'Training',
    'Utilities',
    'Waste Disposal'
  ]);

  // Group categories by type for better organization
  const categoryGroups = {
    premises: ['Rent', 'Rates', 'Utilities', 'Waste Disposal', 'Maintenance & Repairs'],
    operational: ['Insurance', 'Communications', 'IT & Software', 'Office Supplies', 'Client Comfort', 'Stock'],
    financial: ['Accountancy & Legal', 'Bank Charges & Interest', 'Card Machine Fees', 'Loan Repayment', 'Taxation', 'Payroll'],
    marketing: ['Marketing & Advertising', 'Subscriptions'],
    other: ['Furniture & Equipment', 'Training', 'Sundry', 'Personal']
  };

  // Component state
  const [expenseSummary, setExpenseSummary] = useState({});
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState('monthly');
  const [period, setPeriod] = useState('current');
  const [utilization, setUtilization] = useState(businessSettings?.utilization_rate || 85);
  const [apiCallsMade, setApiCallsMade] = useState(false);

  // Real transaction totals from the database
  const [expenseTotals, setExpenseTotals] = useState({
    totalAmount: 0,
    categoryBreakdown: {}
  });

  // Overhead calculations
  const [overhead, setOverhead] = useState({
    hourly: 0,
    perMinute: 0,
    monthly: 0,
    annual: 0
  });

  // Update utilization when businessSettings changes
  useEffect(() => {
    if (businessSettings?.utilization_rate) {
      setUtilization(businessSettings.utilization_rate);
    }
  }, [businessSettings]);

  // Load expense summary data when period changes
  useEffect(() => {
    const loadExpenseData = async () => {
      if (!calculatorApi) {
        console.error("Calculator API not available");
        setLoading(false);
        return;
      }
      
      try {
        setLoading(true);
        console.log(`Fetching expense data for period: ${period}`);
        
        // Fetch expense summary for the selected period
        const response = await calculatorApi.getExpenseSummary(period);
        console.log('Expense summary response:', response);
        
        const data = response?.data || {};
        
        // Initialize category breakdown if not present
        const categoryBreakdown = data.categoryBreakdown || {};
        expenseCategories.forEach(category => {
          if (!categoryBreakdown[category]) {
            categoryBreakdown[category] = 0;
          }
        });
        
        setExpenseTotals({
          totalAmount: data.totalAmount || 0,
          categoryBreakdown,
          period: data.period || period
        });
        
        setLoading(false);
      } catch (err) {
        console.error('Failed to load expense data:', err);
        // Set default values on error
        setExpenseTotals({
          totalAmount: 0,
          categoryBreakdown: {},
          period
        });
        setLoading(false);
      }
    };
    
    loadExpenseData();
  }, [period, calculatorApi, expenseCategories]); // Reload when period changes

  // Load expense configuration once on component mount
  useEffect(() => {
    const loadExpenseConfig = async () => {
      if (apiCallsMade || !calculatorApi) return; // Skip if already loaded or API not available
      
      try {
        console.log('Fetching expense summary config (once)');
        
        // Fetch expense summary config only once
        const configResponse = await calculatorApi.getExpenseSummaryConfig();
        console.log('Expense config response:', configResponse);
        
        if (configResponse?.data) {
          setExpenseSummary(configResponse.data);
        }
        
        setApiCallsMade(true);
      } catch (err) {
        console.error('Failed to load expense summary config:', err);
        setApiCallsMade(true);
      }
    };
    
    loadExpenseConfig();
  }, [calculatorApi, apiCallsMade]); // Empty dependency array means this runs once

  // Calculate overhead costs whenever expense data changes
  useEffect(() => {
    // Get the trading hours and apply utilization rate
    const weeklyHours = businessSettings?.total_trading_hours || 40;
    const utilizationRate = utilization / 100;
    const effectiveWeeklyHours = weeklyHours * utilizationRate;
    const weeksPerYear = 52;
    const annualHours = effectiveWeeklyHours * weeksPerYear;
    const monthlyHours = annualHours / 12;
    
    // Determine the appropriate multiplier based on the period
    let multiplier = 12; // Default for 'current' or 'previous' (monthly)
    if (expenseTotals.period === 'last12months') {
      multiplier = 1; // Already annual
    } else if (expenseTotals.period === 'last6months') {
      multiplier = 2; // Multiply by 2 to get annual
    } else if (expenseTotals.period === 'last3months') {
      multiplier = 4; // Multiply by 4 to get annual
    }
    
    // Total annual expenses from real data
    const annualExpenses = expenseTotals.totalAmount * multiplier;
    
    // Calculate hourly overhead
    const hourlyRate = annualHours > 0 ? annualExpenses / annualHours : 0;
    const minuteRate = hourlyRate / 60;
    
    setOverhead({
      hourly: hourlyRate,
      perMinute: minuteRate,
      monthly: hourlyRate * monthlyHours,
      annual: annualExpenses
    });
    
    // Note: We don't update businessSettings here, only when the save button is clicked
  }, [expenseTotals, businessSettings?.total_trading_hours, utilization]);

  // Event handlers
  const handlePeriodChange = (event) => {
    console.log(`Period changed to: ${event.target.value}`);
    setPeriod(event.target.value);
  };

  const handleTimeframeChange = (event) => {
    setTimeframe(event.target.value);
  };

  // Grouping categories together for display
  const getGroupedCategories = () => {
    // Create an array of categories with their amounts
    const categoriesWithAmounts = expenseCategories.map(category => ({
      name: category,
      amount: expenseTotals.categoryBreakdown[category] || 0,
      group: Object.keys(categoryGroups).find(group => 
        categoryGroups[group].includes(category)
      ) || 'other'
    }));
    
    // Group by the group property
    const grouped = {};
    categoriesWithAmounts.forEach(cat => {
      if (!grouped[cat.group]) {
        grouped[cat.group] = [];
      }
      grouped[cat.group].push(cat);
    });
    
    return grouped;
  };

  // Calculate percentage of total for a category or group
  const getPercentage = (amount) => {
    return expenseTotals.totalAmount > 0 
      ? (amount / expenseTotals.totalAmount) * 100 
      : 0;
  };

  // Function to exclude certain categories from service price calculations
  const shouldExcludeFromPricing = (category) => {
    // Categories that shouldn't affect service pricing
    const excludedCategories = [
      'Stock', // Already included in product costs
      'Personal',
      'Payroll' // Staff costs are handled separately
    ];
    
    return excludedCategories.includes(category);
  };

  // Save overhead settings (only when button is clicked)
  const handleSaveOverheadSettings = async () => {
    if (!onUpdate) {
      console.error("onUpdate function not provided");
      return;
    }
    
    try {
      setLoading(true);
      
      // Prepare settings object
      const settings = {
        overhead_hourly: overhead.hourly,
        overhead_per_minute: overhead.perMinute,
        utilization_rate: utilization
      };
      
      // Save to API if available
      if (calculatorApi) {
        await calculatorApi.updateBusinessSettings(settings);
      }
      
      // Update parent component state
      onUpdate(settings);
      
      setLoading(false);
      alert('Overhead settings saved successfully!');
    } catch (err) {
      console.error('Failed to save overhead settings:', err);
      setLoading(false);
      alert('Failed to save overhead settings.');
    }
  };

  // Format currency for display
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', { 
      style: 'currency', 
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };

  // Component UI
  return (
    <Box>
      {/* Expense Summary Cards */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Expense Summary</Typography>
        
        <Box sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="period-select-label">Time Period</InputLabel>
                <Select
                  labelId="period-select-label"
                  id="period-select"
                  value={period}
                  onChange={handlePeriodChange}
                  label="Time Period"
                >
                  <MenuItem value="current">Current Month</MenuItem>
                  <MenuItem value="previous">Previous Month</MenuItem>
                  <MenuItem value="last3months">Last 3 Months</MenuItem>
                  <MenuItem value="last6months">Last 6 Months</MenuItem>
                  <MenuItem value="last12months">Last 12 Months</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item>
              <FormControl sx={{ minWidth: 150 }}>
                <InputLabel id="timeframe-select-label">View As</InputLabel>
                <Select
                  labelId="timeframe-select-label"
                  id="timeframe-select"
                  value={timeframe}
                  onChange={handleTimeframeChange}
                  label="View As"
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="annual">Annual</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item>
              <Button 
                variant="contained" 
                onClick={handleSaveOverheadSettings}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
                Save Overhead Rate
              </Button>
            </Grid>
          </Grid>
        </Box>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Total Expenses</Typography>
                <Typography variant="h4">
                  {timeframe === 'monthly' 
                    ? formatCurrency(expenseTotals.totalAmount / (period === 'last3months' ? 3 : period === 'last6months' ? 6 : period === 'last12months' ? 12 : 1))
                    : formatCurrency(expenseTotals.totalAmount * (period === 'last12months' ? 1 : 12))}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {timeframe === 'monthly' ? 'Per Month' : 'Per Year'}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Overhead Per Hour</Typography>
                <Typography variant="h4">{formatCurrency(overhead.hourly)}</Typography>
                <Typography variant="caption" color="text.secondary">
                  Based on {businessSettings?.total_trading_hours || 40} hours per week
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Overhead Per Minute</Typography>
                <Typography variant="h4">{formatCurrency(overhead.perMinute)}</Typography>
                <Typography variant="caption" color="text.secondary">
                  Applied to service duration
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Categories</Typography>
                <Typography variant="h4">
                  {Object.keys(expenseTotals.categoryBreakdown).filter(k => expenseTotals.categoryBreakdown[k] > 0).length}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  In expense breakdown
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Expense Breakdown */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Expense Breakdown</Typography>
        
        <Alert severity="info" sx={{ mb: 3 }}>
          This breakdown shows your actual expenses by category for the selected period.
          The overhead cost is calculated based on these expenses and your weekly trading hours,
          which affects service pricing.
        </Alert>
        
        <Box sx={{ mb: 4 }}>
          {Object.entries(getGroupedCategories()).map(([groupName, categories]) => {
            if (categories.length === 0) return null;
            const groupTotal = categories.reduce((sum, cat) => sum + cat.amount, 0);
            if (groupTotal <= 0) return null;
            
            const groupPercentage = getPercentage(groupTotal);
            
            return (
              <Box key={groupName} sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                  <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                    {groupName} Expenses
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle2" sx={{ mr: 1 }}>
                      {timeframe === 'monthly' 
                        ? formatCurrency(groupTotal / (period === 'last3months' ? 3 : period === 'last6months' ? 6 : period === 'last12months' ? 12 : 1))
                        : formatCurrency(groupTotal * (period === 'last12months' ? 1 : 12))}
                    </Typography>
                    <Chip 
                      label={`${groupPercentage.toFixed(1)}%`} 
                      size="small"
                      color="primary"
                    />
                  </Box>
                </Box>
                
                <LinearProgress 
                  variant="determinate" 
                  value={groupPercentage} 
                  sx={{ height: 8, borderRadius: 4, mb: 2 }}
                />
                
                <Grid container spacing={2}>
                  {categories.filter(category => category.amount > 0).sort((a, b) => b.amount - a.amount).map(category => (
                    <Grid item xs={12} sm={6} md={4} key={category.name}>
                      <Box 
                        sx={{ 
                          p: 2, 
                          bgcolor: 'background.default',
                          borderRadius: 1,
                          border: '1px solid',
                          borderColor: 'divider'
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" noWrap sx={{ maxWidth: '70%' }}>
                            {category.name}
                          </Typography>
                          <Chip 
                            label={shouldExcludeFromPricing(category.name) ? 'Excluded' : 'Included'} 
                            size="small"
                            color={shouldExcludeFromPricing(category.name) ? 'default' : 'success'}
                            variant="outlined"
                            sx={{ height: 20, '& .MuiChip-label': { px: 1, fontSize: '0.625rem' } }}
                          />
                        </Box>
                        <Typography variant="h6">
                          {timeframe === 'monthly' 
                            ? formatCurrency(category.amount / (period === 'last3months' ? 3 : period === 'last6months' ? 6 : period === 'last12months' ? 12 : 1))
                            : formatCurrency(category.amount * (period === 'last12months' ? 1 : 12))}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <LinearProgress 
                            variant="determinate" 
                            value={getPercentage(category.amount)} 
                            sx={{ height: 4, borderRadius: 2, flexGrow: 1, mr: 1 }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {getPercentage(category.amount).toFixed(1)}%
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            );
          })}
          
          {/* Show message if no expense data */}
          {expenseTotals.totalAmount <= 0 && (
            <Alert severity="info" sx={{ mt: 2 }}>
              No expense data available for the selected period. Add expenses to see your breakdown here.
            </Alert>
          )}
        </Box>
      </Paper>
      
      {/* Settings for Overhead Calculation */}
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>Overhead Calculation Settings</Typography>
        
        <Alert severity="info" sx={{ mb: 3 }}>
          These settings determine how expenses are converted to an overhead rate for service pricing.
          The calculated overhead rate is applied to each service based on its duration.
        </Alert>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Weekly Trading Hours"
                type="number"
                value={businessSettings?.total_trading_hours || 40}
                onChange={(e) => {
                  // Just update local state, parent is updated on save
                  const value = parseInt(e.target.value) || 40;
                  if (onUpdate) {
                    onUpdate({ total_trading_hours: value });
                  }
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">hours</InputAdornment>,
                }}
                helperText="Total working hours per week across all staff"
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="expense-period-label">Expense Calculation Period</InputLabel>
              <Select
                labelId="expense-period-label"
                id="expense-period"
                value={period}
                onChange={handlePeriodChange}
                label="Expense Calculation Period"
              >
                <MenuItem value="current">Current Month</MenuItem>
                <MenuItem value="previous">Previous Month</MenuItem>
                <MenuItem value="last3months">Last 3 Months (Average)</MenuItem>
                <MenuItem value="last6months">Last 6 Months (Average)</MenuItem>
                <MenuItem value="last12months">Last 12 Months (Average)</MenuItem>
              </Select>
              <FormHelperText>Period used to calculate the overhead rate</FormHelperText>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Utilization Rate (%)"
              value={utilization}
              onChange={(e) => setUtilization(Math.min(100, Math.max(1, parseInt(e.target.value) || 85)))}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={utilization < 1 || utilization > 100}
              helperText={
                utilization < 1 || utilization > 100
                  ? "Utilization must be between 1-100%"
                  : "Percentage of available hours that can be booked with clients"
              }
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="subtitle2" gutterBottom>Utilization Impact</Typography>
                <Typography variant="body2">
                  With {utilization}% utilization rate:
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="body2">
                    • Available hours: {businessSettings?.total_trading_hours || 40} per week
                  </Typography>
                  <Typography variant="body2">
                    • Billable hours: {((businessSettings?.total_trading_hours || 40) * utilization / 100).toFixed(1)} per week
                  </Typography>
                  <Typography variant="body2">
                    • Non-billable hours: {((businessSettings?.total_trading_hours || 40) * (1 - utilization / 100)).toFixed(1)} per week
                  </Typography>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Utilization accounts for time spent on non-billable activities like admin, 
                  training, and breaks. A realistic rate improves pricing accuracy.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 2, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>Excluded Categories</Typography>
              <Typography variant="body2" color="text.secondary">
                These categories are excluded from service overhead calculations because they're already factored in elsewhere:
              </Typography>
              <List dense>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Stock" 
                    secondary="Already included in product costs"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Payroll" 
                    secondary="Staff costs are calculated separately by service time"
                  />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckCircleIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText 
                    primary="Personal" 
                    secondary="Not relevant to business overhead"
                  />
                </ListItem>
              </List>
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Box sx={{ mt: 2, p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
              <Typography variant="subtitle2" gutterBottom>Calculation Method</Typography>
              <Typography variant="body2">
                Overhead Per Hour = (Total Annual Expenses - Excluded Categories) ÷ (Weekly Hours × Utilization Rate × 52 weeks)
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Overhead Per Minute = Hourly Rate ÷ 60
              </Typography>
              <Typography variant="body2" sx={{ mt: 1 }}>
                Service Overhead Cost = Overhead Per Minute × Service Duration
              </Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                variant="contained" 
                onClick={handleSaveOverheadSettings}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
                Save Overhead Settings
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default ExpenseManagement
      
      