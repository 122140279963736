import React from 'react';
import { 
  TableContainer, 
  Paper, 
  Typography, 
  Table, 
  TableHead, 
  TableBody, 
  TableRow, 
  TableCell 
} from '@mui/material';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';

const ProductsTab = ({ products, productCategories }) => {
  // Ensure both arrays are valid
  const safeProducts = Array.isArray(products) ? products : [];
  const safeCategories = Array.isArray(productCategories) ? productCategories : [];
  
  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" sx={{ p: 2 }}>Products</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Cost</TableCell>
            <TableCell>Stock</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {safeProducts.length > 0 ? (
            safeProducts.map((product) => {
              if (!product) return null;
              
              // Find the matching category
              const category = safeCategories.find(c => c?.id === product.category_id);
              
              return (
                <TableRow key={product.id}>
                  <TableCell>{product.name || 'Unnamed Product'}</TableCell>
                  <TableCell>{category?.name || 'Uncategorized'}</TableCell>
                  <TableCell>£{product.cost_price?.toFixed(2) || '0.00'}</TableCell>
                  <TableCell>{product.stock_quantity || 0}</TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={4} align="center">
                No products found. Add products to use in service calculations.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductsTab;