import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Container, 
  Box, 
  Button,
  IconButton,
  Drawer,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Badge,
  Menu,
  MenuItem
} from '@mui/material';
import { useAuth } from './AuthContext';
import { io } from 'socket.io-client';
import api from './axiosConfig';
import logo from './Salon Logic Square 2.gif';

// Import all icons
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import NoteIcon from '@mui/icons-material/Note';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EmailIcon from '@mui/icons-material/Email';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CalculateIcon from '@mui/icons-material/Calculate';
import InventoryIcon from '@mui/icons-material/Inventory'

const rolePermissions = {
  ACCOUNT_OWNER: [
    'Dashboards', 'Employees', 'Time Management', 'Suppliers',
    'Income', 'Stock Management', 'Reminders', 'Space Rental', 'Reports',
    'Employment Applications', 'Data Management', 'Messages', 'Service Calculator'
  ],
  ACCOUNT_MANAGER: [
    'Dashboards', 'Employees', 'Time Management', 'Stock Management',
    'Reminders', 'Employment Applications', 'Space Rental', 'Messages'
  ],
  ACCOUNT_USER: [
    'Dashboards', 'Time Management', 'Messages'
  ],
  RENTAL_USER: [
    'Space Rental', 'Messages'
  ]
};

const Layout = () => {
  const { user, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const [unreadCount, setUnreadCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const menuItems = [
    { title: 'Dashboards', path: '/dashboard', icon: <DashboardIcon /> },
    {
      title: 'Employees',
      icon: <PeopleIcon />,
      items: [
        { title: 'Employee Card', path: '/employee_card' },
        { title: 'Submit Employee Review', path: '/submit_employee_review' },
        { title: 'Employee Reviews', path: '/employee_reviews' },
        { title: 'Commission Management', path: '/commission/dashboard' },
      ],
    },
    {
      title: 'Time Management',
      icon: <AccessTimeIcon />,
      items: [
        { title: 'Holiday Calendar', path: '/holiday_calendar' },
        { title: 'Request Holiday', path: '/submit_holiday_request' },
        { title: 'Submit Absence', path: '/submit_absence' },
        { title: 'Holiday Approval', path: '/holiday_approval' },
      ],
    },
    { title: 'Suppliers', path: '/supplier_card', icon: <BusinessIcon /> },
    { title: 'Income', path: '/income_card', icon: <CurrencyPoundIcon /> },
    {
      title: 'Stock Management',
      icon: <InventoryIcon />, 
      items: [
        { title: 'Products', path: '/stock/products' },
        { title: 'Stock Transactions', path: '/stock/transactions' },
        { title: 'Service Products', path: '/stock/service-products/0' },  // Using 0 as a default ID
        { title: 'Purchase Orders', path: '/stock/purchase-orders' },
        { title: 'Service Calculator', path: '/calculator' },
      ],
    },
    {
      title: 'Reports',
      icon: <AssessmentIcon />,
      items: [
        { title: 'Reports Dashboard', path: '/reports/dashboard' },
        { title: 'Transactions Report', path: '/reports/transaction' },
        { title: 'Tax Report', path: '/reports/tax' },
        { title: 'VAT Dashboard', path: '/reports/vat' },
        { title: 'Cashflow Report', path: '/reports/cashflow' },
        { title: 'Profit & Loss', path: '/reports/profit-loss' },
        { title: 'Payroll Calculator', path: '/payroll-calculator' },
      ],
    },
    {
      title: 'Data Management',
      icon: <ManageAccountsIcon />,
      items: [
        { title: 'Import Income', path: '/import/income', icon: <CloudUploadIcon /> },
        { title: 'Import Expenditure', path: '/import/expenditure', icon: <CloudUploadIcon /> },
        { title: 'Import Suppliers', path: '/import/suppliers', icon: <CloudUploadIcon /> },
        { title: 'Import Employees', path: '/import/employees', icon: <CloudUploadIcon /> },
      ],
    },
    { title: 'Reminders', path: '/reminders', icon: <NotificationsIcon /> },
    { title: 'Space Rental', path: '/rental_dashboard', icon: <HomeWorkIcon /> },
    { title: 'Employment Applications', path: '/employment_applications', icon: <WorkIcon /> },
    { title: 'Messages', path: '/messages', icon: <EmailIcon /> },
];

  useEffect(() => {
    // Handle navigation for unauthenticated users
    if (!isAuthenticated || !user) {
        console.log('Not authenticated, redirecting to login');
        navigate('/login');
        return;
    }

    // Handle home route navigation
    if (location.pathname === '/home') {
        switch (user.role) {
            case 'ACCOUNT_MANAGER':
                navigate('/manager_dashboard');
                break;
            case 'ACCOUNT_OWNER':
                navigate('/owner_dashboard');
                break;
            case 'ACCOUNT_USER':
                navigate('/user_dashboard');
                break;
            default:
                navigate('/dashboard');
        }
    }

    // Only attempt to fetch messages and setup WebSocket if authenticated
    if (isAuthenticated && user) {
        const socket = io('/', {
            path: '/socket.io',
            auth: {
                token: localStorage.getItem('access_token')
            }
        });

        socket.on('connect', () => {
            console.log('Connected to WebSocket');
        });

        socket.on('new_message', (message) => {
            setUnreadCount(prev => prev + 1);
            setNotifications(prev => [message, ...prev]);
        });

        socket.on('message_update', (update) => {
            if (update.status === 'READ') {
                setUnreadCount(prev => Math.max(0, prev - 1));
                setNotifications(prev => 
                    prev.map(notif => 
                        notif.id === update.secure_id 
                            ? { ...notif, status: 'READ' } 
                            : notif
                    )
                );
            }
        });

        // Fetch initial unread count
        api.get('/messages/unread-count')
            .then(response => {
                console.log('Unread count response:', response.data);
                setUnreadCount(response.data.count);
            })
            .catch(error => {
                console.error('Error fetching unread count:', error);
            });

        return () => {
            socket.disconnect();
        };
    }
}, [isAuthenticated, user, navigate, location.pathname]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleItemClick = (title) => {
    setExpandedItems(prev => ({
      ...prev,
      [title]: !prev[title]
    }));
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setAnchorEl(null);
  };

  const getFilteredMenuItems = () => {
    const allowedItems = rolePermissions[user?.role] || [];
    return menuItems.filter(item => allowedItems.includes(item.title));
  };

  if (!isAuthenticated || !user) {
    return null;
  }

  const SidebarContent = () => (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%',
      backgroundColor: '#1976d2'
    }}>
      <Box sx={{ textAlign: 'center', p: 2 }}>
        <img 
          src={logo} 
          alt="Salon Logic Logo" 
          style={{ 
            maxWidth: '100%', 
            height: 'auto',
            maxHeight: '100px'
          }} 
        />
      </Box>
      <List sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {getFilteredMenuItems().map((item, index) => (
          <Box key={index}>
            {item.items ? (
              <>
                <ListItem 
                  button 
                  onClick={() => handleItemClick(item.title)}
                  sx={{ color: 'white' }}
                >
                  <ListItemIcon sx={{ color: 'white' }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                  {expandedItems[item.title] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expandedItems[item.title]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.items.map((subItem, subIndex) => (
                      <ListItem 
                        button 
                        component={Link} 
                        to={subItem.path}
                        key={subIndex}
                        onClick={() => isMobile && setMobileOpen(false)}
                        sx={{ pl: 4, color: 'white' }}
                      >
                        {subItem.icon && (
                          <ListItemIcon sx={{ color: 'white', minWidth: '35px' }}>
                            {subItem.icon}
                          </ListItemIcon>
                        )}
                        <ListItemText primary={subItem.title} />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </>
            ) : (
              <ListItem 
                button 
                component={Link} 
                to={item.path}
                onClick={() => isMobile && setMobileOpen(false)}
                sx={{ color: 'white' }}
              >
                <ListItemIcon sx={{ color: 'white' }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={
                  item.title === 'Messages' ? (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {item.title}
                      {unreadCount > 0 && (
                        <Badge 
                          badgeContent={unreadCount} 
                          color="error" 
                          sx={{ ml: 1 }}
                        />
                      )}
                    </Box>
                  ) : item.title
                } />
              </ListItem>
            )}
          </Box>
        ))}
      </List>
      <Button 
        onClick={handleLogout} 
        variant="contained" 
        color="secondary" 
        sx={{ m: 2 }}
      >
        Logout
      </Button>
    </Box>
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static" sx={{ 
        borderRadius: { xs: 0, sm: '12px' }, 
        margin: { xs: 0, sm: '16px 16px 0 16px' },
        backgroundColor: '#1976d2'
      }}>
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Salon Management System
          </Typography>
          
          <IconButton
            color="inherit"
            onClick={handleNotificationClick}
            sx={{ mr: 2 }}
          >
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleNotificationClose}
            PaperProps={{
              sx: {
                maxHeight: '400px',
                width: '300px',
                mt: 1
              }
            }}
          >
            {notifications.length > 0 ? (
              notifications.map((notification, index) => (
                <MenuItem 
                  key={index}
                  onClick={() => {
                    handleNotificationClose();
                    navigate(`/messages/${notification.id}`);
                  }}
                  sx={{
                    borderBottom: '1px solid #eee',
                    whiteSpace: 'normal',
                    py: 1
                  }}
                >
                  <Box>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: notification.status === 'UNREAD' ? 'bold' : 'normal'
                      }}
                    >
                      {notification.title}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ 
                        display: '-webkit-box',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden'
                      }}
                    >
                      {notification.content}
                    </Typography>
                    <Typography 
                      variant="caption" 
                      color="text.secondary" 
                      sx={{ mt: 0.5 }}
                    >
                      {new Date(notification.created_at).toLocaleString()}
                    </Typography>
                  </Box>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No new notifications</MenuItem>
            )}
            {notifications.length > 0 && (
              <MenuItem 
                onClick={() => {
                  handleNotificationClose();
                  navigate('/messages');
                }}
                sx={{ 
                  justifyContent: 'center', 
                  color: 'primary.main',
                  borderTop: '1px solid #eee'
                }}
              >
                View All Messages
              </MenuItem>
            )}
          </Menu>

          {user && (
            <Typography 
              variant="subtitle1" 
              sx={{ display: { xs: 'none', sm: 'block' } }}
            >
              Welcome, {user.email}
            </Typography>
          )}
        </Toolbar>
      </AppBar>

      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden', margin: { xs: 0, sm: '16px' } }}>
        <Drawer
          variant="temporary"
          anchor="left"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
    sx={{
      display: { xs: 'block', sm: 'none' },
      '& .MuiDrawer-paper': { width: 250 },
    }}
  >
    <SidebarContent />
  </Drawer>

  {/* Desktop Sidebar */}
  <Box sx={{ 
    width: 250,
    display: { xs: 'none', sm: 'block' },
    backgroundColor: '#1976d2',
    borderRadius: '12px 0 0 12px',
  }}>
    <SidebarContent />
  </Box>

  {/* Main Content */}
  <Box sx={{ 
    flexGrow: 1,
    backgroundColor: '#e3f2fd',
    borderRadius: { xs: 0, sm: '0 12px 12px 0' },
    overflow: 'auto',
    padding: '16px'
  }}>
    <Outlet />
  </Box>
</Box>

{/* Footer */}
<Box 
  component="footer" 
  sx={{ 
    py: 3, 
    px: 2, 
    backgroundColor: theme => theme.palette.grey[200],
    borderRadius: { xs: 0, sm: '12px' },
    margin: { xs: 0, sm: '0 16px 16px 16px' }
  }}
>
  <Container maxWidth="sm">
    <Typography variant="body2" color="text.secondary" align="center">
      © {new Date().getFullYear()} Salon Management System
    </Typography>
  </Container>
</Box>
</Box>
);
};

export default Layout;