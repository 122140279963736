import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Box,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Import API utilities
import calculatorApi from './calculatorApi';
import stockApi from './stockApi';

// Import components
import OverviewTab from './OverviewTab';
import ServicesTab from './ServicesTab';
import ProductsTab from './ProductsTab';
import StaffTab from './StaffTab';
import ExpenseManagement from './ExpenseManagement';
import SettingsForm from './SettingsForm';
import ImportDialog from './ImportDialog';

// Enhanced array helper
const ensureArray = (data) => {
  // Case 1: Input is already an array
  if (Array.isArray(data)) {
    return data;
  }
  
  // Case 2: Input is an object with a data property that is an array
  if (data && typeof data === 'object' && data.data && Array.isArray(data.data)) {
    return data.data;
  }
  
  // Case 3: Input has success and data properties (security response format)
  if (data && typeof data === 'object' && data.success && data.data && Array.isArray(data.data)) {
    return data.data;
  }
  
  // Case 4: Input is an axios response with nested data
  if (data && typeof data === 'object' && data.data && typeof data.data === 'object') {
    if (data.data.data && Array.isArray(data.data.data)) {
      return data.data.data;
    }
  }
  
  // If we can't find an array, return empty array
  return [];
};

function ServiceCalculator() {
  const navigate = useNavigate();
  
  // Tab state
  const [activeTab, setActiveTab] = useState(0);
  
  // UI state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Data state
  const [businessSettings, setBusinessSettings] = useState({
    vat_rate: 20,
    profit_level_1: 20,
    profit_level_2: 30,
    total_trading_hours: 0,
    overhead_hourly: 0,
    staff_hourly: 0,
    owner_profit: 0
  });

  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [services, setServices] = useState([]);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [serviceProducts, setServiceProducts] = useState([]);
  const [servicesAnalysis, setServicesAnalysis] = useState([]);

  const [staff, setStaff] = useState([]);
  const [revenueStaff, setRevenueStaff] = useState([]);
  const [supportStaff, setSupportStaff] = useState([]);

  const [expenses, setExpenses] = useState([]);
  
  // Import dialog state
  const [isImportDialogOpen, setIsImportDialogOpen] = useState(false);
  const [importFile, setImportFile] = useState(null);
  const [isImporting, setIsImporting] = useState(false);
  const [importResult, setImportResult] = useState(null);

  // Handle tab changes
  const handleTabChange = (event, newValue) => setActiveTab(newValue);

  // Load all data on component mount
  useEffect(() => {
    loadData();
  }, []);

  // Main data loading function
  const loadData = async () => {
    try {
      setLoading(true);
      console.log("Loading data...");

      // Business Settings
      try {
        const settingsResponse = await calculatorApi.getBusinessSettings();
        console.log("Settings response:", settingsResponse);
        
        const settings = settingsResponse?.data || settingsResponse || businessSettings;
        setBusinessSettings(settings);
      } catch (err) {
        console.error("Failed to load business settings:", err);
      }

      // Products
      try {
        const productsResponse = await stockApi.getProducts();
        console.log("Products response:", productsResponse);
        
        const productsArray = ensureArray(productsResponse);
        setProducts(productsArray);
        setFilteredProducts(productsArray);
      } catch (err) {
        console.error("Failed to load products:", err);
      }

      // Product Categories
      try {
        const categoriesResponse = await stockApi.getCategories();
        console.log("Categories response:", categoriesResponse);
        
        const categoriesArray = ensureArray(categoriesResponse);
        setProductCategories(categoriesArray);
      } catch (err) {
        console.error("Failed to load product categories:", err);
      }

      // Services
      try {
        const servicesResponse = await calculatorApi.getServices();
        console.log("Services response:", servicesResponse);
        
        const servicesArray = ensureArray(servicesResponse);
        setServices(servicesArray);
      } catch (err) {
        console.error("Failed to load services:", err);
      }

      // Service Categories
      try {
        const serviceCategoriesResponse = await calculatorApi.getServiceCategories();
        console.log("Service categories response:", serviceCategoriesResponse);
        
        const serviceCategoriesArray = ensureArray(serviceCategoriesResponse);
        setServiceCategories(serviceCategoriesArray);
      } catch (err) {
        console.error("Failed to load service categories:", err);
      }

      // Staff - Enhanced handling
      try {
        console.log("Fetching staff data...");
        const staffResponse = await calculatorApi.getStaff();
        console.log("Staff API response:", staffResponse);
        
        // Extract the staff array with multiple fallback methods
        let staffArray = [];
        
        if (staffResponse && staffResponse.data && Array.isArray(staffResponse.data)) {
          staffArray = staffResponse.data;
        } else if (Array.isArray(staffResponse)) {
          staffArray = staffResponse;
        } else {
          staffArray = ensureArray(staffResponse);
        }
        
        console.log("Extracted staff array:", staffArray);
        console.log("Staff array length:", staffArray.length);
        
        // Set state and filter staff with defensive programming
        if (Array.isArray(staffArray) && staffArray.length > 0) {
          setStaff(staffArray);
          
          // Filter for revenue-generating staff
          const revenue = staffArray.filter(staff => 
            staff && typeof staff === 'object' && staff.generates_revenue === true
          );
          
          // Filter for support staff
          const support = staffArray.filter(staff => 
            staff && typeof staff === 'object' && staff.generates_revenue === false
          );
          
          setRevenueStaff(revenue);
          setSupportStaff(support);
        } else {
          console.warn("No staff found or invalid staff data structure");
          setStaff([]);
          setRevenueStaff([]);
          setSupportStaff([]);
        }
      } catch (err) {
        console.error("Failed to load staff:", err);
        setStaff([]);
        setRevenueStaff([]);
        setSupportStaff([]);
      }

      // Expenses
      try {
        // Get period for expenses (default to last6months for more accurate representation)
        const period = 'last6months';
        console.log(`Fetching expense data for period: ${period}`);
        
        // Use the enhanced expense summary endpoint
        const expensesResponse = await calculatorApi.getExpenseSummary(period);
        console.log("Expenses summary response:", expensesResponse);
        
        // Save the direct response object which includes period and categoryBreakdown
        const expenseData = expensesResponse?.data || expensesResponse || {
          totalAmount: 0,
          categoryBreakdown: {},
          period: period
        };
        
        console.log(`Expense period: ${expenseData.period}, Total: ${expenseData.totalAmount}`);
        
        setExpenses(expenseData);
      } catch (err) {
        console.error("Failed to load expenses:", err);
        setExpenses({ totalAmount: 0, categoryBreakdown: {}, period: 'current' });
      }

      // Services Analysis
      try {
        const servicesArray = ensureArray(await calculatorApi.getServices());
        if (servicesArray.length > 0) {
          const analysisResponse = await calculatorApi.getServicesAnalysis();
          console.log("Analysis response:", analysisResponse);
          
          const analysisArray = ensureArray(analysisResponse);
          setServicesAnalysis(analysisArray);
        }
      } catch (err) {
        console.error("Failed to load services analysis:", err);
      }

      setLoading(false);
    } catch (err) {
      console.error('Error loading data:', err);
      setError(err.message || 'Failed to load data');
      setLoading(false);
    }
  };

  // Import dialog handlers
  const handleImportDialogOpen = () => {
    setIsImportDialogOpen(true);
    setImportFile(null);
    setImportResult(null);
  };
  
  const handleImportDialogClose = () => {
    setIsImportDialogOpen(false);
  };
  
  const handleFileChange = (e) => {
    setImportFile(e.target.files?.[0] || null);
  };
  
  // Service updating functions
  const saveService = async (serviceData, productsData) => {
    try {
      setLoading(true);
      const serviceResponse = await calculatorApi.saveService(serviceData.id, serviceData);
      const savedService = serviceResponse?.data || serviceResponse;

      if (savedService && Array.isArray(productsData)) {
        await Promise.all(productsData.map(sp => {
          if (!sp) return Promise.resolve();
          return sp.id
            ? stockApi.updateServiceProduct(sp.id, { ...sp, service_id: savedService.id })
            : stockApi.addProductToService({ ...sp, service_id: savedService.id });
        }));
      }

      setLoading(false);
      setSuccess('Service saved successfully');
      
      // Refresh services and analysis
      try {
        const servicesResponse = await calculatorApi.getServices();
        setServices(ensureArray(servicesResponse));
        
        const analysisResponse = await calculatorApi.getServicesAnalysis();
        setServicesAnalysis(ensureArray(analysisResponse));
      } catch (refreshErr) {
        console.error('Error refreshing data:', refreshErr);
      }
      
      return savedService;
    } catch (err) {
      setLoading(false);
      setError(err.message || 'Failed to save service');
      throw err;
    }
  };

  // Import services from file
  const handleImportServices = async () => {
    if (!importFile) {
      setError('Please select a file to import');
      return;
    }
    
    try {
      setIsImporting(true);
      const formData = new FormData();
      formData.append('file', importFile);
      
      const response = await calculatorApi.importServices(formData);
      const resultData = response?.data || response;
      setImportResult(resultData);
      
      // Refresh services after import
      try {
        const servicesResponse = await calculatorApi.getServices();
        setServices(ensureArray(servicesResponse));
        
        const analysisResponse = await calculatorApi.getServicesAnalysis();
        setServicesAnalysis(ensureArray(analysisResponse));
      } catch (refreshErr) {
        console.error('Error refreshing data after import:', refreshErr);
      }
      
      setSuccess(`Import successful: Created ${resultData?.created || 0} services, updated ${resultData?.updated || 0} services`);
    } catch (err) {
      setError(err.message || 'Failed to import services');
    } finally {
      setIsImporting(false);
    }
  };

  // Update service prices
  const handleUpdateServicePrices = async (servicesToUpdate, notes) => {
    try {
      setLoading(true);
      
      const response = await calculatorApi.updateServicePrices({
        services: servicesToUpdate,
        notes: notes,
        update_date: new Date().toISOString()
      });
      
      console.log('Price update response:', response);
      
      // Show success message
      setSuccess(`Successfully updated prices for ${servicesToUpdate.length} services.`);
      
      // Refresh services data
      const servicesResponse = await calculatorApi.getServices();
      setServices(ensureArray(servicesResponse));
      
      const analysisResponse = await calculatorApi.getServicesAnalysis();
      setServicesAnalysis(ensureArray(analysisResponse));
      
      setLoading(false);
      return response;
    } catch (err) {
      console.error('Error updating prices:', err);
      setError(err.message || 'Failed to update service prices. Please try again.');
      setLoading(false);
      throw err;
    }
  };

  // Save business settings
  const handleSaveSettings = async (settings) => {
    try {
      setLoading(true);
      
      const response = await calculatorApi.updateBusinessSettings(settings);
      const updatedSettings = response?.data || response || settings;
      
      setBusinessSettings(updatedSettings);
      setSuccess('Business settings updated successfully');
      setLoading(false);
      
      return updatedSettings;
    } catch (err) {
      console.error('Error updating settings:', err);
      setError(err.message || 'Failed to update settings');
      setLoading(false);
      throw err;
    }
  };

  return (
    <Container maxWidth="xl">
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Service Cost Calculator
        </Typography>
        <Alert severity="info" sx={{ mb: 3 }}>
          This integrated calculator helps you price your services profitably by pulling data from your salon management system.
        </Alert>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Overview" />
          <Tab label="Services" />
          <Tab label="Products" />
          <Tab label="Staff" />
          <Tab label="Expenses" />
          <Tab label="Settings" />
        </Tabs>
      </Paper>
      
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={3}>
          {error && (
            <Alert severity="error" sx={{ mb: 3 }} onClose={() => setError(null)}>
              {error}
            </Alert>
          )}
          {success && (
            <Alert severity="success" sx={{ mb: 3 }} onClose={() => setSuccess(null)}>
              {success}
            </Alert>
          )}

          {activeTab === 0 && (
            <OverviewTab
              businessSettings={businessSettings}
              revenueStaff={revenueStaff}
              expenses={expenses}
              servicesAnalysis={servicesAnalysis}
              services={services}
              setCurrentService={setCurrentService}
            />
          )}
          
          {activeTab === 1 && (
            <ServicesTab
              services={services}
              serviceCategories={serviceCategories}
              servicesAnalysis={servicesAnalysis}
              onImportClick={handleImportDialogOpen}
              onUpdatePrices={handleUpdateServicePrices}
              setError={setError}
            />
          )}
          
          {activeTab === 2 && (
            <ProductsTab
              products={products}
              productCategories={productCategories}
            />
          )}
          
          {activeTab === 3 && (
            <StaffTab 
              staff={staff} 
              calculatorApi={calculatorApi}
            />
          )}
          
          {activeTab === 4 && (
            <ExpenseManagement
              businessSettings={businessSettings}
              onUpdate={(settings) =>
                setBusinessSettings((prev) => ({
                  ...prev,
                  ...settings
                }))
              }
              calculatorApi={calculatorApi}
            />
          )}
          
          {activeTab === 5 && (
            <SettingsForm 
              businessSettings={businessSettings}
              onSaveSettings={handleSaveSettings}
              loading={loading}
            />
          )}
        </Box>
      )}
      
      <ImportDialog 
        isOpen={isImportDialogOpen}
        onClose={handleImportDialogClose}
        onFileChange={handleFileChange}
        selectedFile={importFile}
        isImporting={isImporting}
        importResult={importResult}
        onImport={handleImportServices}
      />
      
      <Snackbar 
        open={!!success} 
        autoHideDuration={6000} 
        onClose={() => setSuccess(null)} 
        message={success} 
      />
    </Container>
  );
}

export default ServiceCalculator;