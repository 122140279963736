import React, { useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Box
} from '@mui/material';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';

const SettingsForm = ({ 
  businessSettings, 
  onSaveSettings, 
  loading 
}) => {
  const [formSettings, setFormSettings] = useState({
    vat_rate: 20,
    profit_level_1: 20,
    profit_level_2: 30,
    total_trading_hours: 40,
    overhead_hourly: 0,
    staff_hourly: 0,
    owner_profit: 0,
    utilization_rate: 85
  });
  
  // Make sure all required fields exist with default values
  useEffect(() => {
    setFormSettings({
      vat_rate: businessSettings?.vat_rate ?? 20,
      profit_level_1: businessSettings?.profit_level_1 ?? 20,
      profit_level_2: businessSettings?.profit_level_2 ?? 30,
      total_trading_hours: businessSettings?.total_trading_hours ?? 40,
      overhead_hourly: businessSettings?.overhead_hourly ?? 0,
      staff_hourly: businessSettings?.staff_hourly ?? 0,
      owner_profit: businessSettings?.owner_profit ?? 0,
      utilization_rate: businessSettings?.utilization_rate ?? 85
    });
  }, [businessSettings]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (onSaveSettings) {
      onSaveSettings(formSettings);
    }
  };
  
  return (
    <Paper sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>Calculator Settings</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="VAT Rate (%)"
              name="vat_rate"
              value={formSettings.vat_rate || 0}
              onChange={handleChange}
              inputProps={{ min: 0, max: 100, step: 0.1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Total Trading Hours (per week)"
              name="total_trading_hours"
              value={formSettings.total_trading_hours || 0}
              onChange={handleChange}
              inputProps={{ min: 1 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Profit Level 1 (%)"
              name="profit_level_1"
              value={formSettings.profit_level_1 || 0}
              onChange={handleChange}
              inputProps={{ min: 0, step: 1 }}
              helperText="This is your minimum profit margin target"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Profit Level 2 (%)"
              name="profit_level_2"
              value={formSettings.profit_level_2 || 0}
              onChange={handleChange}
              inputProps={{ min: 0, step: 1 }}
              helperText="This is your ideal profit margin target"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Overhead Cost (per hour)"
              name="overhead_hourly"
              value={formSettings.overhead_hourly || 0}
              onChange={handleChange}
              inputProps={{ min: 0, step: 0.01 }}
              helperText="Business overhead costs per hour"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              type="number"
              label="Utilization Rate (%)"
              name="utilization_rate"
              value={formSettings.utilization_rate || 85}
              onChange={handleChange}
              inputProps={{ min: 1, max: 100, step: 1 }}
              helperText="Percentage of time that can be billed to clients"
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                disabled={loading}
              >
                {loading ? 'Saving...' : 'Save Settings'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default SettingsForm;