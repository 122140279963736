import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Alert,
  CircularProgress,
  Snackbar
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import stockApi from './stockApi';

function ProductManagement() {
  // State for products, categories, and suppliers
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // State for filters
  const [categoryFilter, setCategoryFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  
  // State for form and editing
  const [editingProduct, setEditingProduct] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    barcode: '',
    description: '',
    unit_type: 'uses',
    category_id: '',
    supplier_id: '',
    current_cost_price: 0,
    retail_price: 0,
    min_stock_level: 0,
    max_stock_level: 0,
    pack_size: 1,
    vat_rate: 20,
    opening_stock: 0
  });
  const [formVisible, setFormVisible] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  
  // State for category creation
  const [newCategoryDialog, setNewCategoryDialog] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  
  // Load products, categories, and suppliers when component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load categories and suppliers in parallel
        const [categoriesRes, suppliersRes] = await Promise.all([
          stockApi.getCategories(),
          stockApi.getSuppliers()
        ]);
        
        setCategories(categoriesRes.data || []);
        setSuppliers(suppliersRes.data || []);
        
        // Load products
        await fetchProducts();
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    loadData();
  }, []);
  
  // Fetch products with current filters
  const fetchProducts = async () => {
    try {
      const filters = {};
      if (categoryFilter) filters.categoryId = categoryFilter;
      if (searchTerm) filters.search = searchTerm;
      
      const productsResponse = await stockApi.getProducts(filters);
      setProducts(productsResponse.data || []);
    } catch (err) {
      console.error('Error fetching products:', err);
      setError(err.message);
    }
  };
  
  // Handle filter changes
  useEffect(() => {
    // Use debounce to prevent too many requests while typing
    const handler = setTimeout(() => {
      fetchProducts();
    }, 300);
    
    return () => clearTimeout(handler);
  }, [categoryFilter, searchTerm]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = ['current_cost_price', 'retail_price', 'pack_size', 'vat_rate', 'min_stock_level', 'max_stock_level', 'opening_stock'].includes(name)
      ? (value === '' ? 0 : parseFloat(value))
      : value;
    
    setFormData({
      ...formData,
      [name]: parsedValue
    });
  };
  
  // Reset form to default values
  const resetForm = () => {
    setFormData({
      name: '',
      barcode: '',
      description: '',
      unit_type: 'uses',
      category_id: '',
      supplier_id: '',
      current_cost_price: 0,
      retail_price: 0,
      min_stock_level: 0,
      max_stock_level: 0,
      pack_size: 1,
      vat_rate: 20,
      opening_stock: 0
    });
    setEditingProduct(null);
  };
  
  // Edit a product
  const handleEdit = (product) => {
    setEditingProduct(product);
    setFormData({
      name: product.name,
      barcode: product.barcode || '',
      description: product.description || '',
      unit_type: product.unit_type || 'uses',
      category_id: product.category_id || '',
      supplier_id: product.supplier_id || '',
      current_cost_price: product.current_cost_price || 0,
      retail_price: product.retail_price || 0,
      min_stock_level: product.min_stock_level || 0,
      max_stock_level: product.max_stock_level || 0,
      pack_size: product.pack_size || 1,
      vat_rate: product.vat_rate || 20,
      opening_stock: product.opening_stock || 0
    });
    setFormVisible(true);
  };
  
  // Handle category creation
  const handleCreateCategory = async () => {
    if (!newCategoryName.trim()) {
      setError('Category name cannot be empty');
      return;
    }
    
    try {
      const response = await stockApi.createCategory({ name: newCategoryName });
      const newCategory = response?.data || response;
      setCategories([...categories, newCategory]);
      setFormData({
        ...formData,
        category_id: newCategory.id
      });
      setNewCategoryName('');
      setNewCategoryDialog(false);
      setSuccessMessage('Category created successfully');
    } catch (err) {
      console.error('Error creating category:', err);
      setError(err.message || 'Failed to create category');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Convert empty category_id and supplier_id to null to avoid database constraints
      const submissionData = {
        ...formData,
        category_id: formData.category_id === '' ? null : formData.category_id,
        supplier_id: formData.supplier_id === '' ? null : formData.supplier_id
      };
      
      // Add old field names to match what API expects
      const apiFormData = {
        ...submissionData,
        sku: submissionData.barcode,
        unit: submissionData.unit_type,
        purchase_price: submissionData.current_cost_price,
        min_stock_level: submissionData.min_stock_level,
        max_stock_level: submissionData.max_stock_level,
        opening_stock: submissionData.opening_stock
      };
      
      if (editingProduct) {
        await stockApi.updateProduct(editingProduct.id, apiFormData);
        setSuccessMessage('Product updated successfully');
      } else {
        await stockApi.createProduct(apiFormData);
        setSuccessMessage('Product created successfully');
      }
      
      // Refresh product list
      await fetchProducts();
      
      // Reset form
      resetForm();
      setFormVisible(false);
      
    } catch (err) {
      console.error('Error saving product:', err);
      setError(err.message || 'Failed to save product');
    }
  };
  
  // Confirm product deletion
  const confirmDelete = (product) => {
    setProductToDelete(product);
    setDeleteConfirmOpen(true);
  };
  
  // Delete a product
  const handleDelete = async () => {
    if (!productToDelete) return;
    
    try {
      await stockApi.deleteProduct(productToDelete.id);
      setSuccessMessage('Product deleted successfully');
      
      // Refresh product list
      await fetchProducts();
      
    } catch (err) {
      console.error('Error deleting product:', err);
      setError(err.message || 'Failed to delete product');
    } finally {
      setDeleteConfirmOpen(false);
      setProductToDelete(null);
    }
  };
  
  // Close success message
  const handleCloseSuccess = () => {
    setSuccessMessage(null);
  };
  
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Product Management
      </Typography>
      
      {/* Filters */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth>
              <InputLabel id="category-filter-label">Category</InputLabel>
              <Select
                labelId="category-filter-label"
                id="category-filter"
                value={categoryFilter}
                label="Category"
                onChange={(e) => setCategoryFilter(e.target.value)}
              >
                <MenuItem value="">All Categories</MenuItem>
                {categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              id="search"
              label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by name or barcode"
            />
          </Grid>
          
          <Grid item xs={12} md={4}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                resetForm();
                setFormVisible(true);
              }}
              fullWidth
            >
              Add New Product
            </Button>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Error message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      
      {/* Product list */}
      <Paper sx={{ width: '100%', mb: 3, overflow: 'hidden' }}>
        <Typography variant="h5" component="h2" sx={{ p: 2 }}>
          Products ({products.length})
        </Typography>
        
        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : products.length === 0 ? (
          <Box p={3}>
            <Typography>No products found.</Typography>
          </Box>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Barcode</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Unit Type</TableCell>
                  <TableCell align="right">Cost Price</TableCell>
                  <TableCell align="right">Retail Price</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products.map(product => (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.barcode}</TableCell>
                    <TableCell>{product.category_name || 'Uncategorized'}</TableCell>
                    <TableCell>{product.unit_type}</TableCell>
                    <TableCell align="right">£{product.current_cost_price?.toFixed(2)}</TableCell>
                    <TableCell align="right">£{product.retail_price?.toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <IconButton 
                        color="primary" 
                        onClick={() => handleEdit(product)}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        color="error" 
                        onClick={() => confirmDelete(product)}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      
      {/* Product form dialog */}
      <Dialog 
        open={formVisible} 
        onClose={() => setFormVisible(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          {editingProduct ? 'Edit Product' : 'Add New Product'}
        </DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="name"
                  name="name"
                  label="Product Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="barcode"
                  name="barcode"
                  label="Barcode/SKU"
                  value={formData.barcode}
                  onChange={handleInputChange}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  value={formData.description}
                  onChange={handleInputChange}
                  multiline
                  rows={3}
                  margin="normal"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  required
                  id="unit_type"
                  name="unit_type"
                  label="Unit Type"
                  value={formData.unit_type}
                  onChange={handleInputChange}
                  margin="normal"
                  placeholder="e.g., ml, g, uses"
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="category-select-label">Category</InputLabel>
                  <Select
                    labelId="category-select-label"
                    id="category_id"
                    name="category_id"
                    value={formData.category_id}
                    label="Category"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">
                      <em>Select Category</em>
                    </MenuItem>
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                    <MenuItem 
                      value="create_new" 
                      onClick={(e) => {
                        e.stopPropagation();
                        setNewCategoryDialog(true);
                      }}
                      sx={{ color: 'primary.main', fontWeight: 'bold' }}
                    >
                      + Create New Category
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              
              {/* Supplier select field */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel id="supplier-select-label">Supplier</InputLabel>
                  <Select
                    labelId="supplier-select-label"
                    id="supplier_id"
                    name="supplier_id"
                    value={formData.supplier_id}
                    label="Supplier"
                    onChange={handleInputChange}
                  >
                    <MenuItem value="">
                      <em>Select Supplier</em>
                    </MenuItem>
                    {suppliers.map(supplier => (
                      <MenuItem key={supplier.id} value={supplier.id}>
                        {supplier.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="current_cost_price"
                  name="current_cost_price"
                  label="Cost Price"
                  type="number"
                  value={formData.current_cost_price}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 0.01
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="retail_price"
                  name="retail_price"
                  label="Retail Price"
                  type="number"
                  value={formData.retail_price}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 0.01
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="pack_size"
                  name="pack_size"
                  label="Pack Size"
                  type="number"
                  value={formData.pack_size}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0.01,
                    step: 0.01
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="vat_rate"
                  name="vat_rate"
                  label="VAT Rate (%)"
                  type="number"
                  value={formData.vat_rate}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    max: 100,
                    step: 0.1
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="min_stock_level"
                  name="min_stock_level"
                  label="Reorder Level"
                  type="number"
                  value={formData.min_stock_level}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                />
              </Grid>
              
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="max_stock_level"
                  name="max_stock_level"
                  label="Reorder Quantity"
                  type="number"
                  value={formData.max_stock_level}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                />
              </Grid>
              
              {/* Opening Stock field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="opening_stock"
                  name="opening_stock"
                  label="Opening Stock"
                  type="number"
                  value={formData.opening_stock}
                  onChange={handleInputChange}
                  margin="normal"
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => {
                resetForm();
                setFormVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              {editingProduct ? 'Update Product' : 'Create Product'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      
      {/* Delete confirmation dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the product "{productToDelete?.name}"? 
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* New Category Dialog */}
      <Dialog
        open={newCategoryDialog}
        onClose={() => setNewCategoryDialog(false)}
      >
        <DialogTitle>Create New Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter a name for the new product category.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="categoryName"
            label="Category Name"
            type="text"
            fullWidth
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewCategoryDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateCategory} color="primary" variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Success message */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        message={successMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
}

export default ProductManagement;