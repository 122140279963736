import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
} from '@mui/material';
import {
  Download,
  Refresh,
  TrendingUp,
  TrendingDown,
  PieChart,
  CompareArrows
} from '@mui/icons-material';
import DatePickerComponent from './DatePickerComponent';
import dayjs from 'dayjs';
import api from './axiosConfig';

// Helper function to safely access nested properties
const safeGet = (obj, path, fallback = '') => {
  try {
    const result = path.split('.').reduce((o, key) => (o || {})[key], obj);
    return result !== undefined && result !== null ? result : fallback;
  } catch (e) {
    return fallback;
  }
};

const ProfitLossReport = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [exportFormat, setExportFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  // Avoid month-end date issues by ensuring proper date formatting
  const formatDateForApi = (date) => {
    if (!date) return '';
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  };

  // Safe formatting for currency values with fallbacks
  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) {
      return '£0.00';
    }
    
    try {
      return `£${parseFloat(amount).toFixed(2)}`;
    } catch (e) {
      return '£0.00';
    }
  };

  const handleGenerateReport = async () => {
    setLoading(true);
    setError(null);

    try {
      // Changed from GET to POST
      const response = await api.post('/reports/profit-loss', {
        start_date: formatDateForApi(startDate),
        end_date: formatDateForApi(endDate)
      });

      // Check if response has data
      if (response.data) {
        setReport(response.data);
        console.log('Report data:', response.data);
      } else {
        setError('No data returned from server');
      }
    } catch (err) {
      console.error('Error fetching profit-loss report:', err);
      
      // Enhanced error handling
      if (err.response?.status === 403) {
        setError('Access denied. You do not have permission to view this report.');
      } else if (err.response?.status === 404) {
        setError('Report endpoint not found. The secure reports module may not be properly configured.');
      } else if (err.response?.status === 405) {
        setError('Method not allowed. The API endpoint does not support this request method.');
      } else if (err.response?.data?.error) {
        setError(`Error: ${err.response.data.error}`);
      } else {
        setError('Failed to generate report. Please try again or contact support.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (format) => {
    if (!format) {
      setError('Please select an export format');
      return;
    }
    
    setLoading(true);
    
    try {
      // Changed from GET to POST
      const response = await api.post('/reports/profit-loss', 
        {
          start_date: formatDateForApi(startDate),
          end_date: formatDateForApi(endDate),
          export_format: format
        },
        {
          responseType: 'blob'
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `profit_loss_report.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error('Error exporting report:', err);
      setError('Failed to export report. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePickerComponent
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleGenerateReport}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
              >
                Generate Report
              </Button>
              <TextField
                select
                label="Export Format"
                value={exportFormat}
                onChange={(e) => setExportFormat(e.target.value)}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="CSV">CSV</MenuItem>
                <MenuItem value="EXCEL">Excel</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                onClick={() => handleExport(exportFormat)}
                disabled={!report || !exportFormat || loading}
                startIcon={<Download />}
              >
                Export
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {report && !loading && (
        <Box>
          {/* Summary Cards */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrendingUp color="success" />
                    <Typography variant="h6">Gross Income</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {formatCurrency(safeGet(report, 'data.income.totals.gross', 0))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <TrendingDown color="error" />
                    <Typography variant="h6">Total Expenses</Typography>
                  </Box>
                  <Typography variant="h4" sx={{ mt: 2 }}>
                    {formatCurrency(safeGet(report, 'data.expenses.totals.gross', 0))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <PieChart color="primary" />
                    <Typography variant="h6">Net Profit</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: safeGet(report, 'data.metrics.net_profit', 0) >= 0 ? 'success.main' : 'error.main'
                    }}
                  >
                    {formatCurrency(safeGet(report, 'data.metrics.net_profit', 0))}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CompareArrows color="info" />
                    <Typography variant="h6">Profit Margin</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: safeGet(report, 'data.metrics.profit_margin_percentage', 0) >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    {safeGet(report, 'data.metrics.profit_margin_percentage', 0).toFixed(1)}%
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Income Breakdown */}
          {Array.isArray(safeGet(report, 'data.income.breakdown', [])) && (
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Income Breakdown
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Gross Amount</TableCell>
                      <TableCell align="right">VAT</TableCell>
                      <TableCell align="right">Net Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {safeGet(report, 'data.income.breakdown', []).map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{safeGet(item, 'type', 'Unknown')}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'gross_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'vat_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'net_amount', 0))}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.income.totals.gross', 0))}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.income.totals.vat', 0))}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.income.totals.net', 0))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}

          {/* Expense Breakdown */}
          {Array.isArray(safeGet(report, 'data.expenses.breakdown', [])) && (
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Expense Breakdown
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell align="right">Gross Amount</TableCell>
                      <TableCell align="right">VAT</TableCell>
                      <TableCell align="right">Net Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {safeGet(report, 'data.expenses.breakdown', []).map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{safeGet(item, 'category', 'Unknown')}</TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'gross_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'vat_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(item, 'net_amount', 0))}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.expenses.totals.gross', 0))}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.expenses.totals.vat', 0))}
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: 'bold' }}>
                        {formatCurrency(safeGet(report, 'data.expenses.totals.net', 0))}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}

          {/* Comparative Analysis */}
          {safeGet(report, 'data.comparative_data') && (
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Comparative Analysis with Previous Period
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Income Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: safeGet(report, 'data.comparative_data.changes.income.percentage', 0) >= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {safeGet(report, 'data.comparative_data.changes.income.percentage', 0).toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatCurrency(safeGet(report, 'data.comparative_data.changes.income.amount', 0))}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Expense Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: safeGet(report, 'data.comparative_data.changes.expenses.percentage', 0) <= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {safeGet(report, 'data.comparative_data.changes.expenses.percentage', 0).toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatCurrency(safeGet(report, 'data.comparative_data.changes.expenses.amount', 0))}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card>
                    <CardContent>
                      <Typography variant="subtitle1">Profit Change</Typography>
                      <Typography 
                        variant="h5"
                        sx={{ 
                          color: safeGet(report, 'data.comparative_data.changes.net_profit.percentage', 0) >= 0 
                            ? 'success.main' 
                            : 'error.main' 
                        }}
                      >
                        {safeGet(report, 'data.comparative_data.changes.net_profit.percentage', 0).toFixed(1)}%
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {formatCurrency(safeGet(report, 'data.comparative_data.changes.net_profit.amount', 0))}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Paper>
          )}
          
          {/* Display an alert if report data structure is different than expected */}
          {!safeGet(report, 'data.income') && !safeGet(report, 'data.expenses') && (
            <Alert severity="info" sx={{ mt: 3 }}>
              The report data structure may have changed. This could be due to recent updates in the reporting system.
              Please contact support if you need assistance.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProfitLossReport;