import React from 'react';
import { Box, Typography, Grid, Paper, Tabs, Tab } from '@mui/material';
import VatMonitoring from './VatMonitoring';
import VatReturnPeriods from './VatReturnPeriods';
import VatLiabilities from './VatLiabilities';
import { useAuth } from './AuthContext';
// Import the new components
import TaxAccrual from './TaxAccrual';
import Drawings from './Drawings';

const VatDashboard = () => {
  const { user } = useAuth();
  const [tabValue, setTabValue] = React.useState(0);
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  return (
    <Box>
      <Typography variant="h4" gutterBottom>Finance Dashboard</Typography>
      
      <Tabs 
        value={tabValue} 
        onChange={handleTabChange} 
        sx={{ mb: 3 }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="VAT" />
        <Tab label="Tax Accruals" />
        <Tab label="Drawings" />
      </Tabs>
      
      {/* VAT Tab */}
      {tabValue === 0 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <VatMonitoring />
          </Grid>
          
          <Grid item xs={12}>
            <VatLiabilities />
          </Grid>
          
          <Grid item xs={12}>
            <VatReturnPeriods />
          </Grid>
        </Grid>
      )}
      
      {/* Tax Accruals Tab */}
      {tabValue === 1 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TaxAccrual />
          </Grid>
        </Grid>
      )}
      
      {/* Drawings Tab */}
      {tabValue === 2 && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Drawings />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default VatDashboard;