import React, { useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Paper,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';

const StaffTab = ({ staff, calculatorApi }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({
    responseAvailable: false,
    directFetch: false
  });
  
  // Load staff directly in the component to ensure fresh data
  useEffect(() => {
    const loadStaff = async () => {
      try {
        setIsLoading(true);
        setError(null);
        
        // First try using the staff prop
        if (Array.isArray(staff) && staff.length > 0) {
          console.log("StaffTab: Using provided staff prop:", staff);
          setStaffList(staff);
          setDebugInfo({
            responseAvailable: true,
            directFetch: false,
            staffCount: staff.length
          });
        } else if (calculatorApi) {
          // If staff prop is empty or invalid and API is available, fetch directly from API
          console.log("StaffTab: Staff prop empty or invalid, fetching directly from API");
          setDebugInfo(prev => ({ ...prev, directFetch: true }));
          
          const response = await calculatorApi.getStaff();
          console.log("StaffTab: Direct API response:", response);
          
          // Process the response to extract the staff array
          let staffData = [];
          if (Array.isArray(response)) {
            staffData = response;
          } else if (response && response.data && Array.isArray(response.data)) {
            staffData = response.data;
          } else if (response && response.success && response.data && Array.isArray(response.data)) {
            staffData = response.data;
          }
          
          console.log("StaffTab: Processed staff data:", staffData);
          setStaffList(staffData);
          setDebugInfo(prev => ({
            ...prev,
            responseAvailable: true,
            staffCount: staffData.length,
            responseType: typeof response,
            hasDataProp: response && response.data ? true : false
          }));
        } else {
          // No staff data available and no API to fetch from
          console.warn("StaffTab: No staff data available and no API to fetch from");
          setStaffList([]);
          setDebugInfo(prev => ({
            ...prev,
            responseAvailable: false,
            directFetch: false
          }));
        }
        
        setIsLoading(false);
      } catch (err) {
        console.error("StaffTab: Failed to load staff:", err);
        setError(`Failed to load staff data: ${err.message}`);
        setDebugInfo(prev => ({
          ...prev,
          error: err.message
        }));
        setIsLoading(false);
      }
    };
    
    loadStaff();
  }, [staff, calculatorApi]); // Re-run when staff prop or API changes
  
  // Use staffList from local state instead of props
  const safeStaff = Array.isArray(staffList) ? staffList : [];
  
  // If we're still using props, fallback to that
  const displayStaff = safeStaff.length > 0 ? safeStaff : (Array.isArray(staff) ? staff : []);
  
  // Function to manually test the API
  const testApiCall = async () => {
    if (!calculatorApi) {
      setError("API client not available");
      return;
    }
    
    try {
      setIsLoading(true);
      const response = await calculatorApi.getStaff();
      console.log("Manual API call response:", response);
      setIsLoading(false);
    } catch (err) {
      console.error("Manual API call error:", err);
      setError(`API test failed: ${err.message}`);
      setIsLoading(false);
    }
  };
  
  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Typography variant="h6" sx={{ p: 2 }}>Staff ({displayStaff.length})</Typography>
          
          {/* Debug info section - remove in production */}
          <Box sx={{ p: 2, mb: 2, bgcolor: 'info.lighter', borderRadius: 1 }}>
            <Typography variant="subtitle2">Debug Information</Typography>
            <Typography variant="body2">Data source: {debugInfo.directFetch ? 'Direct API fetch' : 'Parent component prop'}</Typography>
            <Typography variant="body2">Staff count: {displayStaff.length}</Typography>
            <Button 
              size="small" 
              variant="outlined" 
              onClick={() => console.log("Current staff data:", displayStaff)}
              sx={{ mt: 1, mr: 1 }}
            >
              Log Staff Data
            </Button>
            <Button 
              size="small" 
              variant="outlined" 
              onClick={testApiCall}
              sx={{ mt: 1 }}
            >
              Test API Call
            </Button>
          </Box>
          
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Hourly Rate</TableCell>
                <TableCell>Revenue Generator</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayStaff.length > 0 ? (
                displayStaff.map((member, index) => {
                  if (!member) return null;
                  
                  // Safely extract properties with fallbacks
                  const id = member.id || `staff-${index}`;
                  const name = member.name || 'Unnamed Staff';
                  const role = member.job_role || member.system_role || 'Unspecified';
                  const hourlyRate = member.hourly_rate || 0;
                  const generatesRevenue = member.generates_revenue === true;
                  
                  return (
                    <TableRow key={id}>
                      <TableCell>{name}</TableCell>
                      <TableCell>{role}</TableCell>
                      <TableCell>£{hourlyRate.toFixed(2)}</TableCell>
                      <TableCell>{generatesRevenue ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No staff members found. Please check the API response in the console.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default StaffTab;