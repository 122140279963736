import errorHandler from './utils/errorHandler';
import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import api from './axiosConfig';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';

const validationSchema = Yup.object().shape({
  date: Yup.date().required('Date is required'),
  cash: Yup.number().min(0, 'Cash must be non-negative').required('Cash is required'),
  card: Yup.number().min(0, 'Card must be non-negative').required('Card is required'),
  voucher: Yup.number().min(0, 'Voucher must be non-negative').required('Voucher is required'),
  total: Yup.number().min(0, 'Total must be non-negative').required('Total is required'),
  employees: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().required(),
      service_sales: Yup.number().min(0, 'Service sales must be non-negative'),
      client_count: Yup.number().integer('Client count must be an integer').min(0, 'Client count must be non-negative'),
      product_sales: Yup.number().min(0, 'Product sales must be non-negative'),
      product_count: Yup.number().integer('Product count must be an integer').min(0, 'Product count must be non-negative'),
      start_time: Yup.string().required('Start time is required'),
      end_time: Yup.string().required('End time is required'),
      hours_worked: Yup.number().min(0, 'Hours worked must be non-negative')
    })
  )
});

// Component to handle missing dates dialog
const MissingDatesDialog = ({ onComplete }) => {
  const [open, setOpen] = useState(false);
  const [missingDates, setMissingDates] = useState([]);
  const [nonWorkingSelections, setNonWorkingSelections] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    checkMissingDates();
  }, []);

  const checkMissingDates = async () => {
    try {
      setLoading(true);
      errorHandler.logDebug('MissingDatesDialog', 'Checking for missing dates...');
      const response = await api.get('/income/missing_dates');
      errorHandler.logDebug('MissingDatesDialog', 'Missing dates response:', response.data);
      
      if (response.data && response.data.missing_dates && response.data.missing_dates.length > 0) {
        setMissingDates(response.data.missing_dates);
        
        // Initialize selections
        const selections = {};
        response.data.missing_dates.forEach(date => {
          selections[date] = false;
        });
        setNonWorkingSelections(selections);
        
        // Open the dialog
        setOpen(true);
      } else {
        // No missing dates, proceed
        onComplete();
      }
    } catch (error) {
      console.error('Error checking missing dates:', error);
      setError('Error checking for missing dates. Please try again.');
      onComplete(); // Continue anyway
    } finally {
      setLoading(false);
    }
  };

  const handleNonWorkingChange = (date) => {
    setNonWorkingSelections(prev => ({
      ...prev,
      [date]: !prev[date]
    }));
  };

  const handleSaveNonWorkingDays = async () => {
    try {
      setLoading(true);
      // Get dates marked as non-working
      const nonWorkingDays = Object.entries(nonWorkingSelections)
        .filter(([_, isNonWorking]) => isNonWorking)
        .map(([date]) => date);
      
      if (nonWorkingDays.length > 0) {
        errorHandler.logDebug('MissingDatesDialog', 'Marking non-working days:', nonWorkingDays);
        // Mark selected dates as non-working
        await api.post('/income/mark_non_working_days', {
          dates: nonWorkingDays,
          notes: 'Marked during date reconciliation'
        });
      }
      
      setOpen(false);
      onComplete();
    } catch (error) {
      console.error('Error saving non-working days:', error);
      setError('Error saving non-working days. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    setOpen(false);
    onComplete();
  };

  // Format date for display
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>Missing Daily Entries</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        <Alert severity="info" sx={{ mb: 2 }}>
          There are {missingDates.length} day(s) without income entries. Please check any non-working days.
        </Alert>
        
        <Box sx={{ maxHeight: '300px', overflowY: 'auto', mt: 2 }}>
          {missingDates.map(date => (
            <FormControlLabel
              key={date}
              control={
                <Checkbox 
                  checked={nonWorkingSelections[date] || false}
                  onChange={() => handleNonWorkingChange(date)}
                  disabled={loading}
                />
              }
              label={`${formatDate(date)} - Non-working day`}
              sx={{ display: 'block', mb: 1 }}
            />
          ))}
        </Box>
        
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Any day not marked as non-working will require income data entry later.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSkip} disabled={loading}>
          Skip for Now
        </Button>
        <Button 
          onClick={handleSaveNonWorkingDays}
          variant="contained"
          disabled={loading}
        >
          Save and Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Last Entry Date indicator component
const LastEntryIndicator = () => {
  const [lastEntry, setLastEntry] = useState(null);
  
  useEffect(() => {
    fetchLastEntryDate();
  }, []);
  
  const fetchLastEntryDate = async () => {
    try {
      const response = await api.get('/income/last_entry_date');
      if (response.data && response.data.last_date) {
        setLastEntry(response.data.last_date);
      }
    } catch (error) {
      console.error('Error fetching last entry date:', error);
    }
  };
  
  if (!lastEntry) return null;
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  };
  
  return (
    <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
      Last entry: {formatDate(lastEntry)}
    </Typography>
  );
};

// Non-Working Day checkbox component
const NonWorkingDayToggle = ({ date, onChange }) => {
  const [isNonWorking, setIsNonWorking] = useState(false);
  
  const handleToggle = async (event) => {
    const checked = event.target.checked;
    setIsNonWorking(checked);
    
    if (checked) {
      try {
        errorHandler.logDebug('NonWorkingDayToggle', 'Marking non-working day:', date);
        await api.post('/income/mark_non_working_day', {
          date: date,
          notes: 'Marked as non-working day'
        });
        
        if (onChange) {
          onChange(true);
        }
      } catch (error) {
        console.error('Error marking non-working day:', error);
        setIsNonWorking(false);
      }
    }
  };
  
  return (
    <FormControlLabel
      control={
        <Checkbox 
          checked={isNonWorking}
          onChange={handleToggle}
        />
      }
      label="Non-working day"
    />
  );
};

const IncomeCard = () => {
  const { user } = useAuth();
  const [incomeData, setIncomeData] = useState({
    date: new Date().toISOString().split('T')[0],
    cash: '',
    card: '',
    voucher: '',
    total: '0.00',
    employees: []
  });
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [balanceInfo, setBalanceInfo] = useState({
    expected: '0.00',
    actual: '0.00',
    difference: '0.00',
    status: 'Balanced'
  });
  // New state variables for date tracking
  const [dataInitialized, setDataInitialized] = useState(false);
  const [showEmployeeData, setShowEmployeeData] = useState(true);

  useEffect(() => {
    errorHandler.logDebug('IncomeCard', 'IncomeCard component mounted');
    fetchEmployees();
    return () => {
      errorHandler.logDebug('IncomeCard', 'IncomeCard component unmounting');
    };
  }, []);

  useEffect(() => {
    errorHandler.logDebug('IncomeCard', 'Income data updated:', incomeData);
    calculateBalance();
  }, [incomeData]);

  // Handler for when missing dates are handled
  const handleDateCheckComplete = () => {
    setDataInitialized(true);
  };
  
  // Handler for when day is marked as non-working
  const handleDayMarkedNonWorking = (marked) => {
    if (marked) {
      // If day marked as non-working, hide employee data entry
      setShowEmployeeData(false);
      // Clear employee data
      setIncomeData(prev => ({
        ...prev,
        employees: prev.employees.map(emp => ({
          ...emp,
          worked_today: false,
          service_sales: '',
          client_count: '',
          product_sales: '',
          product_count: '',
          start_time: '',
          end_time: '',
          hours_worked: ''
        }))
      }));
    } else {
      setShowEmployeeData(true);
    }
  };

  const calculateBalance = () => {
    // Sum up all service and product sales from employees who worked
    const totalSales = incomeData.employees.reduce((sum, emp) => {
      if (emp.worked_today) {
        return sum + 
          (Number(emp.service_sales) || 0) + 
          (Number(emp.product_sales) || 0);
      }
      return sum;
    }, 0);

    // Calculate actual total (cash + card + voucher)
    const actualTotal = (Number(incomeData.cash) || 0) + 
                       (Number(incomeData.card) || 0) + 
                       (Number(incomeData.voucher) || 0);

    // Calculate difference
    const difference = actualTotal - totalSales;
    
    setBalanceInfo({
      expected: totalSales.toFixed(2),
      actual: actualTotal.toFixed(2),
      difference: difference.toFixed(2),
      status: difference === 0 ? 'Balanced' : difference > 0 ? 'Over' : 'Short'
    });
  };

  const fetchEmployees = async () => {
    try {
      errorHandler.logDebug('IncomeCard', 'Attempting to fetch employees...');
      const response = await api.get('/employees');  // Changed endpoint
      errorHandler.logDebug('IncomeCard', 'Fetched employees response:', response);
      
      if (!response.data) {
        throw new Error('No data received from server');
      }
      
      const activeEmployees = response.data.map(employee => ({  // No need to check array since /active always returns array
        id: employee.id,
        name: employee.name,
        worked_today: false,
        service_sales: '',
        client_count: '',
        product_sales: '',
        product_count: '',
        start_time: '',
        end_time: '',
        hours_worked: ''
      }));
      
      errorHandler.logDebug('IncomeCard', 'Processed employees:', activeEmployees);
      setIncomeData(prev => ({ ...prev, employees: activeEmployees }));
      
    } catch (error) {
      console.error('Error fetching employees:', error);
      setSnackbar({ 
        open: true, 
        message: `Error fetching employees: ${error.message}`, 
        severity: 'error' 
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setIncomeData(prev => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
    calculateTotal();
  };

  const handleEmployeeChange = (employeeId, field, value) => {
    setIncomeData(prev => ({
      ...prev,
      employees: prev.employees.map(emp => {
        if (emp.id === employeeId) {
          let updatedValue = value;
          if (['service_sales', 'client_count', 'product_sales', 'product_count'].includes(field)) {
            updatedValue = value === '' ? '' : Number(value);
          }
          const updatedEmp = { ...emp, [field]: updatedValue };
          if (field === 'start_time' || field === 'end_time') {
            updatedEmp.hours_worked = calculateHoursWorked(updatedEmp.start_time, updatedEmp.end_time);
          }
          return updatedEmp;
        }
        return emp;
      })
    }));
  };

  const calculateHoursWorked = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const start = new Date(`1970-01-01T${startTime}`);
    const end = new Date(`1970-01-01T${endTime}`);
    let diff = (end - start) / 3600000; // Convert milliseconds to hours
    if (diff < 0) diff += 24; // Handle cases where end time is on the next day
    return Number(diff.toFixed(2));
  };

  const calculateTotal = () => {
    const total = (parseFloat(incomeData.cash) || 0) + 
                  (parseFloat(incomeData.card) || 0) + 
                  (parseFloat(incomeData.voucher) || 0);
    setIncomeData(prev => ({ ...prev, total: total.toFixed(2) }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const workedEmployees = incomeData.employees.filter(emp => emp.worked_today);
      
      const submissionData = {
        date: incomeData.date,
        cash: Number(incomeData.cash) || 0,
        card: Number(incomeData.card) || 0,
        voucher: Number(incomeData.voucher) || 0,
        total: Number(incomeData.total) || 0,
        balance_info: balanceInfo,
        employees: workedEmployees.map(emp => ({
          id: emp.id,
          service_sales: Number(emp.service_sales) || 0,
          client_count: Number(emp.client_count) || 0,
          product_sales: Number(emp.product_sales) || 0,
          product_count: Number(emp.product_count) || 0,
          start_time: emp.start_time,
          end_time: emp.end_time,
          hours_worked: Number(emp.hours_worked) || 0
        }))
      };

      errorHandler.logDebug('IncomeCard', 'Income data before validation:', JSON.stringify(submissionData, null, 2));
      await validationSchema.validate(submissionData, { abortEarly: false });

      errorHandler.logDebug('IncomeCard', 'Validation passed, submitting income data');
      const response = await api.post('/income/submit', submissionData);
      errorHandler.logApiCall('Response:', response);
      setSnackbar({ open: true, message: 'Income data saved successfully', severity: 'success' });
    } catch (error) {
      console.error('Validation or submission error:', error);
      if (error instanceof Yup.ValidationError) {
        console.error('Validation error details:', error.inner);
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error('Error saving income data:', error);
        setSnackbar({ 
          open: true, 
          message: `Error: ${error.response?.data?.error || error.message}`, 
          severity: 'error' 
        });
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      {/* Date tracking dialog - only shown until missing dates are handled */}
      {!dataInitialized && (
        <MissingDatesDialog onComplete={handleDateCheckComplete} />
      )}
      
      <Typography variant="h4" gutterBottom>Income Card</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Date"
              type="date"
              name="date"
              value={incomeData.date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              error={!!errors.date}
              helperText={errors.date}
            />
          </Grid>
        </Grid>

        {/* Non-working day toggle and Simple Income Entry */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2, mb: 2 }}>
          <NonWorkingDayToggle 
            date={incomeData.date} 
            onChange={handleDayMarkedNonWorking} 
          />
          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <LastEntryIndicator />
            <Button 
              variant="outlined"
              color="primary"
              component={Link}
              to="/income/entry"
            >
              Simple Income Entry
            </Button>
          </Box>
        </Box>

        {/* Employee Data section - only shown if not marked as non-working */}
        {showEmployeeData && (
          <>
            <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Employee Data</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Worked Today</TableCell>
                    <TableCell>Service Sales</TableCell>
                    <TableCell>Client Count</TableCell>
                    <TableCell>Product Sales</TableCell>
                    <TableCell>Product Count</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Hours Worked</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {incomeData.employees.map((employee) => (
                    <TableRow key={employee.id}>
                      <TableCell>{employee.name}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={employee.worked_today}
                              onChange={(e) => handleEmployeeChange(employee.id, 'worked_today', e.target.checked)}
                            />
                          }
                          label="Worked"
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={employee.service_sales}
                          onChange={(e) => handleEmployeeChange(employee.id, 'service_sales', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.service_sales}
                          helperText={errors.employees?.[employee.id]?.service_sales}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={employee.client_count}
                          onChange={(e) => handleEmployeeChange(employee.id, 'client_count', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.client_count}
                          helperText={errors.employees?.[employee.id]?.client_count}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={employee.product_sales}
                          onChange={(e) => handleEmployeeChange(employee.id, 'product_sales', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.product_sales}
                          helperText={errors.employees?.[employee.id]?.product_sales}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={employee.product_count}
                          onChange={(e) => handleEmployeeChange(employee.id, 'product_count', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.product_count}
                          helperText={errors.employees?.[employee.id]?.product_count}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="time"
                          value={employee.start_time}
                          onChange={(e) => handleEmployeeChange(employee.id, 'start_time', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.start_time}
                          helperText={errors.employees?.[employee.id]?.start_time}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="time"
                          value={employee.end_time}
                          onChange={(e) => handleEmployeeChange(employee.id, 'end_time', e.target.value)}
                          disabled={!employee.worked_today}
                          error={!!errors.employees?.[employee.id]?.end_time}
                          helperText={errors.employees?.[employee.id]?.end_time}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          type="text"
                          value={employee.hours_worked}
                          InputProps={{ readOnly: true }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Cash"
              type="text"
              name="cash"
              value={incomeData.cash}
              onChange={handleChange}
              error={!!errors.cash}
              helperText={errors.cash}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Card"
              type="text"
              name="card"
              value={incomeData.card}
              onChange={handleChange}
              error={!!errors.card}
              helperText={errors.card}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Voucher"
              type="text"
              name="voucher"
              value={incomeData.voucher}
              onChange={handleChange}
              error={!!errors.voucher}
              helperText={errors.voucher}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Total"
              type="text"
              name="total"
              value={incomeData.total}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          
          {/* Balance Information */}
          <Grid item xs={12}>
            <Paper 
              sx={{ 
                p: 2, 
                mt: 2, 
                bgcolor: 'background.default',
                border: 1,
                borderColor: 'divider'
              }}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">
                    Expected Total: £{balanceInfo.expected}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography variant="subtitle1">
                    Actual Total: £{balanceInfo.actual}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography 
                    variant="subtitle1" 
                    sx={{
                      color: balanceInfo.status === 'Balanced' 
                        ? 'success.main'
                        : balanceInfo.status === 'Over'
                          ? 'warning.main'
                          : 'error.main',
                      fontWeight: 'bold'
                    }}
                  >
                    Status: {balanceInfo.status} 
                    {balanceInfo.status !== 'Balanced' && 
                      ` (£${Math.abs(parseFloat(balanceInfo.difference)).toFixed(2)})`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            variant="contained" 
            type="submit"
          >
            Save Income Data
          </Button>
        </Box>
      </form>
      
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert 
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default IncomeCard;