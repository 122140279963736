import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import stockApi from './stockApi'; // Your existing stock API

const ProductSelection = ({ selectedProducts = [], onChange, readOnly = false }) => {
  // State for all products from the inventory
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  // State for selection and filtering
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Calculated total
  const [total, setTotal] = useState(0);
  
  // Load products and categories on component mount
  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);
        
        // Load product categories
        const categoriesResponse = await stockApi.getCategories();
        const categoriesArray = Array.isArray(categoriesResponse?.data) 
          ? categoriesResponse.data 
          : Array.isArray(categoriesResponse) 
            ? categoriesResponse 
            : [];
        setCategories(categoriesArray);
        
        // Load products
        const productsResponse = await stockApi.getProducts();
        const productsArray = Array.isArray(productsResponse?.data)
          ? productsResponse.data
          : Array.isArray(productsResponse)
            ? productsResponse
            : [];
        
        setProducts(productsArray);
        setFilteredProducts(productsArray);
        
        setIsLoading(false);
      } catch (error) {
        console.error('Error loading product data:', error);
        setIsLoading(false);
      }
    };
    
    loadData();
  }, []);
  
  // Update filtered products when category or search changes
  useEffect(() => {
    let filtered = [...products];
    
    // Filter by category if selected
    if (selectedCategoryId) {
      filtered = filtered.filter(product => product.category_id == selectedCategoryId);
    }
    
    // Filter by search term
    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.name.toLowerCase().includes(term) || 
        product.sku?.toLowerCase().includes(term)
      );
    }
    
    setFilteredProducts(filtered);
  }, [products, selectedCategoryId, searchTerm]);
  
  // Calculate total when selected products change
  useEffect(() => {
    const newTotal = selectedProducts.reduce((sum, sp) => {
      const product = products.find(p => p.id === sp.product_id);
      if (!product) return sum;
      
      const unitCost = product.cost_price || 0;
      return sum + (unitCost * sp.quantity);
    }, 0);
    
    setTotal(newTotal);
  }, [selectedProducts, products]);
  
  // Handle product selection
  const handleProductSelect = (productId) => {
    if (readOnly) return;
    
    // Find the product
    const product = products.find(p => p.id === productId);
    if (!product) return;
    
    // Check if already selected
    const isSelected = selectedProducts.some(sp => sp.product_id === productId);
    
    if (isSelected) {
      // If already selected, remove it
      const updated = selectedProducts.filter(sp => sp.product_id !== productId);
      onChange(updated);
    } else {
      // If not selected, add it with default quantity
      const newSelection = {
        product_id: productId,
        quantity: 1,
        // Add display properties for UI
        product_name: product.name,
        pack_qty: product.pack_size || 1,
        price_inc_vat: product.retail_price || 0,
        per_unit: product.pack_size > 0 ? (product.retail_price / product.pack_size) : 0
      };
      
      onChange([...selectedProducts, newSelection]);
    }
  };
  
  // Handle product quantity change
  const handleQuantityChange = (productId, newQuantity) => {
    if (readOnly) return;
    
    const quantity = parseFloat(newQuantity) || 0;
    if (quantity < 0) return;
    
    const updated = selectedProducts.map(sp => {
      if (sp.product_id === productId) {
        return { ...sp, quantity };
      }
      return sp;
    });
    
    onChange(updated);
  };
  
  // Format currency for display
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };
  
  // Calculate cost for a product
  const calculateCost = (productId, quantity) => {
    const product = products.find(p => p.id === productId);
    if (!product) return 0;
    
    const packSize = product.pack_size || 1;
    const retailPrice = product.retail_price || 0;
    const perUnit = packSize > 0 ? (retailPrice / packSize) : 0;
    
    return perUnit * quantity;
  };
  
  return (
    <Box>
      {/* Category and Search Filters */}
      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="category-select-label">Product Category</InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={selectedCategoryId}
            onChange={(e) => setSelectedCategoryId(e.target.value)}
            label="Product Category"
            disabled={readOnly}
          >
            <MenuItem value="">All Categories</MenuItem>
            {categories.map(category => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        
        <TextField
          label="Search Products"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          disabled={readOnly}
        />
      </Box>
      
      {/* Product Selection Table */}
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ bgcolor: 'background.paper' }}>
              <TableCell padding="checkbox">Select</TableCell>
              <TableCell>Product</TableCell>
              <TableCell align="right">Pack Qty</TableCell>
              <TableCell align="right">Price Inc VAT</TableCell>
              <TableCell align="right">Per Unit</TableCell>
              <TableCell align="right">Usage</TableCell>
              <TableCell align="right">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={7} align="center">Loading products...</TableCell>
              </TableRow>
            ) : filteredProducts.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">No products found</TableCell>
              </TableRow>
            ) : (
              filteredProducts.map(product => {
                // Find if this product is selected
                const selectedProduct = selectedProducts.find(sp => sp.product_id === product.id);
                const isSelected = !!selectedProduct;
                
                // Calculate values
                const packQty = product.pack_size || 1;
                const priceIncVat = product.retail_price || 0;
                const perUnit = packQty > 0 ? (priceIncVat / packQty) : 0;
                const quantity = selectedProduct?.quantity || 0;
                const cost = perUnit * quantity;
                
                return (
                  <TableRow 
                    key={product.id}
                    sx={{ 
                      '&:hover': { bgcolor: 'action.hover' },
                      bgcolor: isSelected ? 'action.selected' : 'inherit'
                    }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isSelected}
                        onChange={() => handleProductSelect(product.id)}
                        disabled={readOnly}
                      />
                    </TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell align="right">{packQty}</TableCell>
                    <TableCell align="right">{formatCurrency(priceIncVat)}</TableCell>
                    <TableCell align="right">{formatCurrency(perUnit)}</TableCell>
                    <TableCell align="right">
                      {isSelected && (
                        <TextField
                          type="number"
                          value={quantity}
                          onChange={(e) => handleQuantityChange(product.id, e.target.value)}
                          size="small"
                          disabled={readOnly}
                          inputProps={{ 
                            min: 0,
                            step: 0.1,
                            style: { textAlign: 'right' }
                          }}
                          sx={{ width: 80 }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {isSelected ? formatCurrency(cost) : ''}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Selected Products Summary */}
      {selectedProducts.length > 0 && (
        <Box>
          <Typography variant="h6" gutterBottom>
            Selected Products
          </Typography>
          
          <TableContainer component={Paper} sx={{ mb: 2 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Cost</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedProducts.map((sp) => {
                  const product = products.find(p => p.id === sp.product_id);
                  if (!product) return null;
                  
                  const cost = calculateCost(sp.product_id, sp.quantity);
                  
                  return (
                    <TableRow key={sp.product_id}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell align="right">{sp.quantity}</TableCell>
                      <TableCell align="right">{formatCurrency(cost)}</TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={2} align="right">
                    <Typography variant="subtitle2">Total Product Cost:</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">{formatCurrency(total)}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          
          {!readOnly && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                variant="outlined" 
                color="error" 
                onClick={() => onChange([])}
              >
                Clear All Products
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProductSelection;