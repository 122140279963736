import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  Button, 
  TextField, 
  FormControl, 
  InputLabel, 
  Select, 
  MenuItem, 
  FormControlLabel, 
  Switch, 
  Divider, 
  Grid, 
  IconButton, 
  CircularProgress, 
  Alert,
  Card,
  CardContent
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import api from './axiosConfig';

const CommissionTypeEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isNewType = !id || id === 'new';
  
  const [loading, setLoading] = useState(!isNewType);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [categories, setCategories] = useState([]);
  
  const [commissionType, setCommissionType] = useState({
    name: '',
    description: '',
    category_id: 1, // Default to sales-based
    calculation_handler: 'flat_percentage',
    is_default: false,
    parameters: []
  });
  
  const [parameters, setParameters] = useState([
    { param_name: 'service_rate', param_label: 'Service Commission Rate', param_type: 'PERCENTAGE', required: true, default_value: '10', display_order: 1 },
    { param_name: 'product_rate', param_label: 'Product Commission Rate', param_type: 'PERCENTAGE', required: true, default_value: '5', display_order: 2 }
  ]);
  
  const calculationHandlers = [
    { value: 'flat_percentage', label: 'Flat Percentage' },
    { value: 'sliding_scale', label: 'Sliding Scale' },
    { value: 'team_based', label: 'Team-Based' },
    { value: 'wage_or_commission', label: 'Higher of Wage or Commission' },
    { value: 'retention_bonus', label: 'Client Retention Bonus' },
    { value: 'wage_multiplier', label: 'Wage Multiplier (Net Takings)' },
    { value: 'top_services_average', label: 'Top Services Average' }
  ];
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch commission categories
        const categoriesResponse = await api.get('/commission/categories');
        if (categoriesResponse.data.success) {
          setCategories(categoriesResponse.data.categories || []);
        }
        
        // Fetch commission type details if editing
        if (!isNewType) {
          const typeResponse = await api.get(`/commission/types/${id}`);
          if (typeResponse.data.success) {
            const typeData = typeResponse.data.commission_type;
            
            setCommissionType({
              name: typeData.name,
              description: typeData.description,
              category_id: typeData.category_id,
              calculation_handler: typeData.calculation_handler,
              is_default: typeData.is_default
            });
            
            setParameters(typeData.parameters || []);
          } else {
            setError(typeResponse.data.error || 'Failed to load commission type');
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data: ' + (err.response?.data?.error || err.message));
        setLoading(false);
      }
    };
    
    fetchData();
  }, [id, isNewType]);
  
  // Update parameters based on calculation handler
  useEffect(() => {
    if (commissionType.calculation_handler === 'flat_percentage') {
      if (parameters.length === 0) {
        setParameters([
          { param_name: 'service_rate', param_label: 'Service Commission Rate', param_type: 'PERCENTAGE', required: true, default_value: '10', display_order: 1 },
          { param_name: 'product_rate', param_label: 'Product Commission Rate', param_type: 'PERCENTAGE', required: true, default_value: '5', display_order: 2 }
        ]);
      }
    } else if (commissionType.calculation_handler === 'sliding_scale') {
      if (!parameters.some(p => p.param_name === 'tier1_threshold')) {
        setParameters([
          { param_name: 'tier1_threshold', param_label: 'Tier 1 Threshold (£)', param_type: 'NUMBER', required: true, default_value: '1000', display_order: 1 },
          { param_name: 'tier1_rate', param_label: 'Tier 1 Rate (%)', param_type: 'PERCENTAGE', required: true, default_value: '10', display_order: 2 },
          { param_name: 'tier2_threshold', param_label: 'Tier 2 Threshold (£)', param_type: 'NUMBER', required: true, default_value: '2000', display_order: 3 },
          { param_name: 'tier2_rate', param_label: 'Tier 2 Rate (%)', param_type: 'PERCENTAGE', required: true, default_value: '15', display_order: 4 },
          { param_name: 'tier3_rate', param_label: 'Tier 3 Rate (%)', param_type: 'PERCENTAGE', required: true, default_value: '20', display_order: 5 }
        ]);
      }
    } else if (commissionType.calculation_handler === 'team_based') {
      if (!parameters.some(p => p.param_name === 'team_rate')) {
        setParameters([
          { param_name: 'team_rate', param_label: 'Team Pool Percentage', param_type: 'PERCENTAGE', required: true, default_value: '5', display_order: 1 },
          { param_name: 'contribution_type', param_label: 'Contribution Calculation', param_type: 'SELECT', required: true, default_value: 'hours', display_order: 2, options: [
            { value: 'hours', label: 'Based on Hours Worked' },
            { value: 'equal', label: 'Equal Split' }
          ]}
        ]);
      }
    } else if (commissionType.calculation_handler === 'retention_bonus') {
      if (!parameters.some(p => p.param_name === 'retention_threshold')) {
        setParameters([
          { param_name: 'retention_threshold', param_label: 'Retention Threshold (%)', param_type: 'PERCENTAGE', required: true, default_value: '70', display_order: 1 },
          { param_name: 'bonus_amount', param_label: 'Bonus Amount (£)', param_type: 'NUMBER', required: true, default_value: '100', display_order: 2 }
        ]);
      }
    } else if (commissionType.calculation_handler === 'wage_multiplier') {
      if (!parameters.some(p => p.param_name === 'net_takings_multiplier')) {
        setParameters([
          { param_name: 'net_takings_multiplier', param_label: 'Net Takings Multiplier', param_type: 'NUMBER', required: true, default_value: '1.5', display_order: 1 }
        ]);
      }
    } else if (commissionType.calculation_handler === 'top_services_average') {
      if (!parameters.some(p => p.param_name === 'top_services_count')) {
        setParameters([
          { param_name: 'top_services_count', param_label: 'Number of Top Services', param_type: 'NUMBER', required: true, default_value: '5', display_order: 1 }
        ]);
      }
    }
  }, [commissionType.calculation_handler, parameters]);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCommissionType(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setCommissionType(prev => ({
      ...prev,
      [name]: checked
    }));
  };
  
  const handleParameterChange = (index, field, value) => {
    const updatedParameters = [...parameters];
    updatedParameters[index] = {
      ...updatedParameters[index],
      [field]: value
    };
    setParameters(updatedParameters);
  };
  
  const addParameter = () => {
    const newParameter = {
      param_name: `param_${parameters.length + 1}`,
      param_label: `Parameter ${parameters.length + 1}`,
      param_type: 'TEXT',
      required: false,
      default_value: '',
      display_order: parameters.length + 1
    };
    setParameters([...parameters, newParameter]);
  };
  
  const removeParameter = (index) => {
    const updatedParameters = [...parameters];
    updatedParameters.splice(index, 1);
    setParameters(updatedParameters);
  };
  
  const handleSubmit = async () => {
    try {
      setSaving(true);
      
      const data = {
        ...commissionType,
        parameters: parameters
      };
      
      let response;
      
      if (isNewType) {
        response = await api.post('/commission/types', data);
      } else {
        response = await api.put(`/commission/types/${id}`, data);
      }
      
      if (response.data.success) {
        navigate('/commission/dashboard');
      } else {
        setError(response.data.error || 'Failed to save commission type');
        setSaving(false);
      }
    } catch (err) {
      console.error('Error saving commission type:', err);
      setError('Failed to save: ' + (err.response?.data?.error || err.message));
      setSaving(false);
    }
  };
  
  const renderParameterFields = (param, index) => {
    return (
      <Card variant="outlined" sx={{ mb: 2 }} key={index}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              <Typography variant="subtitle2" gutterBottom>
                Parameter {index + 1}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton 
                size="small" 
                color="error" 
                onClick={() => removeParameter(index)}
                disabled={parameters.length <= 1}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                label="Parameter Name"
                name="param_name"
                value={param.param_name}
                onChange={(e) => handleParameterChange(index, 'param_name', e.target.value)}
                fullWidth
                size="small"
                helperText="Unique identifier (no spaces)"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                label="Display Label"
                name="param_label"
                value={param.param_label}
                onChange={(e) => handleParameterChange(index, 'param_label', e.target.value)}
                fullWidth
                size="small"
                helperText="User-friendly label"
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth size="small">
                <InputLabel>Parameter Type</InputLabel>
                <Select
                  value={param.param_type}
                  onChange={(e) => handleParameterChange(index, 'param_type', e.target.value)}
                  label="Parameter Type"
                >
                  <MenuItem value="NUMBER">Number</MenuItem>
                  <MenuItem value="PERCENTAGE">Percentage</MenuItem>
                  <MenuItem value="TEXT">Text</MenuItem>
                  <MenuItem value="BOOLEAN">Yes/No</MenuItem>
                  <MenuItem value="SELECT">Selection</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                label="Default Value"
                name="default_value"
                value={param.default_value}
                onChange={(e) => handleParameterChange(index, 'default_value', e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            
            <Grid item xs={6}>
              <TextField
                label="Display Order"
                name="display_order"
                type="number"
                value={param.display_order}
                onChange={(e) => handleParameterChange(index, 'display_order', e.target.value)}
                fullWidth
                size="small"
              />
            </Grid>
            
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={param.required}
                    onChange={(e) => handleParameterChange(index, 'required', e.target.checked)}
                    name="required"
                  />
                }
                label="Required"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={() => navigate('/commission/dashboard')} sx={{ mr: 1 }}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4">
          {isNewType ? 'Create Commission Type' : 'Edit Commission Type'}
        </Typography>
      </Box>
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>
      )}
      
      <Paper elevation={3} sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Commission Type Name"
              name="name"
              value={commissionType.name}
              onChange={handleInputChange}
              required
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                name="category_id"
                value={commissionType.category_id}
                onChange={handleInputChange}
                label="Category"
              >
                {categories.map(category => (
                  <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Description"
              name="description"
              value={commissionType.description}
              onChange={handleInputChange}
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Calculation Method</InputLabel>
              <Select
                name="calculation_handler"
                value={commissionType.calculation_handler}
                onChange={handleInputChange}
                label="Calculation Method"
              >
                {calculationHandlers.map(handler => (
                  <MenuItem key={handler.value} value={handler.value}>{handler.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={commissionType.is_default}
                  onChange={handleSwitchChange}
                  name="is_default"
                />
              }
              label="Set as Default Commission Type"
            />
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 3 }} />
        
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6">Parameters</Typography>
          <Typography variant="body2" color="text.secondary">
            Configure the parameters for this commission type
          </Typography>
        </Box>
        
        {parameters.map((param, index) => renderParameterFields(param, index))}
        
        <Button
          startIcon={<AddIcon />}
          onClick={addParameter}
          sx={{ mb: 3 }}
        >
          Add Parameter
        </Button>
        
        <Divider sx={{ mb: 3 }} />
        
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
            disabled={saving}
          >
            {saving ? 'Saving...' : 'Save Commission Type'}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default CommissionTypeEditor;