import React, { useState } from 'react';
import {
  Box,
  Paper,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Checkbox,
  Chip,
  Switch,
  FormControlLabel,
  IconButton,
  LinearProgress,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  UploadFile as UploadFileIcon,
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils'; 

const ServicesTab = ({ 
  services, 
  serviceCategories, 
  servicesAnalysis,
  onImportClick,
  onUpdatePrices,
  setError
}) => {
  const navigate = useNavigate();
  
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [showNeedsUpdate, setShowNeedsUpdate] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [updateNotes, setUpdateNotes] = useState('');
  const [updating, setUpdating] = useState(false);
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  // Make sure services is an array
  const safeServices = Array.isArray(services) ? services : [];
  const safeServiceCategories = Array.isArray(serviceCategories) ? serviceCategories : [];
  const safeServicesAnalysis = Array.isArray(servicesAnalysis) ? servicesAnalysis : [];
  
  // Filter by services that need updates if the toggle is on
  const filteredServices = showNeedsUpdate
    ? safeServices.filter(service => {
        const analysis = safeServicesAnalysis.find(a => a?.service_id === service.id);
        return analysis?.needs_update === true;
      })
    : safeServices;
  
  // Sort and paginate services safely
  const sortedServices = [...filteredServices].sort((a, b) => {
    // Special handling for sorting by price_difference
    if (orderBy === 'price_difference') {
      const analysisA = safeServicesAnalysis.find(a => a?.service_id === a.id) || {};
      const analysisB = safeServicesAnalysis.find(a => a?.service_id === b.id) || {};
      const valueA = Math.abs(analysisA.price_difference || 0);
      const valueB = Math.abs(analysisB.price_difference || 0);
      
      return order === 'asc' ? valueA - valueB : valueB - valueA;
    }
    
    // Safe access to properties
    const aValue = a && typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : (a?.[orderBy] || 0);
    const bValue = b && typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : (b?.[orderBy] || 0);
    
    if (order === 'asc') {
      return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
    } else {
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    }
  });
  
  const paginatedServices = sortedServices.slice(
    page * rowsPerPage, 
    page * rowsPerPage + rowsPerPage
  );
  
  // Stats
  const stats = {
    totalServices: safeServices.length,
    needsUpdate: safeServicesAnalysis.filter(s => s?.needs_update).length,
    selectedCount: selectedServices.length,
    totalPriceDifference: safeServicesAnalysis
      .filter(s => s?.needs_update && selectedServices.includes(s.service_id))
      .reduce((sum, s) => sum + Math.abs(s.price_difference || 0), 0)
  };
  
  // Handle selection of services for price updates
  const handleSelectService = (serviceId) => {
    setSelectedServices(prev => {
      if (prev.includes(serviceId)) {
        return prev.filter(id => id !== serviceId);
      } else {
        return [...prev, serviceId];
      }
    });
  };
  
  // Select all services on current page
  const handleSelectAllOnPage = (event) => {
    if (event.target.checked) {
      // Get ids of services that need updates on current page
      const pageServiceIds = paginatedServices
        .filter(service => {
          const analysis = safeServicesAnalysis.find(a => a?.service_id === service.id);
          return analysis?.needs_update;
        })
        .map(service => service.id);
        
      // Add these ids to the selected services (if not already selected)
      setSelectedServices(prev => {
        const newSelected = [...prev];
        pageServiceIds.forEach(id => {
          if (!newSelected.includes(id)) {
            newSelected.push(id);
          }
        });
        return newSelected;
      });
    } else {
      // Remove the ids of current page services from selection
      setSelectedServices(prev => 
        prev.filter(id => !paginatedServices.some(service => service.id === id))
      );
    }
  };
  
  // Dialog to confirm price updates
  const handleOpenConfirmDialog = () => {
    if (selectedServices.length === 0) {
      if (setError) {
        setError('Please select at least one service to update.');
      }
      return;
    }
    setConfirmDialogOpen(true);
  };
  
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };
  
  // Apply price updates to services
  const handleApplyPriceUpdates = async () => {
    if (selectedServices.length === 0) {
      if (setError) {
        setError('Please select at least one service to update.');
      }
      return;
    }
    
    try {
      setUpdating(true);
      
      // Prepare services data for update
      const servicesToUpdate = selectedServices.map(serviceId => {
        const service = safeServices.find(s => s.id === serviceId);
        const analysis = safeServicesAnalysis.find(a => a?.service_id === serviceId);
        
        return {
          id: serviceId,
          name: service?.name,
          current_price: service?.current_price,
          new_price: analysis?.recommended_price_level1
        };
      });
      
      // Call parent function to update prices
      if (onUpdatePrices) {
        await onUpdatePrices(servicesToUpdate, updateNotes);
      }
      
      // Clear selection and close dialog
      setSelectedServices([]);
      setUpdateNotes('');
      setConfirmDialogOpen(false);
      setUpdating(false);
    } catch (err) {
      console.error('Error updating prices:', err);
      if (setError) {
        setError(err.message || 'Failed to update service prices. Please try again.');
      }
      setUpdating(false);
    }
  };
  
  // Format currency for display
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', { 
      style: 'currency', 
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };
  
  return (
    <Box>
      {/* Price Update Summary Cards */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Services Needing Updates</Typography>
                <Typography variant="h4">{stats.needsUpdate}</Typography>
                <Typography variant="caption" color="text.secondary">
                  of {stats.totalServices} total services
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.needsUpdate / Math.max(stats.totalServices, 1)) * 100} 
                  color={stats.needsUpdate > 0 ? "warning" : "success"}
                  sx={{ mt: 2, height: 6, borderRadius: 3 }}
                />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Selected for Update</Typography>
                <Typography variant="h4">{stats.selectedCount}</Typography>
                <Typography variant="caption" color="text.secondary">
                  services ready to update
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={(stats.selectedCount / Math.max(stats.needsUpdate, 1)) * 100} 
                  color="primary"
                  sx={{ mt: 2, height: 6, borderRadius: 3 }}
                />
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent>
                <Typography variant="subtitle1" gutterBottom>Revenue Impact</Typography>
                <Typography variant="h4">{formatCurrency(stats.totalPriceDifference)}</Typography>
                <Typography variant="caption" color="text.secondary">
                  from selected services
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} sm={6} md={3}>
            <Card>
              <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Button 
                  variant="contained" 
                  color="primary" 
                  fullWidth
                  disabled={selectedServices.length === 0 || updating}
                  onClick={handleOpenConfirmDialog}
                  sx={{ py: 1.5 }}
                >
                  {updating ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
                  Apply Price Updates
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      
      {/* Services Table with Selection */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Services</Typography>
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={showNeedsUpdate}
                onChange={(e) => setShowNeedsUpdate(e.target.checked)}
                color="primary"
              />
            }
            label="Show only services needing updates"
          />
          
          <Button 
            variant="contained" 
            color="primary" 
            startIcon={<AddIcon />} 
            sx={{ ml: 2, mr: 1 }}
            onClick={() => navigate('/calculator/services/new')}
          >
            Add Service
          </Button>
          <Button 
            variant="outlined" 
            startIcon={<UploadFileIcon />}
            onClick={onImportClick}
          >
            Import Price List
          </Button>
        </Box>
      </Box>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedServices.length > 0 && selectedServices.length < paginatedServices.length}
                  checked={paginatedServices.length > 0 && paginatedServices.every(service => {
                    const analysis = safeServicesAnalysis.find(a => a?.service_id === service.id);
                    return !analysis?.needs_update || selectedServices.includes(service.id);
                  })}
                  onChange={handleSelectAllOnPage}
                />
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={() => handleRequestSort('name')}
                >
                  Name
                </TableSortLabel>
              </TableCell>
              <TableCell>Category</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'duration'}
                  direction={orderBy === 'duration' ? order : 'asc'}
                  onClick={() => handleRequestSort('duration')}
                >
                  Duration
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'current_price'}
                  direction={orderBy === 'current_price' ? order : 'asc'}
                  onClick={() => handleRequestSort('current_price')}
                >
                  Current Price
                </TableSortLabel>
              </TableCell>
              <TableCell>Recommended Price</TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'price_difference'}
                  direction={orderBy === 'price_difference' ? order : 'asc'}
                  onClick={() => handleRequestSort('price_difference')}
                >
                  Difference
                </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedServices.length > 0 ? (
              paginatedServices.map((service) => {
                if (!service) return null;
                
                const analysis = safeServicesAnalysis.find(a => a?.service_id === service.id) || {};
                const category = safeServiceCategories.find(c => c?.id === service.category_id);
                const priceDifference = analysis?.price_difference || 0;
                const needsUpdate = analysis?.needs_update || false;
                
                return (
                  <TableRow key={service.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedServices.includes(service.id)}
                        onChange={() => handleSelectService(service.id)}
                        disabled={!needsUpdate}
                      />
                    </TableCell>
                    <TableCell>{service.name || 'Unnamed Service'}</TableCell>
                    <TableCell>{category?.name || 'Uncategorized'}</TableCell>
                    <TableCell>{service.duration || 0} min</TableCell>
                    <TableCell>{formatCurrency(service.current_price)}</TableCell>
                    <TableCell>{formatCurrency(analysis.recommended_price_level1)}</TableCell>
                    <TableCell 
                      sx={{ 
                        color: priceDifference < 0 ? 'error.main' : 'success.main',
                        fontWeight: 'bold'
                      }}
                    >
                      {priceDifference < 0 ? (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />
                          {formatCurrency(Math.abs(priceDifference))}
                        </Box>
                      ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                          {formatCurrency(priceDifference)}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>
                      <Chip 
                        label={needsUpdate ? "Update Needed" : "OK"} 
                        color={needsUpdate ? "error" : "success"} 
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          if (service.id) {
                            navigate(`/services/${service.id}`);
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {showNeedsUpdate 
                    ? "No services need price updates at this time."
                    : "No services found. Add services to get started."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedServices.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      
      {/* Price Update Confirmation Dialog */}
      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Confirm Price Updates</DialogTitle>
        <DialogContent>
          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="body2">
              You are about to update prices for {selectedServices.length} services. This action will immediately
              change the prices charged to your customers. Please review the changes before confirming.
            </Typography>
          </Alert>
          
          <Typography variant="subtitle1" gutterBottom>
            Services to update:
          </Typography>
          
          <TableContainer sx={{ maxHeight: 300 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>Current Price</TableCell>
                  <TableCell>New Price</TableCell>
                  <TableCell>Difference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedServices.map(serviceId => {
                  const service = safeServices.find(s => s.id === serviceId);
                  const analysis = safeServicesAnalysis.find(a => a?.service_id === serviceId);
                  
                  if (!service || !analysis) return null;
                  
                  const priceDifference = analysis.price_difference || 0;
                  
                  return (
                    <TableRow key={serviceId}>
                      <TableCell>{service.name}</TableCell>
                      <TableCell>{formatCurrency(service.current_price)}</TableCell>
                      <TableCell>{formatCurrency(analysis.recommended_price_level1)}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', color: 'error.main' }}>
                          <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} />
                          {formatCurrency(Math.abs(priceDifference))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          
          <TextField
            margin="normal"
            fullWidth
            multiline
            rows={3}
            label="Update Notes (optional)"
            placeholder="Add a note about why prices are being updated. This will be saved in your price update history."
            value={updateNotes}
            onChange={(e) => setUpdateNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog}>
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleApplyPriceUpdates}
            disabled={updating}
          >
            {updating ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
            Confirm Price Updates
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServicesTab;