import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, Link } from 'react-router-dom';
import api from './axiosConfig';
import { Box, Paper, Typography, CircularProgress, Alert, Grid, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Edit } from '@mui/icons-material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Divider, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@mui/material';
import DatePickerComponent from './DatePickerComponent';
import { Bar } from 'react-chartjs-2';
import { useAuth } from './AuthContext';
import dayjs from 'dayjs';
import GaugeChart from 'react-gauge-chart';
import KPIBox from './KPIBox';
import { Chart, LinearScale, CategoryScale, BarController, BarElement } from 'chart.js';

Chart.register(LinearScale, CategoryScale, BarController, BarElement);

// Company Profile Section Component
const CompanyProfileSection = ({ vatSummary }) => {
  const { user } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    business_name: '',
    business_address_line1: '',
    business_address_line2: '',
    business_address_line3: '',
    postcode: '',
    phone_number: '',
    is_vat_registered: false,
    vat_number: '',
    vat_scheme: '',
    holiday_year_start_month: 1,
    holiday_year_start_day: 1,
    total_holiday_days: 28
  });
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const vatSchemes = [
    { value: 'STANDARD_RATE', label: 'Standard Rate (20%)' },
    { value: 'FLAT_RATE', label: 'Flat Rate Scheme' },
    { value: 'FIRST_YEAR_FLAT_RATE', label: 'First Year Flat Rate' }
  ];

  const months = [
    { value: 1, label: 'January' },
    { value: 2, label: 'February' },
    { value: 3, label: 'March' },
    { value: 4, label: 'April' },
    { value: 5, label: 'May' },
    { value: 6, label: 'June' },
    { value: 7, label: 'July' },
    { value: 8, label: 'August' },
    { value: 9, label: 'September' },
    { value: 10, label: 'October' },
    { value: 11, label: 'November' },
    { value: 12, label: 'December' }
  ];

  useEffect(() => {
    const fetchProfile = async () => {
  try {
    console.log("Fetching company profile");

    // Add cache busting parameter and additional headers
    const response = await api.get('/company/profile', {
      params: { v: new Date().getTime() },
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    });
    console.log("Company profile response:", response.data);

    setProfileData(response.data);
  } catch (error) {
    console.error('Error fetching company profile:', error);
    setError('Failed to load company profile');
  }
};
    
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (name === 'holiday_year_start_month' || 
        name === 'holiday_year_start_day' || 
        name === 'total_holiday_days') {
      const numValue = parseInt(value);
      if (name === 'holiday_year_start_day' && (numValue < 1 || numValue > 31)) {
        return;
      }
      setProfileData(prev => ({
        ...prev,
        [name]: numValue
      }));
      return;
    }

    setProfileData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
      ...(name === 'is_vat_registered' && !checked ? {
        vat_scheme: '',
        vat_number: ''
      } : {})
    }));
  };

  const handleSubmit = async () => {
    try {
      await api.put('/company/profile', {
        ...profileData,
        password: confirmPassword
      });
      setIsEditing(false);
      setConfirmPassword('');
      setError('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    }
  };

  // Format VAT information to be displayed in the Company Profile
  const formatVatInfo = () => {
    if (!vatSummary || !vatSummary.data) return null;
    
    // Extract from the data property since /status returns {success: true, data: {...}}
    const vatData = vatSummary.data;
    
    return {
      nextReturnDue: vatData.next_return_date || 'Not available',
      currentPosition: vatData.current_position || 0,
      status: vatData.is_registered ? 'Registered' : 'Not Registered',
      number: vatData.vat_number || profileData.vat_number || 'Not specified',
      scheme: vatData.vat_scheme || profileData.vat_scheme || 'Not specified'
    };
  };

  const vatInfo = formatVatInfo();

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Company Profile</Typography>
        <Button
          variant="contained"
          onClick={() => setIsEditing(true)}
          startIcon={<Edit/>}
        >
          Edit Profile
        </Button>
      </Box>

      {/* Display Mode */}
      {!isEditing && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="text.secondary">Business Information</Typography>
            <Typography><strong>Business Name:</strong> {profileData.business_name}</Typography>
            <Typography><strong>Phone:</strong> {profileData.phone_number}</Typography>
            <Typography component="div" sx={{ mt: 1 }}>
              <strong>Address:</strong><br />
              {profileData.business_address_line1}<br />
              {profileData.business_address_line2 && <>{profileData.business_address_line2}<br /></>}
              {profileData.business_address_line3 && <>{profileData.business_address_line3}<br /></>}
              {profileData.postcode}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" color="text.secondary">VAT Information</Typography>
            <Typography><strong>VAT Registered:</strong> {profileData.is_vat_registered ? 'Yes' : 'No'}</Typography>
            {profileData.is_vat_registered && (
              <>
                <Typography><strong>VAT Number:</strong> {profileData.vat_number || 'Not specified'}</Typography>
                <Typography><strong>VAT Scheme:</strong> {profileData.vat_scheme}</Typography>
                {vatInfo && (
                  <>
                    <Typography sx={{ mt: 1 }}><strong>Next Return Due:</strong> {vatInfo.nextReturnDue}</Typography>
                    <Typography><strong>Current VAT Position:</strong> £{vatInfo.currentPosition.toFixed(2)}</Typography>
                    <Box sx={{ mt: 1 }}>
                      <Button 
                        variant="outlined" 
                        size="small"
                        component={Link}
                        to="/vat_dashboard"
                      >
                        View VAT Dashboard
                      </Button>
                    </Box>
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="text.secondary">Holiday Year Settings</Typography>
            <Typography>
              <strong>Holiday Year Starts:</strong> {months.find(m => m.value === profileData.holiday_year_start_month)?.label} {profileData.holiday_year_start_day}
            </Typography>
            <Typography><strong>Total Holiday Days:</strong> {profileData.total_holiday_days}</Typography>
          </Grid>
        </Grid>
      )}

      {/* Edit Dialog */}
      <Dialog 
        open={isEditing} 
        onClose={() => setIsEditing(false)} 
        maxWidth="md" 
        fullWidth
      >
        <DialogTitle>
          Edit Company Profile
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            {/* Business Information Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Business Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Business Name"
                    name="business_name"
                    value={profileData.business_name}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address Line 1"
                    name="business_address_line1"
                    value={profileData.business_address_line1}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address Line 2"
                    name="business_address_line2"
                    value={profileData.business_address_line2}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address Line 3"
                    name="business_address_line3"
                    value={profileData.business_address_line3}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    name="postcode"
                    value={profileData.postcode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone_number"
                    value={profileData.phone_number}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Holiday Year Settings Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Holiday Year Settings
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel>Holiday Year Start Month</InputLabel>
                    <Select
                      name="holiday_year_start_month"
                      value={profileData.holiday_year_start_month}
                      onChange={handleChange}
                      label="Holiday Year Start Month"
                    >
                      {months.map(month => (
                        <MenuItem key={month.value} value={month.value}>
                          {month.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    name="holiday_year_start_day"
                    label="Start Day of Month"
                    value={profileData.holiday_year_start_day}
                    onChange={handleChange}
                    inputProps={{ min: 1, max: 31 }}
                    helperText="Enter a day (1-31)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    type="number"
                    name="total_holiday_days"
                    label="Total Holiday Days"
                    value={profileData.total_holiday_days}
                    onChange={handleChange}
                    inputProps={{ min: 0 }}
                    helperText="Default: 28 days"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* VAT Information Section */}
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                VAT Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_vat_registered"
                        checked={profileData.is_vat_registered}
                        onChange={handleChange}
                      />
                    }
                    label="Business is VAT Registered"
                  />
                </Grid>
                {profileData.is_vat_registered && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="VAT Number"
                        name="vat_number"
                        value={profileData.vat_number}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel>VAT Scheme</InputLabel>
                        <Select
                          name="vat_scheme"
                          value={profileData.vat_scheme}
                          onChange={handleChange}
                          label="VAT Scheme"
                        >
                          {vatSchemes.map(scheme => (
                            <MenuItem key={scheme.value} value={scheme.value}>
                              {scheme.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>

            {/* Password Confirmation */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                helperText="Please enter your password to save changes"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditing(false)}>Cancel</Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={!confirmPassword}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

// Format title function
const formatTitle = (key) => {
  // Special formatting for known keys
  const specialCases = {
    'clientCount': 'Client Count',
    'customerCount': 'Client Count',  // Handle both keys during transition
    'averageTicket': 'Average Ticket',
    'totalExpenditure': 'Total Expenditure',
    'inputVat': 'Input VAT',
    'outputVat': 'Output VAT',
    'vatDifference': 'VAT Difference',
    'retailSales': 'Retail Sales',
    'serviceSales': 'Service Sales',
    'totalCosts': 'Total Costs',
    'employerNI': 'Employer NI',
    'pendingRequests': 'Pending Requests',
    'wages': 'Wages',
    'pension': 'Pension',
    'reminders': 'Reminders'
  };
  
  if (specialCases[key]) return specialCases[key];
  
  // Default formatting for other keys
  return key
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, str => str.toUpperCase());
};

// Gauge Metric Component
const GaugeMetric = ({ title, data, isInverse = false }) => {
  // Only render the gauge if we have valid data
  if (!data || typeof data.value !== 'number') {
    return null;
  }

  const getColors = () => isInverse ? 
    ["#FF0000", "#FFA500", "#00FF00"] : 
    ["#00FF00", "#FFA500", "#FF0000"];

  const getValue = () => {
    const val = Math.min(Math.max(data.value, 0), 100) / 100;
    return isInverse ? 1 - val : val;
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Typography variant="subtitle1" gutterBottom>{title}</Typography>
      <GaugeChart
        id={`gauge-${title.toLowerCase().replace(/\s+/g, '-')}`}
        nrOfLevels={20}
        colors={getColors()}
        arcWidth={0.3}
        percent={getValue()}
        textColor="#000000"
        formatTextValue={() => `${data.value.toFixed(1)}%`}
      />
      <Typography variant="caption" color="textSecondary">
        Target: {isInverse ? 'Below' : 'Above'} {data.target}%
      </Typography>
    </Box>
  );
};

// Main Dashboard Component
const OwnerDashboard = () => {
  const { isAuthenticated, user } = useAuth();
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'year').startOf('day'));
  const [endDate, setEndDate] = useState(dayjs().endOf('day'));
  const [loadingAccount, setLoadingAccount] = useState(false);

  // Calculate metrics for the gauge charts
  const calculateMetrics = (kpiData, revenueData) => {
    // Only calculate if we have the required data
    if (!kpiData || !revenueData) return {};
    
    const metrics = {};
    
    // Wages to Turnover calculation
    if (kpiData.wages && kpiData.employerNI && kpiData.pension && revenueData.annualTotal) {
      const wages = typeof kpiData.wages.value === 'number' ? kpiData.wages.value : 0;
      const employerNI = typeof kpiData.employerNI.value === 'number' ? kpiData.employerNI.value : 0;
      const employerPension = typeof kpiData.pension.value === 'number' ? kpiData.pension.value : 0;
      const totalWageCost = wages + employerNI + employerPension;
      const annualRevenue = revenueData.annualTotal || 0;
      
      if (annualRevenue > 0) {
        metrics.wagesToTurnover = {
          value: (totalWageCost / annualRevenue) * 100,
          target: 35,
          warning: 50
        };
      }
    }
    
    // Retail to Service calculation
    if (kpiData.retailSales && kpiData.serviceSales) {
      const retailSales = typeof kpiData.retailSales.value === 'number' ? kpiData.retailSales.value : 0;
      const serviceSales = typeof kpiData.serviceSales.value === 'number' ? kpiData.serviceSales.value : 0;
      
      if (serviceSales > 0) {
        metrics.retailToService = {
          value: (retailSales / serviceSales) * 100,
          target: 20,
          warning: 10
        };
      }
    }
    
    // Operating Profit calculation
    if (kpiData.totalCosts && revenueData.annualTotal) {
      const totalCosts = typeof kpiData.totalCosts.value === 'number' ? kpiData.totalCosts.value : 0;
      const annualRevenue = revenueData.annualTotal || 0;
      
      if (annualRevenue > 0) {
        metrics.operatingProfit = {
          value: ((annualRevenue - totalCosts) / annualRevenue) * 100,
          target: 15,
          warning: 8
        };
      }
    }
    
    return metrics;
  };

  const fetchDashboardData = useCallback(async () => {
    if (!isAuthenticated || user?.role !== 'ACCOUNT_OWNER') {
      console.warn('User not authenticated or not an account owner');
      return;
    }
    
    setLoading(true);
    setError(null);
    
    // Add timestamp to force cache refresh
    const timestamp = new Date().getTime();
    
    try {
      // Fetch all the data in parallel
      const [kpiResponse, revenueResponse, requestsResponse, vatResponse] = 
        await Promise.all([
          api.get('/dashboard/kpi_data', { 
            params: {
              start_date: startDate.format('YYYY-MM-DD'), 
              end_date: endDate.format('YYYY-MM-DD'),
              v: timestamp
            }
          }),
          api.get('/dashboard/revenue_data', { 
            params: {
              start_date: startDate.format('YYYY-MM-DD'), 
              end_date: endDate.format('YYYY-MM-DD'),
              v: timestamp
            }
          }),
          api.get('/dashboard/pending_requests', {
            params: {
              v: timestamp
            }
          }),
          api.get('/vat/status', {
            params: {
              v: timestamp
            }
          })
        ]);
      
      setDashboardData({
        kpiData: kpiResponse.data,
        revenueData: revenueResponse.data,
        pendingRequests: requestsResponse.data.pending_requests || 0,
        vatData: vatResponse.data
      });
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
      setError('Failed to load dashboard data. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate, user, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && user?.role === 'ACCOUNT_OWNER') {
      fetchDashboardData();
    }
  }, [isAuthenticated, user, fetchDashboardData]);

  if (!isAuthenticated || user?.role !== 'ACCOUNT_OWNER') {
    return <Navigate to="/unauthorized" replace />;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading dashboard data...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
        <Button onClick={fetchDashboardData} variant="contained" sx={{ mt: 2 }}>Retry</Button>
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No dashboard data available.</Alert>
        <Button onClick={fetchDashboardData} variant="contained" sx={{ mt: 2 }}>Refresh Data</Button>
      </Box>
    );
  }

  // Ensure we have all required data objects, using empty objects as fallbacks
  const { 
    kpiData = {}, 
    revenueData = { monthlyData: [], annualTotal: 0 }, 
    pendingRequests = 0, 
    vatData 
  } = dashboardData;
  
  // Calculate metrics for gauge charts
  const kpiMetrics = calculateMetrics(kpiData, revenueData);

  // Create bar chart data
  const barChartData = {
    labels: revenueData.monthlyData?.map(item => item.month) || [],
    datasets: [{
      label: 'Monthly Revenue',
      data: revenueData.monthlyData?.map(item => item.total) || [],
      backgroundColor: 'rgba(75,192,192,1)',
    }]
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Revenue (£)'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };
  
  return (
    <Box className="dashboard-container" sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Account Owner Dashboard
      </Typography>
      
      {/* Company Profile Section with VAT Information */}
      <CompanyProfileSection vatSummary={vatData} />
      
      {/* Date Range Selector */}
      <DatePickerComponent 
        startDate={startDate} 
        endDate={endDate} 
        setStartDate={setStartDate} 
        setEndDate={setEndDate} 
      />
      
      <Button 
        onClick={fetchDashboardData} 
        variant="contained"
        sx={{ mt: 2, mb: 2 }}
      >
        Refresh Data
      </Button>
      
      {/* KPI Gauge Charts - only show if we have the data */}
      {(kpiMetrics.wagesToTurnover || kpiMetrics.retailToService || kpiMetrics.operatingProfit) && (
        <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 4, flexWrap: 'wrap' }}>
          {kpiMetrics.wagesToTurnover && (
            <GaugeMetric 
              title="Wages to Turnover" 
              data={kpiMetrics.wagesToTurnover} 
              isInverse={true} 
            />
          )}
          {kpiMetrics.retailToService && (
            <GaugeMetric 
              title="Retail to Service Sales" 
              data={kpiMetrics.retailToService} 
            />
          )}
          {kpiMetrics.operatingProfit && (
            <GaugeMetric 
              title="Operating Profit Margin" 
              data={kpiMetrics.operatingProfit} 
            />
          )}
        </Box>
      )}

      {/* Monthly Revenue Chart - only show if we have data */}
      {revenueData.monthlyData && revenueData.monthlyData.length > 0 && (
        <Paper elevation={3} sx={{ p: 2, mb: 4, height: 400 }}>
          <Typography variant="h6" gutterBottom>Monthly Net Turnover</Typography>
          <Bar data={barChartData} options={barOptions} />
        </Paper>
      )}

      {/* KPI Grid - only show KPIs that have data */}
      <Grid container spacing={3} sx={{ mt: 5 }}>
        {Object.entries(kpiData).map(([key, data]) => {
          // Skip if we don't have a value
          if (!data || typeof data.value !== 'number') {
            return null;
          }
          
          // Handle both customerCount and clientCount during transition
          let displayKey = key;
          if (key === 'customerCount' || key === 'clientCount') {
            displayKey = 'clientCount';  // Normalize to the new key name
          }
          
          return (
            <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
              <KPIBox 
                title={formatTitle(displayKey)}
                value={data.value}
                growth={data.growth}
                prefix={['totalExpenditure', 'inputVat', 'outputVat', 'vatDifference', 'averageTicket', 'wages', 'employerNI', 'pension', 'retailSales', 'serviceSales', 'totalCosts'].includes(key) ? '£' : ''}
                isWholeNumber={['reminders', 'clientCount', 'customerCount', 'pendingRequests'].includes(key)}
              />
            </Grid>
          );
        })}
        {pendingRequests > 0 && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <KPIBox 
              title="Pending Holiday Requests" 
              value={pendingRequests} 
              growth={0}
              isWholeNumber={true}
            />
          </Grid>
        )}
      </Grid>
      
      {/* Link to VAT Dashboard */}
      {vatData && vatData.data && vatData.data.is_registered && (
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button 
            variant="contained" 
            color="primary"
            component={Link}
            to="/vat_dashboard"
            startIcon={<ReceiptIcon />}
          >
            View VAT Dashboard
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default OwnerDashboard;