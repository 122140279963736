import React from 'react';
import { Grid, Card, CardHeader, CardContent, Typography, Box, Chip, CircularProgress, LinearProgress, IconButton, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';
import {
  TrendingUp as TrendingUpIcon,
  Warning as WarningIcon,
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TargetHourlyRate from './TargetHourlyRate';

const OverviewTab = ({ businessSettings, revenueStaff, expenses, servicesAnalysis, services, setCurrentService }) => {
  const navigate = useNavigate();
  
  // Safely calculate totalExpenses using the appropriate format
  let totalExpenses = 0;
  
  // Check if expenses is in the new format (object with categoryBreakdown)
  if (expenses && expenses.categoryBreakdown) {
    // Sum all values in the categoryBreakdown
    totalExpenses = Object.values(expenses.categoryBreakdown)
      .reduce((sum, value) => sum + (value || 0), 0);
    
    console.log("Overview tab using new expense format, total:", totalExpenses);
  } else if (Array.isArray(expenses)) {
    // Use the old format (array of expense objects)
    totalExpenses = expenses.reduce((sum, exp) => sum + (exp?.total || 0), 0);
    console.log("Overview tab using array expense format, total:", totalExpenses);
  }
  
  // Safely calculate avgHourlyRate
  const avgHourlyRate = Array.isArray(revenueStaff) && revenueStaff.length > 0
    ? revenueStaff.reduce((sum, staff) => sum + (staff?.hourly_rate || 0), 0) / revenueStaff.length
    : 0;
  
  // Safely calculate services that need price updates
  const servicesNeedingUpdate = Array.isArray(servicesAnalysis)
    ? servicesAnalysis.filter(s => s?.needs_update)
    : [];
  
  const percentageNeedingUpdate = Array.isArray(services) && services.length > 0
    ? (servicesNeedingUpdate.length / services.length) * 100
    : 0;
  
  // Safely calculate potential revenue impact
  const totalRevenueLoss = Array.isArray(servicesNeedingUpdate)
    ? servicesNeedingUpdate.reduce((total, service) => 
        total + Math.abs(service?.price_difference || 0), 0)
    : 0;
  
  // Get expense categories count safely
  let uniqueCategories = [];
  if (expenses && expenses.categoryBreakdown) {
    // New format - get categories from the object keys
    uniqueCategories = Object.keys(expenses.categoryBreakdown).filter(
      cat => expenses.categoryBreakdown[cat] > 0
    );
  } else if (Array.isArray(expenses)) {
    // Old format - get categories from array items
    uniqueCategories = [...new Set(expenses.map(e => e?.category).filter(Boolean))];
  }
  
  // Safe access to business settings
  const settings = businessSettings || {};
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Business Summary" />
          <CardContent>
            <Typography variant="body1">VAT Rate: {settings.vat_rate || 0}%</Typography>
            <Typography variant="body1">Weekly Trading Hours: {settings.total_trading_hours || 0} hours</Typography>
            <Typography variant="body1">Target Profit: {settings.profit_level_1 || 0}% - {settings.profit_level_2 || 0}%</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Staff" />
          <CardContent>
            <Typography variant="body1">Revenue-generating Staff: {Array.isArray(revenueStaff) ? revenueStaff.length : 0}</Typography>
            <Typography variant="body1">Average Hourly Rate: £{avgHourlyRate.toFixed(2)}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Expenses" />
          <CardContent>
            <Typography variant="body1">Total Expenses: £{totalExpenses.toFixed(2)}</Typography>
            <Typography variant="body1">Categories: {uniqueCategories.length}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <TargetHourlyRate 
          businessSettings={businessSettings} 
          expenses={expenses} 
          servicesAnalysis={servicesAnalysis}
        />
      </Grid>
      {/* Price Monitoring Cards */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Price Monitoring
        </Typography>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Services Needing Updates" />
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress 
                  variant="determinate" 
                  value={percentageNeedingUpdate} 
                  size={80}
                  thickness={6}
                  color={percentageNeedingUpdate > 30 ? "error" : percentageNeedingUpdate > 15 ? "warning" : "success"}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(percentageNeedingUpdate)}%`}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="h4">
                  {servicesNeedingUpdate.length}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  of {Array.isArray(services) ? services.length : 0} services
                </Typography>
              </Box>
            </Box>
            
            <Box sx={{ mt: 2 }}>
              <Chip 
                icon={
                  percentageNeedingUpdate > 30 ? <WarningIcon /> : 
                  percentageNeedingUpdate > 15 ? <InfoIcon /> : 
                  <CheckCircleIcon />
                }
                label={
                  percentageNeedingUpdate > 30 ? "Critical - Many services underpriced" : 
                  percentageNeedingUpdate > 15 ? "Warning - Several services need updates" : 
                  "Good - Most services priced correctly"
                }
                color={
                  percentageNeedingUpdate > 30 ? "error" : 
                  percentageNeedingUpdate > 15 ? "warning" : 
                  "success"
                }
                sx={{ width: '100%' }}
              />
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Potential Revenue Impact" />
          <CardContent>
            <Typography variant="h4" color={totalRevenueLoss > 0 ? "error.main" : "inherit"}>
              {formatCurrency(totalRevenueLoss)}
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Estimated monthly revenue gap
            </Typography>
            
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2">
                This is the estimated additional revenue you could generate by updating your service prices to match recommended levels, based on your current costs and desired profit margins.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      
      <Grid item xs={12} md={4}>
        <Card>
          <CardHeader title="Top Services to Update" />
          <CardContent>
            {Array.isArray(servicesNeedingUpdate) && servicesNeedingUpdate.length > 0 ? (
              servicesNeedingUpdate
                .sort((a, b) => Math.abs(b?.price_difference || 0) - Math.abs(a?.price_difference || 0))
                .slice(0, 3)
                .map((service, index) => (
                  <Box 
                    key={service?.service_id || index} 
                    sx={{ 
                      mb: 2, 
                      p: 1.5, 
                      bgcolor: 'background.default',
                      borderRadius: 1
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle2" noWrap sx={{ maxWidth: '70%' }}>
                        {service?.service_name || 'Unnamed Service'}
                      </Typography>
                      <IconButton 
                        size="small" 
                        onClick={() => {
                          // Handle editing the service
                          if (Array.isArray(services) && service?.service_id) {
                            const serviceObj = services.find(s => s?.id === service.service_id);
                            if (serviceObj) {
                              setCurrentService(serviceObj);
                              navigate(`/services/${service.service_id}`);
                            }
                          }
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Box>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      <Box>
                        <Typography variant="caption" color="text.secondary">
                          Current
                        </Typography>
                        <Typography variant="body2">
                          {formatCurrency(service?.current_price || 0)}
                        </Typography>
                      </Box>
                      <Box textAlign="center">
                        <TrendingUpIcon color="error" fontSize="small" />
                      </Box>
                      <Box textAlign="right">
                        <Typography variant="caption" color="text.secondary">
                          Recommended
                        </Typography>
                        <Typography variant="body2">
                          {formatCurrency(service?.recommended_price_level1 || 0)}
                        </Typography>
                      </Box>
                    </Box>
                    
                    <Box sx={{ mt: 1 }}>
                      <LinearProgress 
                        variant="determinate" 
                        value={service?.current_price && service?.recommended_price_level1 ?
                          Math.min(100, (service.current_price / service.recommended_price_level1) * 100) : 0} 
                        color="error"
                        sx={{ height: 8, borderRadius: 4 }}
                      />
                    </Box>
                  </Box>
                ))
            ) : (
              <Typography variant="body2" color="text.secondary" align="center">
                All services are priced correctly!
              </Typography>
            )}
            
            {Array.isArray(servicesNeedingUpdate) && servicesNeedingUpdate.length > 0 && (
              <Button 
                variant="contained" 
                fullWidth 
                onClick={() => navigate('/services')} // Navigate to services tab/page
                sx={{ mt: 1 }}
              >
                View All Services
              </Button>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default OverviewTab;