import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Box,
  Alert,
  CircularProgress
} from '@mui/material';
import stockApi from './stockApi';

function ServiceProductManager({ serviceId }) {
  // State for products and service products
  const [products, setProducts] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [costSummary, setCostSummary] = useState({
    total_cost: 0,
    required_cost: 0,
    optional_cost: 0
  });
  
  // State for form
  const [formData, setFormData] = useState({
    service_id: serviceId,
    product_id: '',
    quantity: 0.01,
    is_optional: false,
    is_retail: false
  });
  
  // State for editing
  const [editingId, setEditingId] = useState(null);
  
  // Load products and service products when component mounts or serviceId changes
  useEffect(() => {
    if (!serviceId) {
      setError('No service selected');
      setLoading(false);
      return;
    }
    
    const loadData = async () => {
      try {
        setLoading(true);
        setFormData({ ...formData, service_id: serviceId });
        
        // Load all products
        const productsResponse = await stockApi.getProducts();
        setProducts(productsResponse.data || []);
        
        // Load service products
        await fetchServiceProducts();
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    loadData();
  }, [serviceId]);
  
  // Fetch service products and calculate costs
  const fetchServiceProducts = async () => {
    try {
      // Get service products
      const response = await stockApi.getServiceProducts(serviceId);
      setServiceProducts(response.data || []);
      
      // Calculate service cost
      const costResponse = await stockApi.calculateServiceCost(serviceId);
      setCostSummary({
        total_cost: costResponse.data.total_cost || 0,
        required_cost: costResponse.data.required_cost || 0,
        optional_cost: costResponse.data.optional_cost || 0
      });
    } catch (err) {
      console.error('Error fetching service products:', err);
      setError(err.message);
    }
  };
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    setFormData({
      ...formData,
      [name]: name === 'is_optional' || name === 'is_retail' ? checked : 
              (name === 'quantity' || name === 'product_id') ? value : value
    });
  };
  
  // Start editing a service product
  const handleEdit = (serviceProduct) => {
    setEditingId(serviceProduct.id);
    setFormData({
      service_id: serviceId,
      product_id: serviceProduct.product_id,
      quantity: serviceProduct.quantity,
      is_optional: serviceProduct.is_optional,
      is_retail: serviceProduct.is_retail
    });
  };
  
  // Cancel editing
  const handleCancel = () => {
    setEditingId(null);
    setFormData({
      service_id: serviceId,
      product_id: '',
      quantity: 0.01,
      is_optional: false,
      is_retail: false
    });
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (editingId) {
        // Update existing mapping
        await stockApi.updateServiceProduct(editingId, {
          quantity: formData.quantity,
          is_optional: formData.is_optional,
          is_retail: formData.is_retail
        });
      } else {
        // Create new mapping
        await stockApi.addProductToService(formData);
      }
      
      // Refresh service products and costs
      await fetchServiceProducts();
      
      // Reset form
      handleCancel();
      
    } catch (err) {
      console.error('Error saving service product:', err);
      setError(err.message);
    }
  };
  
  // Remove a product from a service
  const handleRemove = async (mappingId) => {
    if (!window.confirm('Are you sure you want to remove this product from the service?')) {
      return;
    }
    
    try {
      await stockApi.removeProductFromService(mappingId);
      
      // Refresh service products and costs
      await fetchServiceProducts();
      
    } catch (err) {
      console.error('Error removing product from service:', err);
      setError(err.message);
    }
  };
  
  // Get product options for select dropdown, excluding already added products
  const getAvailableProducts = () => {
    if (editingId) {
      // When editing, show all products
      return products;
    }
    
    // When adding new, exclude products already in the service
    const alreadyAddedIds = serviceProducts.map(sp => sp.product_id);
    return products.filter(product => !alreadyAddedIds.includes(product.id));
  };
  
  // Render UI
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Service Products Manager
      </Typography>
      
      {/* Error message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      
      {/* Cost summary */}
      <Box mb={4}>
        <Typography variant="h5" component="h2" gutterBottom>
          Service Cost Summary
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Total Cost
                </Typography>
                <Typography variant="h5" component="h3">
                  £{costSummary.total_cost.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Required Products
                </Typography>
                <Typography variant="h5" component="h3">
                  £{costSummary.required_cost.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography color="textSecondary" gutterBottom>
                  Optional Products
                </Typography>
                <Typography variant="h5" component="h3">
                  £{costSummary.optional_cost.toFixed(2)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      
      {/* Product form */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          {editingId ? 'Edit Product Usage' : 'Add Product to Service'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="product-select-label">Product</InputLabel>
                <Select
                  labelId="product-select-label"
                  id="product_id"
                  name="product_id"
                  value={formData.product_id}
                  onChange={handleInputChange}
                  disabled={!!editingId}
                  required
                  label="Product"
                >
                  <MenuItem value="">
                    <em>Select Product</em>
                  </MenuItem>
                  {getAvailableProducts().map(product => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name} ({product.unit})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                id="quantity"
                name="quantity"
                label="Quantity Used"
                type="number"
                value={formData.quantity}
                onChange={handleInputChange}
                required
                fullWidth
                inputProps={{
                  min: 0.01,
                  step: 0.01
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is_optional}
                    onChange={handleInputChange}
                    name="is_optional"
                  />
                }
                label="Optional Product"
              />
              <Typography variant="caption" display="block" color="textSecondary">
                Check if this product is not always used in the service
              </Typography>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is_retail}
                    onChange={handleInputChange}
                    name="is_retail"
                  />
                }
                label="Retail Product"
              />
              <Typography variant="caption" display="block" color="textSecondary">
                Check if this product is sold to the client
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <Box display="flex" gap={2}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="primary"
                >
                  {editingId ? 'Update Product' : 'Add Product'}
                </Button>
                {editingId && (
                  <Button 
                    type="button" 
                    variant="outlined" 
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Paper>
      
      {/* Product list */}
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <Typography variant="h5" component="h2" sx={{ p: 2 }}>
            Products Used in Service
          </Typography>
          
          {serviceProducts.length === 0 ? (
            <Box p={3}>
              <Typography>No products added to this service yet.</Typography>
            </Box>
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit</TableCell>
                    <TableCell>Cost</TableCell>
                    <TableCell>Optional</TableCell>
                    <TableCell>Retail</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {serviceProducts.map(sp => (
                    <TableRow 
                      key={sp.id}
                      sx={sp.is_optional ? { backgroundColor: 'rgba(245, 245, 245, 0.6)' } : {}}
                    >
                      <TableCell>{sp.product_name}</TableCell>
                      <TableCell>{sp.quantity}</TableCell>
                      <TableCell>{sp.unit}</TableCell>
                      <TableCell>£{sp.cost.toFixed(2)}</TableCell>
                      <TableCell>{sp.is_optional ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{sp.is_retail ? 'Yes' : 'No'}</TableCell>
                      <TableCell>
                        <Box display="flex" gap={1}>
                          <Button
                            size="small"
                            variant="outlined"
                            onClick={() => handleEdit(sp)}
                            disabled={!!editingId}
                          >
                            Edit
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() => handleRemove(sp.id)}
                            disabled={!!editingId}
                          >
                            Remove
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell colSpan={3}><Typography fontWeight="bold">Total Cost:</Typography></TableCell>
                  <TableCell colSpan={4}><Typography fontWeight="bold">£{costSummary.total_cost.toFixed(2)}</Typography></TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          )}
        </Paper>
      )}
    </Container>
  );
}

export default ServiceProductManager;