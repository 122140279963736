// calculatorUtils.js
// Core calculation functions used across calculator components

/**
 * Formats a number as currency
 * @param {number} value - The value to format
 * @param {string} currency - Currency code (default: 'GBP')
 * @returns {string} Formatted currency string
 */
export const formatCurrency = (value, currency = 'GBP') => {
  if (value === null || value === undefined) return '';
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

/**
 * Formats a number as a percentage
 * @param {number} value - The value to format
 * @param {number} digits - Number of decimal places (default: 2)
 * @returns {string} Formatted percentage string
 */
export const formatPercentage = (value, digits = 2) => {
  if (value === null || value === undefined) return '';
  return `${(value * 100).toFixed(digits)}%`;
};

/**
 * Calculates hourly rate based on salary and hours
 * @param {number} annualSalary - Annual salary amount
 * @param {number} hoursPerWeek - Weekly working hours
 * @param {number} weeksPerYear - Working weeks per year
 * @returns {number} Calculated hourly rate
 */
export const calculateHourlyRate = (annualSalary, hoursPerWeek = 40, weeksPerYear = 52) => {
  if (!annualSalary || !hoursPerWeek || !weeksPerYear) return 0;
  return annualSalary / (hoursPerWeek * weeksPerYear);
};

/**
 * Calculates annual cost from hourly rate
 * @param {number} hourlyRate - Hourly rate
 * @param {number} hoursPerWeek - Weekly working hours
 * @param {number} weeksPerYear - Working weeks per year
 * @returns {number} Calculated annual cost
 */
export const calculateAnnualCost = (hourlyRate, hoursPerWeek = 40, weeksPerYear = 52) => {
  if (!hourlyRate) return 0;
  return hourlyRate * hoursPerWeek * weeksPerYear;
};

/**
 * Applies overhead percentage to base cost
 * @param {number} baseCost - Base cost amount
 * @param {number} overheadPercentage - Overhead percentage (decimal)
 * @returns {number} Cost with overhead applied
 */
export const applyOverhead = (baseCost, overheadPercentage) => {
  if (!baseCost) return 0;
  return baseCost * (1 + (overheadPercentage || 0));
};

/**
 * Calculates profit from cost and profit margin
 * @param {number} cost - Base cost amount
 * @param {number} profitMargin - Profit margin (decimal)
 * @returns {number} Calculated profit amount
 */
export const calculateProfit = (cost, profitMargin) => {
  if (!cost) return 0;
  return cost * (profitMargin || 0);
};

/**
 * Calculates final price with profit
 * @param {number} cost - Cost amount
 * @param {number} profitMargin - Profit margin (decimal)
 * @returns {number} Final price
 */
export const calculatePrice = (cost, profitMargin) => {
  if (!cost) return 0;
  return cost / (1 - (profitMargin || 0));
};

/**
 * Rounds a number to specified decimal places
 * @param {number} value - Value to round
 * @param {number} decimals - Number of decimal places
 * @returns {number} Rounded value
 */
export const roundToDecimal = (value, decimals = 2) => {
  if (value === null || value === undefined) return 0;
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
};