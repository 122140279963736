import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import api from '../axiosConfig';

const IncomeImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);
  const [updateExisting, setUpdateExisting] = useState(false);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsProcessing(true);
    setError(null);
    setResults(null);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('update_existing', updateExisting.toString());

    try {
      const response = await api.post('/csv/import/income', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResults(response.data);
      
      if (response.data.success > 0 || response.data.updated_dates > 0) {
        const totalSkipped = (response.data.skipped_not_worked || 0) + 
                            (response.data.duplicates || 0) + 
                            (response.data.skipped_dates || 0);
        const messageType = response.data.failures?.length > 0 ? 'warning' : 'success';
        
        let message = '';
        if (response.data.success > 0) {
          message += `Successfully imported ${response.data.success} new record(s). `;
        }
        if (response.data.updated_dates > 0) {
          message += `Updated ${response.data.updated_dates} existing record(s). `;
        }
        if (totalSkipped > 0) {
          message += `${totalSkipped} record(s) skipped. `;
        }
        if (response.data.failures?.length > 0) {
          message += `${response.data.failures.length} failures.`;
        }
        
        setMessageType(messageType);
        setMessage(message);
      } else {
        setMessageType('error');
        setMessage('Import failed. Please check the errors below.');
      }
    } catch (error) {
      console.error('Import error:', error);
      setError(error.response?.data?.error || 'An error occurred during import');
      setMessageType('error');
      setMessage('Error importing data. Please try again.');
    } finally {
      setIsProcessing(false);
      event.target.value = '';
    }
  };

  const handleDownloadTemplate = () => {
    const templateContent = 'name,date,sales_type,amount,payment_type,worked_today,client_count,product_count,start_time,end_time,hours_worked\n' +
      'John Smith,05/01/2024,service,150.00,card,true,3,0,09:00,17:00,8\n' +
      'Jane Doe,05/01/2024,product,50.00,cash,true,1,2,09:00,17:00,8\n' +
      'Sarah Wilson,05/01/2024,service,0,bank,false,,,,,';

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'income_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Income Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Required Fields:</Typography>
            <ul>
              <li>Name (must match employee name in system)</li>
              <li>Date (DD/MM/YYYY format)</li>
              <li>Sales Type (service or product)</li>
              <li>Amount</li>
              <li>Payment Type (cash, card, or bank)</li>
              <li>Worked Today (true/false)</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Optional Fields:</Typography>
            <ul>
              <li>Client Count (for service sales)</li>
              <li>Product Count (for product sales)</li>
              <li>Start Time (HH:MM format)</li>
              <li>End Time (HH:MM format)</li>
              <li>Hours Worked</li>
            </ul>
          </Alert>

          <Typography variant="body2" sx={{ mb: 2 }}>
            Important Notes:
            <ul>
              <li>If 'worked_today' is false, sales fields can be left empty</li>
              <li>Duplicate entries within the same import will be automatically skipped</li>
              <li>Employee names must match exactly as in the system</li>
              <li>You can choose to update existing records or skip dates that already have records</li>
            </ul>
          </Typography>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="body2">
              1. Download the template below
              <br />
              2. Fill in your income data following the format requirements
              <br />
              3. Choose whether to update existing records or skip them
              <br />
              4. Save and upload your completed file
            </Typography>
          </Alert>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {results && (
            <Alert 
              severity={
                results.success > 0 || results.updated_dates > 0 ? 
                (results.failures?.length > 0 ? 'warning' : 'success') : 
                'error'
              } 
              sx={{ mb: 3 }}
            >
              <Typography variant="subtitle2">Import Results:</Typography>
              <ul>
                {results.success > 0 && (
                  <li>Successfully imported new records: {results.success}</li>
                )}
                {results.updated_dates > 0 && (
                  <li>Updated existing records: {results.updated_dates}</li>
                )}
                {results.skipped_dates > 0 && (
                  <li>Skipped existing dates: {results.skipped_dates}</li>
                )}
                {results.skipped_not_worked > 0 && (
                  <li>Skipped (not worked): {results.skipped_not_worked}</li>
                )}
                {results.duplicates > 0 && (
                  <li>Skipped (duplicates): {results.duplicates}</li>
                )}
                {results.failures?.length > 0 && (
                  <li>Failed imports: {results.failures.length}</li>
                )}
                <li>Total Amount: £{results.total_amount?.toFixed(2)}</li>
              </ul>
              {results.failures?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Failed Rows:</Typography>
                  <ul>
                    {results.failures.map((failure, index) => (
                      <li key={index}>Row {failure.row}: {failure.error}</li>
                    ))}
                  </ul>
                </Box>
              )}
              {(results.skipped_dates > 0 || results.skipped_not_worked > 0 || results.duplicates > 0) && (
                <Typography variant="body2" sx={{ mt: 2, fontStyle: 'italic' }}>
                  * {results.skipped_dates > 0 ? `${results.skipped_dates} existing dates skipped. ` : ''} 
                  {results.skipped_not_worked > 0 ? `${results.skipped_not_worked} non-working records skipped. ` : ''} 
                  {results.duplicates > 0 ? `${results.duplicates} duplicate entries skipped.` : ''}
                </Typography>
              )}
            </Alert>
          )}

          <Box sx={{ mb: 3 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={updateExisting}
                  onChange={(e) => setUpdateExisting(e.target.checked)}
                  color="primary"
                />
              }
              label="Update existing records (if unchecked, will skip dates that already have records)"
            />
          </Box>

          <Button
            startIcon={<DownloadIcon />}
            variant="outlined"
            onClick={handleDownloadTemplate}
            sx={{ mt: 2, mb: 4 }}
            disabled={isProcessing}
          >
            Download Template
          </Button>

          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="csv-file-input"
              disabled={isProcessing}
            />
            <label htmlFor="csv-file-input">
              <Button
                startIcon={isProcessing ? <CircularProgress size={20} /> : <UploadIcon />}
                variant="contained"
                component="span"
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Choose CSV File'}
              </Button>
            </label>
            {isProcessing && (
              <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                Processing your file. This may take a few moments...
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default IncomeImport;