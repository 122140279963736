import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Paper, 
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  CircularProgress,
  Alert
} from '@mui/material';
import api from './axiosConfig';
import { useAuth } from './AuthContext';

const VatReturnView = () => {
  const { periodId } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [vatReturn, setVatReturn] = useState(null);
  
  useEffect(() => {
    const fetchVatReturnData = async () => {
      try {
        setLoading(true);
        // Add timestamp for cache busting
        const timestamp = new Date().getTime();
        
        // Remove account_id param - it should use the authenticated account from security context
        const response = await api.get(`/vat/periods/${periodId}?t=${timestamp}`);
        
        if (response.data.success) {
          setVatReturn(response.data.data);
        } else {
          setError(response.data.error || 'Failed to load VAT return');
        }
      } catch (err) {
        console.error('Error fetching VAT return:', err);
        setError('Failed to load VAT return details');
      } finally {
        setLoading(false);
      }
    };
    
    if (user?.id && periodId) {
      fetchVatReturnData();
    }
    
    // Cleanup function to reset state when unmounting
    return () => {
      setVatReturn(null);
    };
  }, [periodId, user]);
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }
  
  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }
  
  if (!vatReturn) {
    return <Alert severity="info">No VAT return found</Alert>;
  }
  
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(value || 0);
  };
  
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };
  
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">VAT Return Details</Typography>
        <Button 
          variant="outlined" 
          onClick={() => navigate('/reports/vat')}
        >
          Back to VAT Dashboard
        </Button>
      </Box>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Period Information</Typography>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Period</TableCell>
                  <TableCell align="right">
                    {formatDate(vatReturn.period_start_date)} - {formatDate(vatReturn.period_end_date)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Due Date</TableCell>
                  <TableCell align="right">{formatDate(vatReturn.due_date)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Status</TableCell>
                  <TableCell align="right">{vatReturn.status}</TableCell>
                </TableRow>
                {vatReturn.submission_date && (
                  <TableRow>
                    <TableCell component="th" scope="row">Submitted On</TableCell>
                    <TableCell align="right">{formatDate(vatReturn.submission_date)}</TableCell>
                  </TableRow>
                )}
                {vatReturn.submission_reference && (
                  <TableRow>
                    <TableCell component="th" scope="row">Reference</TableCell>
                    <TableCell align="right">{vatReturn.submission_reference}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>VAT Amounts</Typography>
          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">Output VAT (Sales)</TableCell>
                  <TableCell align="right">{formatCurrency(vatReturn.output_vat)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Input VAT (Purchases)</TableCell>
                  <TableCell align="right">{formatCurrency(vatReturn.input_vat)}</TableCell>
                </TableRow>
                <TableRow sx={{ 
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  fontWeight: 'bold'
                }}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle1">VAT Due</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle1">{formatCurrency(vatReturn.vat_due)}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      
      {/* Debug info section if available */}
      {vatReturn.debug && (
        <Box sx={{ mt: 4, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
          <Typography variant="caption" color="text.secondary">
            Debug Info
          </Typography>
          {vatReturn.debug.account_id && (
            <Typography variant="caption" component="div" color="text.secondary">
              Account ID: {vatReturn.debug.account_id}
            </Typography>
          )}
          {vatReturn.debug.timestamp && (
            <Typography variant="caption" component="div" color="text.secondary">
              Timestamp: {vatReturn.debug.timestamp}
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default VatReturnView;