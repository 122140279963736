import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Alert,
  CircularProgress
} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import api from '../axiosConfig';

const TestImport = () => {
  const [incomeResults, setIncomeResults] = useState(null);
  const [expenditureResults, setExpenditureResults] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const handleFileUpload = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsProcessing(true);
    setError(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post(`/csv/import/${type}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (type === 'income') {
        setIncomeResults(response.data);
      } else {
        setExpenditureResults(response.data);
      }
    } catch (error) {
      setError(error.response?.data?.error || 'An error occurred during import');
      console.error('Import error:', error);
    } finally {
      setIsProcessing(false);
      event.target.value = '';
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 800, margin: '20px auto' }}>
      <Typography variant="h4" gutterBottom>CSV Import Test</Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Income Import</Typography>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleFileUpload(e, 'income')}
          style={{ display: 'none' }}
          id="income-file-input"
          disabled={isProcessing}
        />
        <label htmlFor="income-file-input">
          <Button
            startIcon={isProcessing ? <CircularProgress size={20} /> : <UploadIcon />}
            variant="contained"
            component="span"
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Upload Income CSV'}
          </Button>
        </label>

        {incomeResults && (
          <Box sx={{ mt: 2 }}>
            <Alert severity={incomeResults.success > 0 ? 'success' : 'warning'}>
              <Typography variant="subtitle2">Income Import Results:</Typography>
              <ul>
                <li>Successfully imported: {incomeResults.success}</li>
                <li>Failed imports: {incomeResults.failures?.length || 0}</li>
                <li>Total Amount: £{incomeResults.total_amount?.toFixed(2)}</li>
              </ul>
              {incomeResults.failures?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Failures:</Typography>
                  <ul>
                    {incomeResults.failures.map((failure, index) => (
                      <li key={index}>Row {failure.row}: {failure.error}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Alert>
          </Box>
        )}
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>Expenditure Import</Typography>
        <input
          type="file"
          accept=".csv"
          onChange={(e) => handleFileUpload(e, 'expenditure')}
          style={{ display: 'none' }}
          id="expenditure-file-input"
          disabled={isProcessing}
        />
        <label htmlFor="expenditure-file-input">
          <Button
            startIcon={isProcessing ? <CircularProgress size={20} /> : <UploadIcon />}
            variant="contained"
            component="span"
            disabled={isProcessing}
          >
            {isProcessing ? 'Processing...' : 'Upload Expenditure CSV'}
          </Button>
        </label>

        {expenditureResults && (
          <Box sx={{ mt: 2 }}>
            <Alert severity={expenditureResults.success > 0 ? 'success' : 'warning'}>
              <Typography variant="subtitle2">Expenditure Import Results:</Typography>
              <ul>
                <li>Successfully imported: {expenditureResults.success}</li>
                <li>Failed imports: {expenditureResults.failures?.length || 0}</li>
                <li>Total Amount: £{expenditureResults.total_amount?.toFixed(2)}</li>
                <li>Total VAT: £{expenditureResults.total_vat?.toFixed(2)}</li>
                <li>Total Net: £{expenditureResults.total_net?.toFixed(2)}</li>
              </ul>
              {expenditureResults.failures?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Failures:</Typography>
                  <ul>
                    {expenditureResults.failures.map((failure, index) => (
                      <li key={index}>Row {failure.row}: {failure.error}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Alert>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TestImport;