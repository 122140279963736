import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  Button,
  Alert,
  Paper,
  CircularProgress
} from '@mui/material';
import { UploadFile as UploadFileIcon } from '@mui/icons-material';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';

const ImportDialog = ({ 
  isOpen, 
  onClose, 
  onFileChange, 
  selectedFile, 
  isImporting, 
  importResult, 
  onImport 
}) => {
  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
    >
      <DialogTitle>Import Price List</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 1 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            Upload a CSV or Excel file with your service price list. The file must have at least the following columns: 
            <strong>name</strong> and <strong>price</strong>. Optional columns include: <strong>category</strong>, 
            <strong>duration</strong> (in minutes), and <strong>description</strong>.
          </Typography>
          
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="body2">
              Services with matching names will be updated with the new prices and information.
              New services will be created if they don't already exist.
            </Typography>
          </Alert>
          
          <Box sx={{ mb: 3 }}>
            <input
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={onFileChange}
              style={{ display: 'none' }}
              id="price-list-upload"
            />
            <label htmlFor="price-list-upload">
              <Button 
                variant="contained" 
                component="span" 
                startIcon={<UploadFileIcon />}
              >
                Select File
              </Button>
            </label>
            {selectedFile && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                Selected file: <strong>{selectedFile.name}</strong> ({(selectedFile.size / 1024).toFixed(2)} KB)
              </Typography>
            )}
          </Box>
          
          {importResult && (
            <Box sx={{ mb: 2 }}>
              <Alert 
                severity={(importResult.errors && importResult.errors.length > 0) ? "warning" : "success"} 
                sx={{ mb: 2 }}
              >
                {importResult.message || `Import complete. Created ${importResult.created || 0} services, updated ${importResult.updated || 0} services.`}
              </Alert>
              
              {importResult.errors && importResult.errors.length > 0 && (
                <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                  <Typography variant="subtitle2" gutterBottom>
                    Errors:
                  </Typography>
                  <ul>
                    {importResult.errors.map((error, index) => (
                      <li key={index}>
                        <Typography variant="body2" color="error">
                          {error}
                        </Typography>
                      </li>
                    ))}
                  </ul>
                </Paper>
              )}
            </Box>
          )}
          
          <Box sx={{ mt: 4 }}>
            <Typography variant="subtitle1" gutterBottom>
              Sample Format
            </Typography>
            <Typography variant="body2" component="pre" sx={{ 
              p: 2, 
              bgcolor: 'grey.100', 
              borderRadius: 1,
              overflowX: 'auto',
              fontFamily: 'monospace'
            }}>
              name,category,price,duration,description<br/>
              "Haircut - Short","Haircuts",30,30,"Basic haircut for short hair"<br/>
              "Haircut - Long","Haircuts",45,45,"Haircut for long hair"<br/>
              "Hair Color - Full","Color",85,120,"Full head color"
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Cancel
        </Button>
        <Button 
          variant="contained" 
          onClick={onImport}
          disabled={!selectedFile || isImporting}
        >
          {isImporting ? (
            <>
              <CircularProgress size={24} sx={{ mr: 1 }} />
              Importing...
            </>
          ) : (
            'Import Services'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;