import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Grid,
  IconButton
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { format, parseISO, startOfMonth } from 'date-fns';
import api from './axiosConfig';

const TaxAccrual = ({ accountId }) => {
  const [accruals, setAccruals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openSetupDialog, setOpenSetupDialog] = useState(false);
  const [accrualDate, setAccrualDate] = useState(startOfMonth(new Date()));
  const [accrualAmount, setAccrualAmount] = useState('');
  const [accrualDescription, setAccrualDescription] = useState('');
  const [totalAnnualTax, setTotalAnnualTax] = useState('');
  const [setupYear, setSetupYear] = useState(new Date().getFullYear());
  const [setupDescription, setSetupDescription] = useState('');
  
  const fetchTaxAccruals = async () => {
    setLoading(true);
    try {
      // Get current date as YYYY-MM-DD for default date params
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 0, 1);
      const endDate = new Date(today.getFullYear(), 11, 31);
      
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');
      
      const response = await api.get(`/accounting/tax-accruals`, {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate
        }
      });
      
      if (response.data && response.data.accruals) {
        // Sort accruals by month
        const sortedAccruals = [...response.data.accruals].sort((a, b) => {
          return new Date(a.month) - new Date(b.month);
        });
        setAccruals(sortedAccruals);
      }
    } catch (err) {
      console.error('Error fetching tax accruals:', err);
      setError('Failed to fetch tax accruals. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchTaxAccruals();
  }, [accountId]);
  
  const handleAddAccrual = async () => {
    try {
      await api.post('/accounting/tax-accruals', {
        month_date: format(accrualDate, 'yyyy-MM-dd'),
        amount: parseFloat(accrualAmount),
        description: accrualDescription || undefined
      });
      
      setOpenAddDialog(false);
      resetFormFields();
      fetchTaxAccruals(); // Refresh data
    } catch (err) {
      console.error('Error adding tax accrual:', err);
      setError('Failed to add tax accrual. Please try again.');
    }
  };
  
  const handleAnnualSetup = async () => {
    try {
      await api.post('/accounting/tax-accruals/annual-setup', {
        year: setupYear,
        total_annual_tax: parseFloat(totalAnnualTax),
        description: setupDescription || undefined
      });
      
      setOpenSetupDialog(false);
      resetFormFields();
      fetchTaxAccruals(); // Refresh data
    } catch (err) {
      console.error('Error setting up annual accruals:', err);
      setError('Failed to set up annual accruals. Please try again.');
    }
  };
  
  const handleDeleteAccrual = async (accrualId) => {
    try {
      await api.delete(`/accounting/tax-accruals/${accrualId}`);
      fetchTaxAccruals(); // Refresh data
    } catch (err) {
      console.error('Error deleting tax accrual:', err);
      setError('Failed to delete tax accrual. Please try again.');
    }
  };
  
  const resetFormFields = () => {
    setAccrualDate(startOfMonth(new Date()));
    setAccrualAmount('');
    setAccrualDescription('');
    setTotalAnnualTax('');
    setSetupYear(new Date().getFullYear());
    setSetupDescription('');
  };
  
  const calculateTotalAccrued = () => {
    return accruals.reduce((sum, accrual) => sum + accrual.amount, 0);
  };
  
  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Paper>
    );
  }
  
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Tax Accruals</Typography>
        <Box>
          <Button 
            variant="outlined" 
            startIcon={<AddCircleOutlineIcon />} 
            onClick={() => setOpenAddDialog(true)}
            sx={{ mr: 1 }}
          >
            Add Accrual
          </Button>
          <Button 
            variant="contained" 
            onClick={() => setOpenSetupDialog(true)}
          >
            Annual Setup
          </Button>
        </Box>
      </Box>
      
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper elevation={1} sx={{ p: 2, backgroundColor: '#f8f9fa' }}>
            <Typography variant="subtitle2">Total Accrued for {new Date().getFullYear()}</Typography>
            <Typography variant="h4">£{calculateTotalAccrued().toFixed(2)}</Typography>
            <Typography variant="body2" color="text.secondary">
              Monthly average: £{(calculateTotalAccrued() / 12).toFixed(2)}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Month</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accruals.length > 0 ? (
              accruals.map((accrual) => (
                <TableRow key={accrual.id}>
                  <TableCell>{format(parseISO(accrual.month), 'MMMM yyyy')}</TableCell>
                  <TableCell>{accrual.description || '-'}</TableCell>
                  <TableCell align="right">£{accrual.amount.toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <IconButton 
                      size="small" 
                      color="error"
                      onClick={() => handleDeleteAccrual(accrual.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No tax accruals found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Add Accrual Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add Tax Accrual</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2, minWidth: '300px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Accrual Month"
                views={['year', 'month']}
                value={accrualDate}
                onChange={(newValue) => setAccrualDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </LocalizationProvider>
            
            <TextField
              label="Amount"
              type="number"
              value={accrualAmount}
              onChange={(e) => setAccrualAmount(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <span>£</span>,
              }}
            />
            
            <TextField
              label="Description"
              value={accrualDescription}
              onChange={(e) => setAccrualDescription(e.target.value)}
              fullWidth
              margin="normal"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAddAccrual} 
            variant="contained" 
            color="primary"
            disabled={!accrualAmount}
          >
            Add Accrual
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Annual Setup Dialog */}
      <Dialog open={openSetupDialog} onClose={() => setOpenSetupDialog(false)}>
        <DialogTitle>Annual Tax Accrual Setup</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2, minWidth: '300px' }}>
            <Typography variant="body2" gutterBottom>
              This will create monthly accruals for the entire year, evenly distributing the total tax amount.
            </Typography>
            
            <TextField
              label="Year"
              type="number"
              value={setupYear}
              onChange={(e) => setSetupYear(parseInt(e.target.value))}
              fullWidth
              margin="normal"
            />
            
            <TextField
              label="Total Annual Tax"
              type="number"
              value={totalAnnualTax}
              onChange={(e) => setTotalAnnualTax(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <span>£</span>,
              }}
            />
            
            <TextField
              label="Description"
              value={setupDescription}
              onChange={(e) => setSetupDescription(e.target.value)}
              fullWidth
              margin="normal"
            />
            
            {totalAnnualTax && (
              <Alert severity="info" sx={{ mt: 2 }}>
                This will create 12 monthly accruals of £{(parseFloat(totalAnnualTax) / 12).toFixed(2)} each.
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSetupDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAnnualSetup} 
            variant="contained" 
            color="primary"
            disabled={!totalAnnualTax}
          >
            Create Accruals
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TaxAccrual;