import React, { useState } from 'react';
import { 
  Paper, Typography, Box, TextField, Button, 
  Grid, Alert, InputAdornment 
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import api from './axiosConfig';

const DailyIncomeEntry = () => {
  const [date, setDate] = useState(new Date());
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [voucher, setVoucher] = useState(0);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  
  // Calculate total
  const total = parseFloat(cash || 0) + parseFloat(card || 0) + parseFloat(voucher || 0);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    
    try {
      await api.post('/income/daily', {
        date: date.toISOString().split('T')[0],
        cash,
        card,
        voucher,
        total
      });
      
      setSuccess(true);
      // Reset form or show confirmation
      setCash(0);
      setCard(0);
      setVoucher(0);
    } catch (err) {
      setError(err.response?.data?.error || 'Error adding income');
    }
  };
  
  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>Add Daily Income</Typography>
      
      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          Daily income added successfully!
        </Alert>
      )}
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePicker
              label="Date"
              value={date}
              onChange={(newDate) => setDate(newDate)}
              renderInput={(params) => <TextField {...params} fullWidth required />}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              label="Cash"
              type="number"
              value={cash}
              onChange={(e) => setCash(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              label="Card"
              type="number"
              value={card}
              onChange={(e) => setCard(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <TextField
              label="Voucher"
              type="number"
              value={voucher}
              onChange={(e) => setVoucher(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <TextField
              label="Total"
              type="number"
              value={total}
              disabled
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>
              }}
            />
          </Grid>
          
          <Grid item xs={12}>
            <Button 
              variant="contained" 
              color="primary" 
              type="submit"
              fullWidth
            >
              Save Daily Income
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default DailyIncomeEntry;