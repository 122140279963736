import React from 'react';
import { formatCurrency, calculateHourlyRate } from './utils/calculatorUtils';
import { transformApiData } from './utils/dataTransformers';
import { validateNumeric, validateForm } from './utils/validationUtils';
import { Card, CardHeader, CardContent, Grid, Typography, Box, Divider, Alert } from '@mui/material';


const TargetHourlyRate = ({ businessSettings, expenses, servicesAnalysis }) => {
  // Get total annual expenses (excluding categories already factored elsewhere)
  const excludedCategories = ['Stock', 'Payroll', 'Personal'];
  
  // Check if expenses is the old format (array) or new format (object with categoryBreakdown)
  const hasNewFormat = expenses && expenses.categoryBreakdown && typeof expenses.categoryBreakdown === 'object';
  
  // Calculate total expenses differently based on data format
  let totalAnnualExpenses = 0;
  
  if (hasNewFormat) {
    // New format - expenses is an object with categoryBreakdown
    console.log("Using new expense format with categoryBreakdown");
    
    // Sum the values from categories, excluding specific ones
    totalAnnualExpenses = Object.entries(expenses.categoryBreakdown || {})
      .filter(([category, amount]) => !excludedCategories.includes(category))
      .reduce((sum, [category, amount]) => sum + amount, 0);
    
    // If this is already an annual figure, don't multiply by 12
    if (expenses.period === 'last12months') {
      console.log("Using 12-month expense total directly:", totalAnnualExpenses);
    } else {
      // For other periods (monthly, last3months, last6months), convert to annual
      const multiplier = expenses.period === 'last6months' ? 2 : 
                         expenses.period === 'last3months' ? 4 : 12;
      
      totalAnnualExpenses *= multiplier;
      console.log(`Converting ${expenses.period} expenses to annual (× ${multiplier}):`, totalAnnualExpenses);
    }
  } else {
    // Old format - expenses is an array of objects with category and total
    console.log("Using old expense format (array of objects)");
    
    totalAnnualExpenses = Array.isArray(expenses)
      ? expenses
          .filter(exp => !excludedCategories.includes(exp?.category))
          .reduce((sum, exp) => sum + (exp?.total || 0), 0) * 12 // Convert to annual
      : 0;
  }
  
  console.log("Final annual expenses used for calculation:", totalAnnualExpenses);
    
  // Get utilization rate (default to 85% if not set)
  const utilizationRate = (businessSettings?.utilization_rate || 85) / 100;
  
  // Get weekly hours
  const weeklyHours = businessSettings?.total_trading_hours || 40;
  
  // Calculate effective weekly hours
  const effectiveWeeklyHours = weeklyHours * utilizationRate;
  
  // Calculate annual billable hours
  const annualBillableHours = effectiveWeeklyHours * 52;
  
  // Calculate minimum hourly rate needed to cover expenses
  const baseHourlyRate = annualBillableHours > 0 
    ? totalAnnualExpenses / annualBillableHours 
    : 0;
    
  // Calculate recommended hourly rate with profit margin
  const profitMargin = businessSettings?.profit_level_1 || 20;
  const recommendedHourlyRate = baseHourlyRate * (1 + profitMargin / 100);
  
  // Function to format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', { 
      style: 'currency', 
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };
  
  return (
    <Card>
      <CardHeader title="Target Hourly Rate" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom color="primary">
              {formatCurrency(recommendedHourlyRate)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Recommended average hourly rate across all services
            </Typography>
          </Grid>
          
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
            <Typography variant="subtitle2" gutterBottom>
              Calculation Breakdown
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Typography variant="body2">Annual Expenses:</Typography>
              <Typography variant="body2">{formatCurrency(totalAnnualExpenses)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
              <Typography variant="body2">Billable Hours (@ {utilizationRate * 100}% utilization):</Typography>
              <Typography variant="body2">{annualBillableHours.toFixed(0)} hours</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
              <Typography variant="body2">Base Hourly Rate:</Typography>
              <Typography variant="body2">{formatCurrency(baseHourlyRate)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
              <Typography variant="body2">Profit Margin:</Typography>
              <Typography variant="body2">{profitMargin}%</Typography>
            </Box>
          </Grid>
          
          <Grid item xs={12}>
            <Alert severity="info" sx={{ mt: 1 }}>
              This is the average hourly rate your services should generate to cover expenses and achieve your desired {profitMargin}% profit margin.
            </Alert>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TargetHourlyRate;