import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Alert,
  CircularProgress,
  Chip,
  Snackbar,
  Card,
  CardContent
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import stockApi from './stockApi';
import api from './axiosConfig';

function StockTransactions() {
  // State for products and transactions
  const [products, setProducts] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [currentStock, setCurrentStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  
  // State for pagination
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 20,
    offset: 0
  });
  
  // State for transaction form
  const [formData, setFormData] = useState({
    product_id: '',
    transaction_type: 'purchase',
    quantity: 1,
    unit_price: 0,
    reference: '',
    notes: '',
    transaction_date: new Date().toISOString().split('T')[0]
  });
  
  // State for filters
  const [filters, setFilters] = useState({
    productId: '',
    type: '',
    startDate: '',
    endDate: ''
  });
  
  // Load products and transactions when component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Load products
        const productsResponse = await stockApi.getProducts();
        setProducts(productsResponse.data || []);
        
        // Load current stock
        const stockResponse = await stockApi.getCurrentStock();
        setCurrentStock(stockResponse.data || []);
        
        // Load transactions
        await fetchTransactions();
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    
    loadData();
  }, []);
  
  // Fetch transactions with current filters and pagination
  const fetchTransactions = async () => {
    try {
      const apiFilters = {
        productId: filters.productId || undefined,
        type: filters.type || undefined,
        startDate: filters.startDate || undefined,
        endDate: filters.endDate || undefined,
        limit: pagination.limit,
        offset: pagination.offset
      };
      
      const response = await stockApi.getStockTransactions(apiFilters);
      setTransactions(response.data || []);
      setPagination({
        ...pagination,
        total: response.pagination?.total || 0
      });
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError(err.message);
    }
  };
  
  // Handle filter changes
  useEffect(() => {
    // Reset pagination when filters change
    setPagination({
      ...pagination,
      offset: 0
    });
    
    fetchTransactions();
  }, [filters]);
  
  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    // If changing product, update unit price from selected product
    if (name === 'product_id') {
      const selectedProduct = products.find(p => p.id === parseInt(value));
      setFormData({
        ...formData,
        product_id: value,
        unit_price: selectedProduct ? 
          (formData.transaction_type === 'sale' ? selectedProduct.retail_price : selectedProduct.purchase_price) 
          : 0
      });
    } 
    // If changing transaction type, update price based on type
    else if (name === 'transaction_type') {
      const selectedProduct = products.find(p => p.id === parseInt(formData.product_id));
      setFormData({
        ...formData,
        transaction_type: value,
        unit_price: selectedProduct ? 
          (value === 'sale' ? selectedProduct.retail_price : selectedProduct.purchase_price) 
          : formData.unit_price
      });
    }
    // Handle other inputs normally
    else {
      const parsedValue = ['quantity', 'unit_price'].includes(name)
        ? (value === '' ? 0 : parseFloat(value))
        : value;
      
      setFormData({
        ...formData,
        [name]: parsedValue
      });
    }
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Create transaction
      await stockApi.createStockTransaction(formData);
      setSuccessMessage('Transaction recorded successfully');
      
      // Refresh transactions and current stock
      await fetchTransactions();
      const stockResponse = await stockApi.getCurrentStock();
      setCurrentStock(stockResponse.data || []);
      
      // Reset form parts
      setFormData({
        ...formData,
        quantity: 1,
        reference: '',
        notes: ''
      });
      
    } catch (err) {
      console.error('Error creating transaction:', err);
      setError(err.message);
    }
  };
  
  // Handle filter changes
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };
  
  // Format date for display
  const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Get current stock level for a product
  const getStockLevel = (productId) => {
    const stockItem = currentStock.find(item => item.product_id === parseInt(productId));
    return stockItem ? stockItem.quantity : 0;
  };
  
  // Get stock unit for a product
  const getProductUnit = (productId) => {
    const product = products.find(p => p.id === parseInt(productId));
    return product ? product.unit : '';
  };
  
  // Transaction type chip color
  const getTransactionTypeColor = (type) => {
    switch(type) {
      case 'purchase': return 'success';
      case 'sale': return 'primary';
      case 'adjustment': return 'warning';
      case 'waste': return 'error';
      case 'transfer': return 'info';
      default: return 'default';
    }
  };
  
  // Handle pagination changes
  const handleChangePage = (event, newPage) => {
    setPagination({
      ...pagination,
      offset: newPage * pagination.limit
    });
  };
  
  const handleChangeRowsPerPage = (event) => {
    setPagination({
      ...pagination,
      limit: parseInt(event.target.value, 10),
      offset: 0
    });
  };
  
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Stock Transactions
      </Typography>
      
      {/* Error message */}
      {error && (
        <Alert 
          severity="error" 
          sx={{ mb: 2 }}
          onClose={() => setError(null)}
        >
          {error}
        </Alert>
      )}
      
      {/* Transaction form */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Record New Transaction
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="product-select-label">Product</InputLabel>
                <Select
                  labelId="product-select-label"
                  id="product_id"
                  name="product_id"
                  value={formData.product_id}
                  onChange={handleInputChange}
                  label="Product"
                >
                  <MenuItem value="">
                    <em>Select Product</em>
                  </MenuItem>
                  {products.map(product => (
                    <MenuItem key={product.id} value={product.id}>
                      {product.name} ({getStockLevel(product.id)} {product.unit} in stock)
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="type-select-label">Transaction Type</InputLabel>
                <Select
                  labelId="type-select-label"
                  id="transaction_type"
                  name="transaction_type"
                  value={formData.transaction_type}
                  onChange={handleInputChange}
                  label="Transaction Type"
                >
                  <MenuItem value="purchase">Purchase</MenuItem>
                  <MenuItem value="sale">Sale</MenuItem>
                  <MenuItem value="adjustment">Adjustment</MenuItem>
                  <MenuItem value="waste">Waste</MenuItem>
                  <MenuItem value="transfer">Transfer</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                id="quantity"
                name="quantity"
                label="Quantity"
                type="number"
                value={formData.quantity}
                onChange={handleInputChange}
                inputProps={{
                  min: 0.01,
                  step: 0.01
                }}
                helperText={formData.product_id ? `Current stock: £{getStockLevel(formData.product_id)} £{getProductUnit(formData.product_id)}` : ''}
              />
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                id="unit_price"
                name="unit_price"
                label="Unit Price"
                type="number"
                value={formData.unit_price}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: '£'
                }}
                inputProps={{
                  min: 0,
                  step: 0.01
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                required
                id="transaction_date"
                name="transaction_date"
                label="Date"
                type="date"
                value={formData.transaction_date}
                onChange={handleInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="reference"
                name="reference"
                label="Reference"
                value={formData.reference}
                onChange={handleInputChange}
                placeholder="Invoice #, PO #, etc."
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                id="notes"
                name="notes"
                label="Notes"
                value={formData.notes}
                onChange={handleInputChange}
                placeholder="Optional notes about this transaction"
                multiline
                rows={2}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                startIcon={<AddIcon />}
              >
                Record Transaction
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      
      {/* Current Stock Summary */}
      {currentStock.length > 0 && (
        <Box mb={4}>
          <Typography variant="h5" component="h2" gutterBottom>
            Current Stock Summary
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Total Items
                  </Typography>
                  <Typography variant="h5">
                    {currentStock.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Total Value
                  </Typography>
                  <Typography variant="h5">
                    £{currentStock.reduce((sum, item) => sum + (item.value || 0), 0).toFixed(2)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Typography color="textSecondary" gutterBottom>
                    Low Stock Items
                  </Typography>
                  <Typography variant="h5">
                    {currentStock.filter(item => item.is_low_stock).length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      
      {/* Transaction filters */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Transaction History
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="product-filter-label">Product</InputLabel>
              <Select
                labelId="product-filter-label"
                id="productId"
                name="productId"
                value={filters.productId}
                onChange={handleFilterChange}
                label="Product"
              >
                <MenuItem value="">All Products</MenuItem>
                {products.map(product => (
                  <MenuItem key={product.id} value={product.id}>
                    {product.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="type-filter-label">Type</InputLabel>
              <Select
                labelId="type-filter-label"
                id="type"
                name="type"
                value={filters.type}
                onChange={handleFilterChange}
                label="Type"
              >
                <MenuItem value="">All Types</MenuItem>
                <MenuItem value="purchase">Purchase</MenuItem>
                <MenuItem value="sale">Sale</MenuItem>
                <MenuItem value="adjustment">Adjustment</MenuItem>
                <MenuItem value="waste">Waste</MenuItem>
                <MenuItem value="transfer">Transfer</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="startDate"
              name="startDate"
              label="Start Date"
              type="date"
              value={filters.startDate}
              onChange={handleFilterChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              id="endDate"
              name="endDate"
              label="End Date"
              type="date"
              value={filters.endDate}
              onChange={handleFilterChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      
      {/* Transaction list */}
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer>
          {loading ? (
            <Box display="flex" justifyContent="center" my={4}>
              <CircularProgress />
            </Box>
          ) : transactions.length === 0 ? (
            <Box p={3}>
              <Typography>No transactions found.</Typography>
            </Box>
          ) : (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell align="right">Quantity</TableCell>
                    <TableCell align="right">Unit Price</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell>Reference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map(transaction => (
                    <TableRow key={transaction.id}>
                      <TableCell>{formatDate(transaction.transaction_date)}</TableCell>
                      <TableCell>{transaction.product_name}</TableCell>
                      <TableCell>
                        <Chip 
                          label={transaction.transaction_type} 
                          color={getTransactionTypeColor(transaction.transaction_type)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">{transaction.quantity}</TableCell>
                      <TableCell align="right">£{transaction.unit_price?.toFixed(2)}</TableCell>
                      <TableCell align="right">£{(transaction.quantity * transaction.unit_price).toFixed(2)}</TableCell>
                      <TableCell>{transaction.reference || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              
              <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                component="div"
                count={pagination.total}
                rowsPerPage={pagination.limit}
                page={Math.floor(pagination.offset / pagination.limit)}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </TableContainer>
      </Paper>
      
      {/* Success message */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Container>
  );
}

export default StockTransactions;