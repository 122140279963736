import React, { useState, useEffect } from 'react';
import stockApi from './stockApi';
import api from './axiosConfig';
import {
  Container,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Box,
  Alert,
  CircularProgress,
  Chip,
  Snackbar
} from '@mui/material';

const PurchaseOrders = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [filters, setFilters] = useState({
    status: '',
    supplierId: '',
    startDate: '',
    endDate: ''
  });

  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    supplier_id: '',
    expected_delivery_date: '',
    notes: '',
    items: []
  });

  const [newItem, setNewItem] = useState({
    product_id: '',
    quantity: 1,
    unit_price: 0
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const ordersResponse = await stockApi.getPurchaseOrders(filters);
        setPurchaseOrders(ordersResponse.data || []);

        const suppliersData = await stockApi.getSuppliers();
        setSuppliers(suppliersData || []);

        const productsResponse = await stockApi.getProducts();
        setProducts(productsResponse.data || []);

        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    loadData();
  }, [filters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleItemChange = (e) => {
    const { name, value } = e.target;
    if (name === 'product_id') {
      const selectedProduct = products.find(p => p.id === parseInt(value));
      setNewItem({
        ...newItem,
        product_id: value,
        unit_price: selectedProduct ? selectedProduct.purchase_price : 0
      });
    } else {
      setNewItem({ ...newItem, [name]: value });
    }
  };

  const handleAddItem = (e) => {
    e.preventDefault();
    if (!newItem.product_id || newItem.quantity <= 0 || newItem.unit_price <= 0) return;
    const selectedProduct = products.find(p => p.id === parseInt(newItem.product_id));
    setFormData({
      ...formData,
      items: [
        ...formData.items,
        {
          ...newItem,
          product_name: selectedProduct ? selectedProduct.name : 'Unknown',
          total_price: newItem.quantity * newItem.unit_price
        }
      ]
    });
    setNewItem({ product_id: '', quantity: 1, unit_price: 0 });
  };

  const handleRemoveItem = (index) => {
    const updatedItems = [...formData.items];
    updatedItems.splice(index, 1);
    setFormData({ ...formData, items: updatedItems });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.supplier_id || formData.items.length === 0) {
      setError('Please complete all required fields.');
      return;
    }
    try {
      await stockApi.createPurchaseOrder(formData);
      const ordersResponse = await stockApi.getPurchaseOrders(filters);
      setPurchaseOrders(ordersResponse.data || []);
      setFormData({ supplier_id: '', expected_delivery_date: '', notes: '', items: [] });
      setShowForm(false);
      setSuccessMessage('Purchase order created successfully');
    } catch (err) {
      console.error('Error creating order:', err);
      setError(err.message);
    }
  };

  const calculateOrderTotal = (items) =>
    items.reduce((total, item) => total + item.quantity * item.unit_price, 0);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>Purchase Orders</Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6" gutterBottom>Filters</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select name="status" value={filters.status} onChange={handleFilterChange} label="Status">
                <MenuItem value="">All</MenuItem>
                <MenuItem value="draft">Draft</MenuItem>
                <MenuItem value="submitted">Submitted</MenuItem>
                <MenuItem value="approved">Approved</MenuItem>
                <MenuItem value="ordered">Ordered</MenuItem>
                <MenuItem value="partially_received">Partially Received</MenuItem>
                <MenuItem value="received">Received</MenuItem>
                <MenuItem value="cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Supplier</InputLabel>
              <Select name="supplierId" value={filters.supplierId} onChange={handleFilterChange} label="Supplier">
                <MenuItem value="">All</MenuItem>
                {suppliers.map((s) => (
                  <MenuItem key={s.id} value={s.id}>{s.supplier_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Start Date"
              name="startDate"
              type="date"
              value={filters.startDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="End Date"
              name="endDate"
              type="date"
              value={filters.endDate}
              onChange={handleFilterChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Box mb={2} display="flex" gap={2}>
        <Button variant="contained" onClick={() => setShowForm(!showForm)}>
          {showForm ? 'Cancel' : 'New Purchase Order'}
        </Button>
        <Button variant="outlined" onClick={() => console.log('Suggested purchases')}>Suggested Orders</Button>
      </Box>

      {showForm && (
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6" gutterBottom>Create New Order</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel>Supplier</InputLabel>
                <Select
                  name="supplier_id"
                  value={formData.supplier_id}
                  onChange={handleInputChange}
                  label="Supplier"
                >
                  <MenuItem value="">Select</MenuItem>
                  {suppliers.map((s) => (
                    <MenuItem key={s.id} value={s.id}>{s.supplier_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Expected Delivery"
                name="expected_delivery_date"
                type="date"
                value={formData.expected_delivery_date}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                name="notes"
                value={formData.notes}
                onChange={handleInputChange}
                multiline
                rows={2}
              />
            </Grid>
          </Grid>

          <Box mt={3}>
            <Typography variant="subtitle1">Add Items</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Product</InputLabel>
                  <Select
                    name="product_id"
                    value={newItem.product_id}
                    onChange={handleItemChange}
                    label="Product"
                  >
                    <MenuItem value="">Select Product</MenuItem>
                    {products.map(p => (
                      <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  name="quantity"
                  label="Quantity"
                  type="number"
                  value={newItem.quantity}
                  onChange={handleItemChange}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  fullWidth
                  name="unit_price"
                  label="Unit Price"
                  type="number"
                  value={newItem.unit_price}
                  onChange={handleItemChange}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button variant="outlined" onClick={handleAddItem}>Add</Button>
              </Grid>
            </Grid>
          </Box>

          {formData.items.length > 0 && (
            <Box mt={3}>
              <Typography variant="subtitle1">Order Items</Typography>
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formData.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.product_name}</TableCell>
                        <TableCell>{item.quantity}</TableCell>
                        <TableCell>{item.unit_price}</TableCell>
                        <TableCell>{(item.quantity * item.unit_price).toFixed(2)}</TableCell>
                        <TableCell>
                          <Button
                            color="error"
                            onClick={() => handleRemoveItem(index)}
                          >Remove</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={3}><strong>Total</strong></TableCell>
                      <TableCell colSpan={2}><strong>
                        {calculateOrderTotal(formData.items).toFixed(2)}
                      </strong></TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Box>
          )}

          <Box mt={3} display="flex" gap={2}>
            <Button variant="contained" onClick={handleSubmit}>Submit Order</Button>
            <Button variant="outlined" onClick={() => setShowForm(false)}>Cancel</Button>
          </Box>
        </Paper>
      )}

      {loading ? (
        <Box display="flex" justifyContent="center" my={4}><CircularProgress /></Box>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order #</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Delivery</TableCell>
                  <TableCell>Items</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {purchaseOrders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number}</TableCell>
                    <TableCell>{order.supplier_name}</TableCell>
                    <TableCell>
                      <Chip label={order.status} size="small" color="primary" />
                    </TableCell>
                    <TableCell>{order.order_date}</TableCell>
                    <TableCell>{order.expected_delivery_date}</TableCell>
                    <TableCell>{order.items_count}</TableCell>
                    <TableCell>{order.total_amount?.toFixed(2)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
      />
    </Container>
  );
};

export default PurchaseOrders;