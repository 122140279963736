import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Download, Refresh } from '@mui/icons-material';
import dayjs from 'dayjs';
import DatePickerComponent from './DatePickerComponent';
import api from './axiosConfig';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enGB from 'date-fns/locale/en-GB'; // Import UK locale

const TransactionReport = () => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [exportFormat, setExportFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [report, setReport] = useState(null);

  const formatDateForAPI = (date) => {
    if (!date) return '';
    return date.format('YYYY-MM-DD'); // ISO format for API
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    return dayjs(dateString).format('DD/MM/YYYY'); // UK format for display
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP'
    }).format(amount);
  };

  const handleGenerateReport = async () => {
    if (!startDate || !endDate) {
      setError('Please select both start and end dates');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await api.get('/reports/transaction', {
        params: {
          start_date: formatDateForAPI(startDate),
          end_date: formatDateForAPI(endDate)
        }
      });

      setReport(response.data);
    } catch (err) {
      setError(err.response?.data?.error || 'Failed to generate report');
      console.error('Report generation error:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (format) => {
    if (!format) {
      setError('Please select an export format');
      return;
    }

    try {
      const response = await api.get('/reports/transaction', {
        params: {
          start_date: formatDateForAPI(startDate),
          end_date: formatDateForAPI(endDate),
          export_format: format
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `transaction_report.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      setError('Failed to export report');
      console.error('Export error:', err);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <Box>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <DatePickerComponent
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  onClick={handleGenerateReport}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
                >
                  Generate Report
                </Button>
                <TextField
                  select
                  label="Export Format"
                  value={exportFormat}
                  onChange={(e) => setExportFormat(e.target.value)}
                  sx={{ minWidth: 120 }}
                >
                  <MenuItem value="CSV">CSV</MenuItem>
                  <MenuItem value="EXCEL">Excel</MenuItem>
                </TextField>
                <Button
                  variant="outlined"
                  onClick={() => handleExport(exportFormat)}
                  disabled={!report || !exportFormat}
                  startIcon={<Download />}
                >
                  Export
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        {report && (
          <Box>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h5" gutterBottom>
                Summary for period: {formatDateForDisplay(report.period.start_date)} - {formatDateForDisplay(report.period.end_date)}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6">Total Income</Typography>
                    <Typography variant="h4" color="primary">
                      {formatCurrency(report.income.total_income)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {report.income.transaction_count} Transactions
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6">Total Expenses</Typography>
                    <Typography variant="h4" color="error">
                      {formatCurrency(report.expenses.total_expenses)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {report.expenses.transaction_count} Transactions
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Paper sx={{ p: 2 }}>
                    <Typography variant="h6">Net Position</Typography>
                    <Typography variant="h4" color={report.income.total_income - report.expenses.total_expenses >= 0 ? "success" : "error"}>
                      {formatCurrency(report.income.total_income - report.expenses.total_expenses)}
                    </Typography>
                    <Typography variant="subtitle2">
                      {report.suppliers.active_suppliers} Active Suppliers
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Income Breakdown by Type</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Payment Type</TableCell>
                      <TableCell align="right">Transactions</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                      <TableCell align="right">Average Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.income.breakdown_by_type.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.type}</TableCell>
                        <TableCell align="right">{item.transaction_count}</TableCell>
                        <TableCell align="right">{formatCurrency(item.total_amount)}</TableCell>
                        <TableCell align="right">{formatCurrency(item.average_amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Expense Breakdown by Category</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Category</TableCell>
                      <TableCell align="right">Transactions</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                      <TableCell align="right">Average Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.expenses.breakdown_by_category.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.category}</TableCell>
                        <TableCell align="right">{item.transaction_count}</TableCell>
                        <TableCell align="right">{formatCurrency(item.total_amount)}</TableCell>
                        <TableCell align="right">{formatCurrency(item.average_amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Supplier Analysis</Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Supplier Name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Transactions</TableCell>
                      <TableCell align="right">Total Amount</TableCell>
                      <TableCell align="right">Average Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {report.suppliers.breakdown_by_supplier.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.type}</TableCell>
                        <TableCell align="right">{item.transaction_count}</TableCell>
                        <TableCell align="right">{formatCurrency(item.total_amount)}</TableCell>
                        <TableCell align="right">{formatCurrency(item.average_amount)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default TransactionReport;