import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Tabs,
  Tab,
  Alert,
  CircularProgress
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import api from '../axiosConfig';

const ExpenditureImport = ({ setMessage, setMessageType }) => {
  const [activeTab, setActiveTab] = useState('INSTRUCTIONS');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState(null);

  const VALID_PAYMENT_TYPES = ['CASH', 'CARD', 'BANK'];
  const VALID_CATEGORIES = [
    'Accountancy & Legal',
    'Bank Charges & Interest',
    'Card Machine Fees',
    'Client Comfort',
    'Communications',
    'Furniture & Equipment',
    'Insurance',
    'IT & Software',
    'Loan Repayment',
    'Maintenance & Repairs',
    'Marketing & Advertising',
    'Office Supplies',
    'Payroll',
    'Personal',
    'Rates',
    'Rent',
    'Stock',
    'Subscriptions',
    'Sundry',
    'Taxation',
    'Training',
    'Utilities',
    'Waste Disposal'
  ].sort();

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setIsProcessing(true);
    setError(null);
    setResults(null);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await api.post('/csv/import/expenditure', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setResults(response.data);
      
      if (response.data.success > 0) {
        setMessageType(response.data.failures?.length > 0 ? 'warning' : 'success');
        setMessage(`Successfully imported ${response.data.success} records${
          response.data.failures?.length > 0 ? ` with ${response.data.failures.length} failures` : ''
        }`);
      } else {
        setMessageType('error');
        setMessage('Import failed. Please check the errors below.');
      }
    } catch (error) {
      console.error('Import error:', error);
      setError(error.response?.data?.error || 'An error occurred during import');
      setMessageType('error');
      setMessage('Error importing data. Please try again.');
    } finally {
      setIsProcessing(false);
      event.target.value = '';
    }
  };

  const handleDownloadTemplate = () => {
    const templateContent = 'date,supplier_name,category,description,total,payment_type\n' +
      '01/02/2024,ABC Supplies Ltd,Office Supplies,Printer Paper,120.00,CARD\n' +
      '01/02/2024,XYZ Services,Training,Staff Training Course,500.00,BANK';

    const blob = new Blob([templateContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'expenditure_import_template.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 1200, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Expenditure Import</Typography>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={(e, newValue) => setActiveTab(newValue)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider'
          }}
        >
          <Tab label="Import Instructions" value="INSTRUCTIONS" />
          <Tab label="Import" value="IMPORT" />
        </Tabs>
      </Box>

      {activeTab === 'INSTRUCTIONS' && (
        <Box>
          <Typography variant="h6" gutterBottom>Before You Import</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Required Fields:</Typography>
            <ul>
              <li>Date (DD/MM/YYYY format)</li>
              <li>Supplier Name (must match existing supplier)</li>
              <li>Description</li>
              <li>Total Amount (including VAT if applicable)</li>
              <li>Payment Type (CASH, CARD, or BANK)</li>
            </ul>
          </Alert>

          <Alert severity="warning" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Payment Types:</Typography>
            <ul>
              {VALID_PAYMENT_TYPES.map(type => (
                <li key={type}>{type}</li>
              ))}
            </ul>
          </Alert>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">Categories:</Typography>
            <Box sx={{ maxHeight: '200px', overflowY: 'auto' }}>
              <ul>
                {VALID_CATEGORIES.map(category => (
                  <li key={category}>{category}</li>
                ))}
              </ul>
            </Box>
          </Alert>

          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="subtitle2">VAT Handling:</Typography>
            <ul>
              <li>VAT will be automatically calculated based on supplier VAT status</li>
              <li>Enter the total amount including VAT where applicable</li>
              <li>The system will calculate VAT (20%) and net amounts automatically</li>
              <li>Taxation category items are automatically set to 0% VAT</li>
            </ul>
          </Alert>
        </Box>
      )}

      {activeTab === 'IMPORT' && (
        <Box>
          <Typography variant="subtitle1" gutterBottom>Import Instructions</Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <Typography variant="body2">
              1. Download the template below
              <br />
              2. Fill in your expenditure data following the format requirements
              <br />
              3. Save and upload your completed file
              <br />
              Note: Large files will be processed on the server to ensure successful import.
            </Typography>
          </Alert>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {results && (
            <Alert 
              severity={results.success > 0 ? 
                (results.failures?.length > 0 ? 'warning' : 'success') : 
                'error'
              } 
              sx={{ mb: 3 }}
            >
              <Typography variant="subtitle2">Import Results:</Typography>
              <ul>
                <li>Successfully imported: {results.success}</li>
                {results.failures?.length > 0 && (
                  <li>Failed imports: {results.failures.length}</li>
                )}
                <li>Total Amount: £{results.total_amount?.toFixed(2)}</li>
                <li>Total VAT: £{results.total_vat?.toFixed(2)}</li>
                <li>Total Net: £{results.total_net?.toFixed(2)}</li>
              </ul>
              {results.failures?.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle2">Failed Rows:</Typography>
                  <ul>
                    {results.failures.map((failure, index) => (
                      <li key={index}>Row {failure.row}: {failure.error}</li>
                    ))}
                  </ul>
                </Box>
              )}
            </Alert>
          )}

          <Button
            startIcon={<DownloadIcon />}
            variant="outlined"
            onClick={handleDownloadTemplate}
            sx={{ mt: 2, mb: 4 }}
            disabled={isProcessing}
          >
            Download Template
          </Button>

          <Box>
            <Typography variant="subtitle1" gutterBottom>Import Data</Typography>
            <input
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="csv-file-input"
              disabled={isProcessing}
            />
            <label htmlFor="csv-file-input">
              <Button
                startIcon={isProcessing ? <CircularProgress size={20} /> : <UploadIcon />}
                variant="contained"
                component="span"
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Choose CSV File'}
              </Button>
            </label>
            {isProcessing && (
              <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                Processing your file. This may take a few moments...
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default ExpenditureImport;