import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  CircularProgress,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Divider,
  Chip,
  LinearProgress
} from '@mui/material';
import {
  Download,
  Refresh,
  AccountBalance,
  TrendingUp,
  TrendingDown,
  Timeline,
  RepeatOne,
  MonetizationOn,
  CurrencyPound,
  CalendarMonth,
  Payment,
  Percent,
  CompareArrows
} from '@mui/icons-material';
import dayjs from 'dayjs';
import DatePickerComponent from './DatePickerComponent';
import api from './axiosConfig';

// Helper function to safely access nested properties
const safeGet = (obj, path, fallback = '') => {
  try {
    const result = path.split('.').reduce((o, key) => (o || {})[key], obj);
    return result !== undefined && result !== null ? result : fallback;
  } catch (e) {
    return fallback;
  }
};

const CashflowReport = () => {
  const [startDate, setStartDate] = useState(dayjs().startOf('month'));
  const [endDate, setEndDate] = useState(dayjs().endOf('month'));
  const [monthsAhead, setMonthsAhead] = useState(3);
  const [exportFormat, setExportFormat] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [report, setReport] = useState(null);

  // Avoid month-end date issues by ensuring proper date formatting
  const formatDateForApi = (date) => {
    if (!date) return '';
    const formattedDate = date.format('YYYY-MM-DD');
    return formattedDate;
  };

  const handleGenerateReport = async () => {
    setLoading(true);
    setError(null);

    try {
      // Use the secure reports API endpoint
      const response = await api.get('/reports/cashflow', {  
        params: {
          start_date: formatDateForApi(startDate), 
          end_date: formatDateForApi(endDate),     
          months_ahead: monthsAhead 
        }
      });

      // Check if response has data
      if (response.data) {
        setReport(response.data);
        console.log('Report data:', response.data); // Debug log
      } else {
        setError('No data returned from server');
      }
    } catch (err) {
      console.error('Error fetching cashflow report:', err);
      
      // Enhanced error handling
      if (err.response?.status === 403) {
        setError('Access denied. You do not have permission to view this report.');
      } else if (err.response?.status === 404) {
        setError('Report endpoint not found. The secure reports module may not be properly configured.');
      } else if (err.response?.data?.error) {
        setError(`Error: ${err.response.data.error}`);
      } else {
        setError('Failed to generate report. Please try again or contact support.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleExport = async (format) => {
    if (!format) {
      setError('Please select an export format');
      return;
    }
    
    setLoading(true);
    
    try {
      // Use the secure reports API endpoint
      const response = await api.get('/reports/cashflow', {
        params: {
          start_date: formatDateForApi(startDate), 
          end_date: formatDateForApi(endDate), 
          months_ahead: monthsAhead,
          export_format: format
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cashflow_forecast.${format.toLowerCase()}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (err) {
      console.error('Error exporting report:', err);
      setError('Failed to export report. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };

  const getMonthName = (monthStr) => {
    try {
      return dayjs(monthStr).format('MMMM YYYY');
    } catch (e) {
      return monthStr || 'Unknown';
    }
  };

  // Safe formatting for currency values with fallbacks
  const formatCurrency = (amount) => {
    if (amount === undefined || amount === null) {
      return '£0.00';
    }
    
    try {
      return `£${parseFloat(amount).toFixed(2)}`;
    } catch (e) {
      return '£0.00';
    }
  };

  // Convert the monthly cashflow object to an array for display
  const getMonthlyProjections = () => {
    if (!report || !report.data) {
      return [];
    }
    
    // Try different possible data structures
    if (Array.isArray(safeGet(report, 'data.monthly_projections'))) {
      return safeGet(report, 'data.monthly_projections', []);
    }
    
    if (typeof safeGet(report, 'data.monthly_cashflow') === 'object') {
      const monthlyData = report.data.monthly_cashflow;
      return Object.keys(monthlyData).map(month => {
        const monthData = monthlyData[month];
        if (!monthData) {
          return {
            month: month,
            projected_income: 0,
            projected_expenses: 0,
            net_cashflow: 0,
            running_balance: 0,
          };
        }

        return {
          month: month,
          projected_income: safeGet(monthData, 'income', 0),
          projected_expenses: safeGet(monthData, 'expenses', 0),
          net_cashflow: safeGet(monthData, 'net', 0),
          running_balance: safeGet(monthData, 'cumulative_balance', 0),
        };
      });
    }
    
    return [];
  };

  // Get forecast summary data
  const getForecastSummary = () => {
    // Try different possible data structures
    if (safeGet(report, 'data.forecast_summary')) {
      return safeGet(report, 'data.forecast_summary', {});
    }
    
    // Calculate summary if not provided directly
    const projections = getMonthlyProjections();
    if (projections.length === 0) return {};
    
    const startingBalance = safeGet(report, 'data.current_position.balance', 0);
    const endingBalance = projections[projections.length - 1]?.running_balance || 0;
    
    return {
      starting_balance: startingBalance,
      ending_balance: endingBalance,
      total_projected_income: projections.reduce((sum, p) => sum + (p.projected_income || 0), 0),
      total_projected_expenses: projections.reduce((sum, p) => sum + (p.projected_expenses || 0), 0),
      net_projected_cashflow: endingBalance - startingBalance
    };
  };

  // Function to render income breakdown by payment method
  const renderIncomeBreakdown = (monthProjection) => {
    const breakdown = safeGet(monthProjection, 'breakdown.income.breakdown', null);
    if (!breakdown) return null;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>Income by Payment Method</Typography>
        <Grid container spacing={1}>
          {Object.entries(breakdown).map(([method, amount]) => (
            <Grid item xs={4} key={method}>
              <Card variant="outlined" sx={{ p: 1 }}>
                <Typography variant="caption" color="textSecondary">
                  {method.charAt(0).toUpperCase() + method.slice(1)}
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                  {formatCurrency(amount)}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  // Function to render expense breakdown by category
  const renderExpenseBreakdown = (monthProjection) => {
    const breakdown = safeGet(monthProjection, 'breakdown.expenses', {});
    if (Object.keys(breakdown).length === 0) return null;
    
    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" gutterBottom>Expenses by Category</Typography>
        <TableContainer component={Paper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(breakdown).map(([category, data]) => (
                <TableRow key={category}>
                  <TableCell>{category.charAt(0).toUpperCase() + category.slice(1)}</TableCell>
                  <TableCell align="right">{formatCurrency(safeGet(data, 'projected_amount', 0))}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };

  return (
    <Box>
      {/* Controls */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DatePickerComponent
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Forecast Months Ahead"
              type="number"
              value={monthsAhead}
              onChange={(e) => setMonthsAhead(Number(e.target.value))}
              inputProps={{ min: 1, max: 12 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={handleGenerateReport}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={20} /> : <Refresh />}
              >
                Generate Forecast
              </Button>
              <TextField
                select
                label="Export Format"
                value={exportFormat}
                onChange={(e) => setExportFormat(e.target.value)}
                sx={{ minWidth: 120 }}
              >
                <MenuItem value="CSV">CSV</MenuItem>
                <MenuItem value="EXCEL">Excel</MenuItem>
              </TextField>
              <Button
                variant="outlined"
                onClick={() => handleExport(exportFormat)}
                disabled={!report || !exportFormat || loading}
                startIcon={<Download />}
              >
                Export
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {report && safeGet(report, 'warnings', []).length > 0 && (
        <Alert severity="warning" sx={{ mb: 3 }}>
          {safeGet(report, 'warnings', []).join(' ')}
        </Alert>
      )}

      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {report && !loading && (
        <Box>
          {/* Summary Row */}
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <AccountBalance color="primary" />
                    <Typography variant="h6">Current Cash Position</Typography>
                  </Box>
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mt: 2,
                      color: safeGet(report, 'data.current_position.balance', 0) >= 0 
                        ? 'success.main' 
                        : 'error.main'
                    }}
                  >
                    {formatCurrency(safeGet(report, 'data.current_position.balance', 0))}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    As of {safeGet(report, 'data.current_position.as_of_date', 'today')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Timeline color="info" />
                    <Typography variant="h6">Report Period</Typography>
                  </Box>
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    {safeGet(report, 'params.start_date', '')} to {safeGet(report, 'params.end_date', '')}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Generated at {safeGet(report, 'generated_at', '').split('T')[0]}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <MonetizationOn color="warning" />
                    <Typography variant="h6">Account</Typography>
                  </Box>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    ID: {safeGet(report, 'account_id', 'Unknown')}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Forecast: {safeGet(report, 'params.forecast_months', monthsAhead)} months
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Forecast Summary */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Forecast Summary
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Starting Balance
                    </Typography>
                    <Typography variant="h5" component="div">
                      {formatCurrency(safeGet(getForecastSummary(), 'starting_balance', 0))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Projected Income
                    </Typography>
                    <Typography variant="h5" component="div" color="success.main">
                      {formatCurrency(safeGet(getForecastSummary(), 'total_projected_income', 0))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Projected Expenses
                    </Typography>
                    <Typography variant="h5" component="div" color="error.main">
                      {formatCurrency(safeGet(getForecastSummary(), 'total_projected_expenses', 0))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      Ending Balance
                    </Typography>
                    <Typography 
                      variant="h5" 
                      component="div"
                      color={safeGet(getForecastSummary(), 'ending_balance', 0) >= 0 ? 'success.main' : 'error.main'}
                    >
                      {formatCurrency(safeGet(getForecastSummary(), 'ending_balance', 0))}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>

          {/* Recurring Expenses */}
          {Array.isArray(safeGet(report, 'data.recurring_expenses', [])) && safeGet(report, 'data.recurring_expenses', []).length > 0 && (
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Recurring Expenses
              </Typography>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Supplier</TableCell>
                      <TableCell>Frequency</TableCell>
                      <TableCell align="right">Average Amount</TableCell>
                      <TableCell align="right">Min Amount</TableCell>
                      <TableCell align="right">Max Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {safeGet(report, 'data.recurring_expenses', []).map((expense, index) => (
                      <TableRow key={index}>
                        <TableCell>{safeGet(expense, 'supplier_name', 'Unknown Supplier')}</TableCell>
                        <TableCell>
                          <Chip 
                            label={safeGet(expense, 'frequency', 'Unknown')} 
                            color={safeGet(expense, 'frequency', '') === 'Monthly' ? 'primary' : 'default'}
                            size="small"
                          />
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(expense, 'average_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(expense, 'min_amount', 0))}
                        </TableCell>
                        <TableCell align="right">
                          {formatCurrency(safeGet(expense, 'max_amount', 0))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}

          {/* Monthly Projections */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Cashflow Projections
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Month</TableCell>
                    <TableCell align="right">Projected Income</TableCell>
                    <TableCell align="right">Projected Expenses</TableCell>
                    <TableCell align="right">Net Cashflow</TableCell>
                    <TableCell align="right">Running Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getMonthlyProjections().map((month, index) => (
                    <TableRow key={index}>
                      <TableCell>{getMonthName(month.month)}</TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <TrendingUp 
                            sx={{ 
                              mr: 1, 
                              color: 'success.main',
                              fontSize: '1rem'
                            }} 
                          />
                          {formatCurrency(month.projected_income)}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                          <TrendingDown 
                            sx={{ 
                              mr: 1, 
                              color: 'error.main',
                              fontSize: '1rem'
                            }} 
                          />
                          {formatCurrency(month.projected_expenses)}
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: month.net_cashflow >= 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          {formatCurrency(month.net_cashflow)}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography
                          sx={{
                            color: month.running_balance >= 0 ? 'success.main' : 'error.main',
                            fontWeight: 'bold'
                          }}
                        >
                          {formatCurrency(month.running_balance)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          {/* Detailed Breakdown for Each Month */}
          {getMonthlyProjections().length > 0 && (
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Detailed Monthly Breakdown
              </Typography>
              <Grid container spacing={3}>
                {getMonthlyProjections().map((month, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="h6" gutterBottom>
                          {getMonthName(month.month)}
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="subtitle1" color="success.main">
                              Income: {formatCurrency(month.projected_income)}
                            </Typography>
                            {renderIncomeBreakdown(month)}
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="subtitle1" color="error.main">
                              Expenses: {formatCurrency(month.projected_expenses)}
                            </Typography>
                            {renderExpenseBreakdown(month)}
                          </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="subtitle1">
                            Net Cashflow:
                          </Typography>
                          <Typography 
                            variant="subtitle1"
                            sx={{
                              color: month.net_cashflow >= 0 ? 'success.main' : 'error.main',
                              fontWeight: 'bold'
                            }}
                          >
                            {formatCurrency(month.net_cashflow)}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                          <Typography variant="subtitle1">
                            Running Balance:
                          </Typography>
                          <Typography 
                            variant="subtitle1"
                            sx={{
                              color: month.running_balance >= 0 ? 'success.main' : 'error.main',
                              fontWeight: 'bold'
                            }}
                          >
                            {formatCurrency(month.running_balance)}
                          </Typography>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}

          {/* Payment Method Analysis */}
          {safeGet(report, 'data.payment_methods') && (
            <Paper sx={{ p: 3, mt: 3 }}>
              <Typography variant="h6" gutterBottom>
                Payment Method Analysis
              </Typography>
              <Grid container spacing={3}>
                {Object.entries(safeGet(report, 'data.payment_methods', {})).map(([method, amount], index) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                          {method.charAt(0).toUpperCase() + method.slice(1)}
                        </Typography>
                        <Typography variant="h5">
                          {formatCurrency(amount)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {Math.round((amount / safeGet(report, 'data.current_position.total_income', 1)) * 100)}% of total
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          )}

          {/* If the report data structure is different than expected */}
          {!safeGet(report, 'data.current_position') && (
            <Alert severity="info" sx={{ mt: 3 }}>
              The report data structure has changed. This may be due to recent updates in the reporting system.
              Please contact support if you need assistance.
            </Alert>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CashflowReport;