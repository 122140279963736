// src/stockApi.js
import api from './axiosConfig';

/**
 * API service for stock management operations
 */
const stockApi = {
  // Product management
  getProducts: async (filters = {}) => {
    const queryParams = new URLSearchParams();
    if (filters.categoryId) queryParams.append('category_id', filters.categoryId);
    if (filters.search) queryParams.append('search', filters.search);
    const response = await api.get(`/products/?${queryParams.toString()}`);
    return response.data;
  },

  getProduct: async (productId) => {
    const response = await api.get(`/products/${productId}`);
    return response.data;
  },

  createProduct: async (productData) => {
    const response = await api.post('/products/', productData);
    return response.data;
  },

  updateProduct: async (productId, productData) => {
    const response = await api.put(`/products/${productId}`, productData);
    return response.data;
  },

  deleteProduct: async (productId) => {
    const response = await api.delete(`/products/${productId}`);
    return response.data;
  },

  // Product categories
  getCategories: async () => {
    const response = await api.get('/products/categories');
    return response.data;
  },

  createCategory: async (categoryData) => {
    const response = await api.post('/products/categories', categoryData);
    return response.data;
  },

  // Supplier methods
  getSuppliers: async (search = '') => {
    const queryParams = new URLSearchParams();
    if (search) queryParams.append('name', search);
    const response = await api.get(`/suppliers/search?${queryParams.toString()}`);
    return response.data;
  },

  // Stock transactions
  getStockTransactions: async (filters = {}) => {
    const queryParams = new URLSearchParams();
    if (filters.productId) queryParams.append('product_id', filters.productId);
    if (filters.type) queryParams.append('type', filters.type);
    if (filters.startDate) queryParams.append('start_date', filters.startDate);
    if (filters.endDate) queryParams.append('end_date', filters.endDate);
    if (filters.limit) queryParams.append('limit', filters.limit);
    if (filters.offset) queryParams.append('offset', filters.offset);
    const response = await api.get(`/stock/transactions?${queryParams.toString()}`);
    return response.data;
  },

  createStockTransaction: async (transactionData) => {
    const response = await api.post('/stock/transactions', transactionData);
    return response.data;
  },

  getCurrentStock: async (filters = {}) => {
    const queryParams = new URLSearchParams();
    if (filters.categoryId) queryParams.append('category_id', filters.categoryId);
    if (filters.lowStock) queryParams.append('low_stock', filters.lowStock);
    const response = await api.get(`/stock/current?${queryParams.toString()}`);
    return response.data;
  },

  // Service-product integration
  getServiceProducts: async (serviceId) => {
    const queryParams = new URLSearchParams();
    if (serviceId) queryParams.append('service_id', serviceId);
    const response = await api.get(`/service-products/?${queryParams.toString()}`);
    return response.data;
  },

  addProductToService: async (serviceProductData) => {
    const response = await api.post('/service-products/', serviceProductData);
    return response.data;
  },

  updateServiceProduct: async (mappingId, mappingData) => {
    const response = await api.put(`/service-products/${mappingId}`, mappingData);
    return response.data;
  },

  removeProductFromService: async (mappingId) => {
    const response = await api.delete(`/service-products/${mappingId}`);
    return response.data;
  },

  calculateServiceCost: async (serviceId) => {
    const response = await api.post('/service-products/calculate-cost', {
      service_id: serviceId
    });
    return response.data;
  },

  // Purchase orders
  getPurchaseOrders: async (filters = {}) => {
    const queryParams = new URLSearchParams();
    if (filters.status) queryParams.append('status', filters.status);
    if (filters.supplierId) queryParams.append('supplier_id', filters.supplierId);
    if (filters.startDate) queryParams.append('start_date', filters.startDate);
    if (filters.endDate) queryParams.append('end_date', filters.endDate);
    if (filters.limit) queryParams.append('limit', filters.limit);
    if (filters.offset) queryParams.append('offset', filters.offset);
    const response = await api.get(`/purchase-orders/?${queryParams.toString()}`);
    return response.data;
  },

  createPurchaseOrder: async (orderData) => {
    const response = await api.post('/purchase-orders/', orderData);
    return response.data;
  },

  getSuggestedPurchases: async () => {
    const response = await api.get('/purchase-orders/suggested');
    return response.data;
  }
};

export default stockApi;