import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Divider,
  InputAdornment,
  Alert,
  CircularProgress
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import calculatorApi from './calculatorApi';
import stockApi from './stockApi';
import ProductSelection from './ProductSelector';

function ServiceEditor() {
  const { serviceId } = useParams();
  const navigate = useNavigate();
  const isNewService = !serviceId || serviceId === 'new';
  
  // Tab state
  const [activeTab, setActiveTab] = useState(0);
  
  // Loading and error states
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  
  // Service data
  const [service, setService] = useState({
    id: null,
    name: '',
    category_id: '',
    duration: 30,
    description: '',
    product_cost: 0
  });
  
  // Reference data
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [serviceProducts, setServiceProducts] = useState([]);
  
  // Business settings
  const [businessSettings, setBusinessSettings] = useState({
    vat_rate: 20,
    profit_level_1: 20,
    profit_level_2: 30,
    total_trading_hours: 40,
    overhead_hourly: 0,
    overhead_per_minute: 0,
    staff_hourly: 15,
    utilization_rate: 85
  });
  
  // Load data on component mount
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        
        // Fetch business settings
        const settingsResponse = await calculatorApi.getBusinessSettings();
        const settings = settingsResponse?.data || settingsResponse;
        if (settings) {
          setBusinessSettings(settings);
        }
        
        // Fetch service categories
        const categoriesResponse = await calculatorApi.getServiceCategories();
        const categoriesArray = Array.isArray(categoriesResponse?.data) 
          ? categoriesResponse.data 
          : Array.isArray(categoriesResponse) 
            ? categoriesResponse 
            : [];
        setCategories(categoriesArray);
        
        // Fetch products
        const productsResponse = await stockApi.getProducts();
        const productsArray = Array.isArray(productsResponse?.data)
          ? productsResponse.data
          : Array.isArray(productsResponse)
            ? productsResponse
            : [];
        setProducts(productsArray);
        
        // If editing an existing service, load service data
        if (!isNewService) {
          const serviceResponse = await calculatorApi.getService(serviceId);
          const serviceData = serviceResponse?.data || serviceResponse;
          
          if (serviceData) {
            setService({
              id: serviceData.id,
              name: serviceData.name || '',
              category_id: serviceData.category_id || '',
              duration: serviceData.duration || 30,
              description: serviceData.description || '',
              product_cost: serviceData.product_cost || 0
            });
            
            // Load service products
            const productsResponse = await stockApi.getServiceProducts(serviceId);
            const productsArray = Array.isArray(productsResponse?.data)
              ? productsResponse.data
              : Array.isArray(productsResponse)
                ? productsResponse
                : [];
            
            setServiceProducts(productsArray);
          }
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error loading data:', err);
        setError('Failed to load service data. Please try again.');
        setLoading(false);
      }
    };
    
    loadData();
  }, [serviceId, isNewService]);
  
  // Handle tab changes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  
  // Handle service field changes
  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setService(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  // Handle product selection changes
  const handleProductsChange = (updatedProducts) => {
    setServiceProducts(updatedProducts);
  };
  
  // Calculate service costs based on the specified formula
  const calculateServiceCosts = () => {
    // 1. Calculate product cost
    const productCost = serviceProducts.reduce((total, sp) => {
      // Find the product details
      const product = products.find(p => p.id === sp.product_id) || {};
      
      // Calculate per unit cost
      const packSize = product.pack_size || 1;
      const productPrice = product.cost_price || 0; // Using cost_price for calculations
      const perUnit = packSize > 0 ? (productPrice / packSize) : 0;
      
      // Calculate the cost for this product usage
      return total + (perUnit * (sp.quantity || 0));
    }, 0);
    
    // 2. Calculate overhead cost based on overhead per minute
    const overheadPerMinute = businessSettings.overhead_per_minute || 0;
    const overheadCost = overheadPerMinute * (service.duration || 0);
    
    // 3. Calculate staff cost based on staff hourly rate
    const staffHourlyRate = businessSettings.staff_hourly || 15;
    const staffCost = (staffHourlyRate / 60) * (service.duration || 0);
    
    // 4. Calculate total cost
    const totalCost = productCost + overheadCost + staffCost;
    
    // 5. Calculate recommended prices with profit margins
    const profitMargin1 = (businessSettings.profit_level_1 || 20) / 100;
    const profitMargin2 = (businessSettings.profit_level_2 || 30) / 100;
    
    const recommendedPrice1 = totalCost * (1 + profitMargin1);
    const recommendedPrice2 = totalCost * (1 + profitMargin2);
    
    return {
      productCost,
      overheadCost,
      staffCost,
      totalCost,
      recommendedPrice1,
      recommendedPrice2
    };
  };
  
  // Get calculated costs
  const costs = calculateServiceCosts();
  
  // Format currency values
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount || 0);
  };
  
  // Save the service
  const handleSave = async () => {
    try {
      // Validate input
      if (!service.name) {
        setError('Service name is required');
        return;
      }
      
      if (!service.category_id) {
        setError('Please select a category');
        return;
      }
      
      setSaving(true);
      setError(null);
      
      // Set the recommended price as the current price
      const serviceData = {
        ...service,
        product_cost: costs.productCost,
        current_price: costs.recommendedPrice1
      };
      
      // Save the service
      const saveResponse = await calculatorApi.saveService(service.id, serviceData);
      const savedService = saveResponse?.data || saveResponse;
      
      if (!savedService || !savedService.id) {
        throw new Error('Failed to save service');
      }
      
      // Handle product relationships
      
      // First, remove existing products if editing
      if (!isNewService) {
        try {
          await stockApi.deleteServiceProducts(savedService.id);
        } catch (err) {
          console.error('Error clearing existing products:', err);
          // Continue anyway to add the new products
        }
      }
      
      // Then add all selected products
      if (serviceProducts.length > 0) {
        await Promise.all(serviceProducts.map(product => 
          stockApi.addProductToService({
            service_id: savedService.id,
            product_id: product.product_id,
            quantity: product.quantity || 0
          })
        ));
      }
      
      setSaving(false);
      setSuccess('Service saved successfully');
      
      // Redirect back to services list after a short delay
      setTimeout(() => {
        navigate('/calculator');
      }, 1500);
      
    } catch (err) {
      console.error('Error saving service:', err);
      setError('Failed to save service. Please try again.');
      setSaving(false);
    }
  };
  
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <CircularProgress />
      </Box>
    );
  }
  
  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 2 }}>
      <Typography variant="h5" gutterBottom>
        {isNewService ? 'Create New Service' : 'Edit Service'}
      </Typography>
      
      {error && (
        <Alert severity="error" sx={{ mb: 2 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {success}
        </Alert>
      )}
      
      <Paper sx={{ mb: 3 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="Service Details" />
          <Tab label="Products" />
          <Tab label="Cost & Pricing" />
        </Tabs>
      </Paper>
      
      {/* Service Details Tab */}
      {activeTab === 0 && (
        <Paper sx={{ p: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                required
                label="Service Name"
                name="name"
                value={service.name}
                onChange={handleServiceChange}
                error={!service.name}
                helperText={!service.name ? 'Service name is required' : ''}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required>
                <InputLabel id="category-label">Category</InputLabel>
                <Select
                  labelId="category-label"
                  name="category_id"
                  value={service.category_id}
                  onChange={handleServiceChange}
                  error={!service.category_id}
                >
                  <MenuItem value="">Select a category</MenuItem>
                  {categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="number"
                label="Duration (minutes)"
                name="duration"
                value={service.duration}
                onChange={handleServiceChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">min</InputAdornment>,
                }}
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                name="description"
                value={service.description}
                onChange={handleServiceChange}
              />
            </Grid>
          </Grid>
        </Paper>
      )}
      
      {/* Products Tab */}
      {activeTab === 1 && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Add Products to Service
          </Typography>
          
          <Alert severity="info" sx={{ mb: 3 }}>
            Select products used in this service and enter the quantity used for each appointment.
            The product cost will be factored into the service price calculation.
          </Alert>
          
          <ProductSelection
            selectedProducts={serviceProducts}
            onChange={handleProductsChange}
          />
        </Paper>
      )}
      
      {/* Cost & Pricing Tab */}
      {activeTab === 2 && (
        <Paper sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Service Cost Breakdown
          </Typography>
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Cost Breakdown
                </Typography>
                
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="body2">Product Cost:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="right">
                      {formatCurrency(costs.productCost)}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={8}>
                    <Typography variant="body2">Overhead Cost ({service.duration} min):</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="right">
                      {formatCurrency(costs.overheadCost)}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={8}>
                    <Typography variant="body2">Staff Cost ({service.duration} min):</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="right">
                      {formatCurrency(costs.staffCost)}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Divider sx={{ my: 1 }} />
                  </Grid>
                  
                  <Grid item xs={8}>
                    <Typography variant="subtitle2">Total Cost:</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" align="right">
                      {formatCurrency(costs.totalCost)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 1 }}>
                <Typography variant="subtitle1" gutterBottom>
                  Pricing Analysis
                </Typography>
                
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Typography variant="body2">
                      Recommended Price (with {businessSettings.profit_level_1}% profit):
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="right" color="primary.main">
                      {formatCurrency(costs.recommendedPrice1)}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={8}>
                    <Typography variant="body2">
                      Premium Price (with {businessSettings.profit_level_2}% profit):
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="body2" align="right" color="primary.main">
                      {formatCurrency(costs.recommendedPrice2)}
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12}>
                    <Alert severity="info" sx={{ mt: 2 }}>
                      This service will be saved with the recommended price based on your costs and desired profit margin.
                    </Alert>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
      
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button 
          variant="outlined" 
          onClick={() => navigate('/calculator')}
          sx={{ mr: 2 }}
          disabled={saving}
        >
          Cancel
        </Button>
        <Button 
          variant="contained" 
          color="primary"
          onClick={handleSave}
          disabled={saving || !service.name || !service.category_id}
        >
          {saving ? <CircularProgress size={24} sx={{ mr: 1 }} /> : null}
          Save Service
        </Button>
      </Box>
    </Box>
  );
}

export default ServiceEditor;