import React, { useState, useEffect } from 'react';
import { 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  CircularProgress,
  Alert,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Card,
  CardContent
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BarChartIcon from '@mui/icons-material/BarChart';
import { format, parseISO } from 'date-fns';
import api from './axiosConfig';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Drawings = ({ accountId }) => {
  const [drawings, setDrawings] = useState([]);
  const [monthlyTotals, setMonthlyTotals] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openChartDialog, setOpenChartDialog] = useState(false);
  const [drawingDate, setDrawingDate] = useState(new Date());
  const [drawingAmount, setDrawingAmount] = useState('');
  const [drawingDescription, setDrawingDescription] = useState('');
  const [paymentType, setPaymentType] = useState('BANK');
  const [chartYear, setChartYear] = useState(new Date().getFullYear());
  
  const fetchDrawings = async () => {
    setLoading(true);
    try {
      // Get current date as YYYY-MM-DD for default date params
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 0, 1);
      const endDate = today;
      
      const formattedStartDate = format(startDate, 'yyyy-MM-dd');
      const formattedEndDate = format(endDate, 'yyyy-MM-dd');
      
      const response = await api.get(`/accounting/drawings`, {
        params: {
          start_date: formattedStartDate,
          end_date: formattedEndDate
        }
      });
      
      if (response.data && response.data.drawings) {
        setDrawings(response.data.drawings);
      }
    } catch (err) {
      console.error('Error fetching drawings:', err);
      setError('Failed to fetch drawings. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchMonthlyTotals = async (year = new Date().getFullYear()) => {
    try {
      const response = await api.get(`/accounting/drawings/monthly-totals/${year}`);
      
      if (response.data && response.data.monthly_totals) {
        setMonthlyTotals(response.data.monthly_totals);
      }
    } catch (err) {
      console.error('Error fetching monthly drawing totals:', err);
    }
  };
  
  useEffect(() => {
    fetchDrawings();
    fetchMonthlyTotals();
  }, [accountId]);
  
  const handleAddDrawing = async () => {
    try {
      await api.post('/accounting/drawings', {
        date: format(drawingDate, 'yyyy-MM-dd'),
        amount: parseFloat(drawingAmount),
        description: drawingDescription || 'Owner Drawing',
        payment_type: paymentType
      });
      
      setOpenAddDialog(false);
      resetFormFields();
      fetchDrawings(); // Refresh data
      fetchMonthlyTotals(); // Refresh monthly totals
    } catch (err) {
      console.error('Error adding drawing:', err);
      setError('Failed to add drawing. Please try again.');
    }
  };
  
  const handleDeleteDrawing = async (drawingId) => {
    try {
      await api.delete(`/accounting/drawings/${drawingId}`);
      fetchDrawings(); // Refresh data
      fetchMonthlyTotals(); // Refresh monthly totals
    } catch (err) {
      console.error('Error deleting drawing:', err);
      setError('Failed to delete drawing. Please try again.');
    }
  };
  
  const handleReclassifyPersonal = async () => {
    try {
      const response = await api.post('/accounting/drawings/reclassify-personal');
      
      if (response.data && response.data.success) {
        fetchDrawings(); // Refresh data
        fetchMonthlyTotals(); // Refresh monthly totals
        alert(`Successfully reclassified ${response.data.items_reclassified} personal expenditures as drawings.`);
      }
    } catch (err) {
      console.error('Error reclassifying personal expenditures:', err);
      setError('Failed to reclassify personal expenditures. Please try again.');
    }
  };
  
  const handleShowMonthlyChart = async () => {
    await fetchMonthlyTotals(chartYear);
    setOpenChartDialog(true);
  };
  
  const resetFormFields = () => {
    setDrawingDate(new Date());
    setDrawingAmount('');
    setDrawingDescription('');
    setPaymentType('BANK');
  };
  
  const calculateTotalDrawings = () => {
    return drawings.reduce((sum, drawing) => sum + drawing.amount, 0);
  };
  
  const prepareChartData = () => {
    const data = [];
    
    // Sort months chronologically
    const months = Object.keys(monthlyTotals).sort((a, b) => {
      const monthA = new Date(a);
      const monthB = new Date(b);
      return monthA - monthB;
    });
    
    for (const month of months) {
      data.push({
        month,
        amount: monthlyTotals[month]
      });
    }
    
    return data;
  };
  
  if (loading) {
    return (
      <Paper elevation={3} sx={{ p: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
        <CircularProgress />
      </Paper>
    );
  }
  
  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Owner Drawings</Typography>
        <Box>
          <Button 
            variant="outlined" 
            startIcon={<AddCircleOutlineIcon />} 
            onClick={() => setOpenAddDialog(true)}
            sx={{ mr: 1 }}
          >
            Add Drawing
          </Button>
          <Button 
            variant="outlined"
            startIcon={<BarChartIcon />}
            onClick={handleShowMonthlyChart}
            sx={{ mr: 1 }}
          >
            View Chart
          </Button>
          <Button 
            variant="contained" 
            color="secondary"
            onClick={handleReclassifyPersonal}
          >
            Reclassify Personal
          </Button>
        </Box>
      </Box>
      
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper elevation={1} sx={{ p: 2, backgroundColor: '#f8f9fa' }}>
            <Typography variant="subtitle2">Total Drawings for {new Date().getFullYear()}</Typography>
            <Typography variant="h4">£{calculateTotalDrawings().toFixed(2)}</Typography>
          </Paper>
        </Grid>
      </Grid>
      
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {drawings.length > 0 ? (
              drawings.map((drawing) => (
                <TableRow key={drawing.id}>
                  <TableCell>{format(parseISO(drawing.date), 'dd/MM/yyyy')}</TableCell>
                  <TableCell>{drawing.description}</TableCell>
                  <TableCell align="right">£{drawing.amount.toFixed(2)}</TableCell>
                  <TableCell>{drawing.payment_type}</TableCell>
                  <TableCell align="center">
                    <IconButton 
                      size="small" 
                      color="error"
                      onClick={() => handleDeleteDrawing(drawing.id)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center">No drawings found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      
      {/* Add Drawing Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add Owner Drawing</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2, minWidth: '300px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Drawing Date"
                value={drawingDate}
                onChange={(newValue) => setDrawingDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            </LocalizationProvider>
            
            <TextField
              label="Amount"
              type="number"
              value={drawingAmount}
              onChange={(e) => setDrawingAmount(e.target.value)}
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: <span>£</span>,
              }}
            />
            
            <TextField
              label="Description"
              value={drawingDescription}
              onChange={(e) => setDrawingDescription(e.target.value)}
              fullWidth
              margin="normal"
            />
            
            <FormControl fullWidth margin="normal">
              <InputLabel>Payment Method</InputLabel>
              <Select
                value={paymentType}
                label="Payment Method"
                onChange={(e) => setPaymentType(e.target.value)}
              >
                <MenuItem value="CASH">Cash</MenuItem>
                <MenuItem value="CARD">Card</MenuItem>
                <MenuItem value="BANK">Bank Transfer</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
          <Button 
            onClick={handleAddDrawing} 
            variant="contained" 
            color="primary"
            disabled={!drawingAmount}
          >
            Add Drawing
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Monthly Chart Dialog */}
      <Dialog 
        open={openChartDialog} 
        onClose={() => setOpenChartDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Monthly Drawings for {chartYear}</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, pb: 2 }}>
            <TextField
              label="Year"
              type="number"
              value={chartYear}
              onChange={(e) => {
                const year = parseInt(e.target.value);
                setChartYear(year);
                fetchMonthlyTotals(year);
              }}
              margin="normal"
            />
            
            <Box sx={{ height: 400, mt: 2 }}>
              {Object.keys(monthlyTotals).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={prepareChartData()}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="month" 
                      angle={-45} 
                      textAnchor="end"
                      height={70}
                    />
                    <YAxis />
                    <Tooltip 
                      formatter={(value) => [`£${value.toFixed(2)}`, 'Amount']}
                    />
                    <Bar dataKey="amount" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <Typography variant="body1">No data available for {chartYear}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenChartDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default Drawings;